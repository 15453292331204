import { HStack, Spinner, Tag, TagLabel } from '@chakra-ui/react'
import { UserStatus } from '../types/UserStatus';
import { UserType } from '../types/UserType';
import { BookingStatus } from '../types/BookingStatus';
import { FacilityStatus } from '../types/FacilityStatus';
import { getLabel } from '../common/labelsFactory';

export const StatusBadge = ({ label, size }: { label: string, size: 'lg' | 'md' | 'sm' }) => {
    let colorScheme = 'gray';
    let description = label;
    let loading = false;
    if (label === UserStatus.ACTIVE || label === UserStatus.ACTIVE_BY_API) {
        colorScheme = 'green';
        description = getLabel(label);
        loading = false;
    } else if (label === UserStatus.BLOCKED || label === UserStatus.BLOCKED_BY_API) {
        colorScheme = 'red';
        description = getLabel(label);
        loading = false;
    } else if (label === UserStatus.ONLY_LOGIN || label === UserStatus.ONLY_LOGIN_BY_API) {
        colorScheme = 'orange';
        description = getLabel(label);
        loading = false;
    } else if (label === UserType.REGULAR) {
        colorScheme = 'gray';
        description = getLabel(label);
        loading = false;
    } else if (label === UserType.ADMIN) {
        colorScheme = 'gray';
        description = getLabel(label);
        loading = false;
    } else if (label === UserType.ASSOCIATED) {
        colorScheme = 'gray';
        description = getLabel(label);
        loading = false;
    } else if (label === UserType.MANAGER) {
        colorScheme = 'gray';
        description = getLabel(label);
        loading = false;
    } else if (label === BookingStatus.RESERVED) {
        colorScheme = 'green';
        description = getLabel(label);
        loading = false;
    } else if (label === BookingStatus.DENIED ) {
        colorScheme = 'red';
        description = getLabel(label);
        loading = false;
    } else if (label === BookingStatus.REFUNDED) {
        colorScheme = 'red';
        description = getLabel(label);
        loading = false;
    } else if (label === BookingStatus.WAITING_FOR_APPROVAL) {
        colorScheme = 'orange';
        description = getLabel(label);
        loading = false;
    } else if (label === BookingStatus.WAITING_FOR_PAYMENT) {
        colorScheme = 'orange';
        description = getLabel(label);
        loading = false;
    } else if (label === FacilityStatus.AVAILABLE){
        colorScheme = 'green';
        description = getLabel(label);
        loading = false;
    } else if (label === FacilityStatus.UNAVAILABLE){
        colorScheme = 'red';
        description = getLabel(label);
        loading = false;
    } else if (label === 'Cancelada'){
        colorScheme = 'red';
        loading = false;
    }
    return (
        <HStack>
            <Tag size={size} variant='solid' colorScheme={colorScheme}>
                <TagLabel>{description}</TagLabel>
            </Tag>
            {loading &&
                <Spinner size={size} />
            }
        </HStack>
    )
}