import { initializeApp } from '@firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from '@firebase/app-check';
import { connectFirestoreEmulator, initializeFirestore } from '@firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from '@firebase/functions';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import ReactGA from 'react-ga4';

const firebaseConfig = {
    apiKey: 'AIzaSyDQ_Hf4aJFDLYw0Ey4-pLA5qCYZai0zB7s',
    authDomain: 'pcttbinary.firebaseapp.com',
    projectId: 'pcttbinary',
    storageBucket: 'pcttbinary.appspot.com',
    messagingSenderId: '128059847017',
    appId: '1:128059847017:web:96eec015e2560fad120df8',
    measurementId: 'G-J3Z1J93R6F'
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

if (process.env.APP_CHECK_DEBUG_TOKEN) {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.APP_CHECK_DEBUG_TOKEN;
}

export const appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider('6LfTtNMnAAAAANT9W1KuTvPCTadjNTbN8Y9TmEru'),
    isTokenAutoRefreshEnabled: true
});

const firestore = initializeFirestore(firebaseApp, { ignoreUndefinedProperties: true });
const functions = getFunctions(firebaseApp, 'europe-west3');
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);

const emulatorHost = process.env.EMULATOR_HOST ?? 'localhost';

if (process.env.USE_EMULATORS === 'true') {
    connectFirestoreEmulator(firestore, emulatorHost, 8080);
    connectFunctionsEmulator(functions, emulatorHost, 5001);
    connectAuthEmulator(auth, `http://${emulatorHost}:9090`, { disableWarnings: true })
    connectStorageEmulator(storage,emulatorHost,5002);
}

export const initializeAnalytics = (trackingId?: string) => {
    if (!ReactGA.isInitialized) {
        if (trackingId) {
            ReactGA.initialize([{
                trackingId: firebaseConfig.measurementId,
            }, {
                trackingId
            }]);
        } else {
            ReactGA.initialize([{
                trackingId: firebaseConfig.measurementId,
            }]);
        }
    }
}
export const logAnalyticsEvent = (
    eventName: string,
    eventParams?: { [key: string]: any; },
) => {
    if (ReactGA.isInitialized) {
        ReactGA.event(eventName, eventParams);
    }
}
export const firebaseFirestore = firestore;
export const firebaseFunctions = functions;
export const firebaseAuth = auth;
export const firebaseStorage = storage;