import { Outlet } from 'react-router';
import {
    Box,
    Container,
} from '@chakra-ui/react';

export const PublicLayout = () => {

    return (
        <>
                <Box as='section' minH='lg'>
                    <Container maxW={'container.2xl'} py={8}>
                        <Outlet />
                    </Container>
                </Box>
        </>
    )
}
