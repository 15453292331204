import {
    Box,
    Button,
    HStack,
    IconButton,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast,
} from '@chakra-ui/react';
import { FiEdit } from 'react-icons/fi';
import { AddIcon } from '@chakra-ui/icons';
import { Link, useOutletContext } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { QueryDocumentSnapshot, collection, query } from 'firebase/firestore';
import { firebaseFirestore } from '../../firebase';
import { showToast } from '../../helpers';
import { TableLoadingSpinner } from '../../components/TableLoadingSpinner';
import { TableNoResultsMessage } from '../../components/TableNoResultsMessage';
import { Helmet } from 'react-helmet-async';
import { StatusBadge } from '../../components/StatusBadge';
import { getLabel } from '../../common/labelsFactory';
import { User } from 'firebase/auth';

export const FacilitiesList = () => {

    const [user, isAdmin, isManager, path]: [User, boolean, boolean, string] = useOutletContext();
    const toast = useToast();
    const [docs, setDocs] = useState<QueryDocumentSnapshot[]>([]);

    const [facilitiesQuerySnapshot, isLoading, facilitiesLoadingError] = useCollection(
        query(
            collection(firebaseFirestore, 'facilities'),
        )
    );

    useEffect(() => {
        if (facilitiesLoadingError) {
            showToast(toast, {
                title: 'Could not fetch data.',
                status: 'error'
            });
        }
    }, [facilitiesLoadingError])

    useEffect(() => {
        if (facilitiesQuerySnapshot?.docs) {
            setDocs(facilitiesQuerySnapshot.docs);
        }
    }, [facilitiesQuerySnapshot]);

    return (
        <>
            <Helmet>
                <title>PCTT - Listado de salas</title>
            </Helmet>
            <Stack>
                <Box
                    bg='bg.surface'
                    boxShadow={{ base: 'none', md: 'sm' }}
                    borderRadius={{ base: 'none', md: 'lg' }}
                >
                    <Stack spacing='5'>
                        <Box px={{ base: '4', md: '6' }} pt='5'>
                            <Stack direction={{ base: 'column', md: 'row' }} justify='space-between'>
                                <Text fontSize={'3xl'} fontWeight='medium'>
                                    Salas
                                </Text>
                                {(isAdmin &&
                                    <Button
                                        width='auto'
                                        colorScheme='blue'
                                        as={Link}
                                        to='../facilities/new'
                                        leftIcon={<AddIcon boxSize={3.5} />}>
                                        Añadir sala
                                    </Button>
                                )}
                            </Stack>
                        </Box>
                        <Box overflowX='auto' overflowY='hidden'>
                            <Table>
                                {(isLoading) &&
                                    <TableLoadingSpinner />
                                }
                                {(!isLoading && docs?.length === 0) &&
                                    <TableNoResultsMessage />
                                }
                                <Thead>
                                    <Tr>
                                        {(isAdmin &&
                                            <Th w={20}></Th>)}
                                        <Th>Nombre</Th>
                                        <Th>Tipo</Th>
                                        <Th>Edificio</Th>
                                        <Th>Estado</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {docs.map(facility => (
                                        <Tr key={facility.id}>
                                            {(isAdmin &&

                                                <Td>
                                                    <IconButton
                                                        icon={<FiEdit />}
                                                        as={Link}
                                                        to={`../facilities/${facility.id}`}
                                                        variant='tertiary'
                                                        aria-label='Edit booking' />
                                                </Td>
                                            )}
                                            <Td><Text color='fg.muted'>{facility.data().name}</Text></Td>
                                            <Td><Text color='fg.muted'>{getLabel(facility.data().type)}</Text></Td>
                                            <Td><Text color='fg.muted'>{facility.data().info.location.name}</Text></Td>
                                            <Td><StatusBadge size='sm' label={facility.data().status} /></Td>

                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Box>
                        <Box px={{ base: '4', md: '6' }} pb='5'>
                            <HStack spacing='3' justify='space-between'>
                            </HStack>
                        </Box>
                    </Stack>
                </Box>
            </Stack>
        </>
    )

}