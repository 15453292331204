import { Button, ButtonGroup, Flex, FormControl, FormLabel, Heading, Input, Spinner, Stack, useDisclosure, useToast } from '@chakra-ui/react'
import { QueryFieldFilterConstraint, collection, doc, query, where } from 'firebase/firestore';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { firebaseAuth, firebaseFirestore } from '../../firebase';
import { SetStateAction, useEffect, useState } from 'react';
import { showToast } from '../../helpers';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet-async';
import { Select } from 'chakra-react-select';
import { firebaseFunctions } from '../../firebase';
import { httpsCallable } from 'firebase/functions';
import { getLabel } from '../../common/labelsFactory';
import { FacilityStatus } from '../../types/FacilityStatus';
import { FacilityInformationCard } from './components/FacilityInformationCard';
import { RateUnitType } from '../../types/RateUnitType';
import { CheckAndBookModal } from '../bookings/form/CheckAndBookModal';
import { BookingButtonInformationComponent } from '../bookings/components/BookingButtonInformation';
import FullCalendar from '../../components/FullCalendar';
import { BookingStatus } from '../../types/BookingStatus';

type BookingFormData = {
    facility?: string;
    client?: string;
    start?: number;
    units?: number;
    unitType?: any;
    description?: string
    sector?: any
}

export const FacilityBookingForm = () => {

    const checkAndBookModalDisclosure = useDisclosure();

    const { id } = useParams();
    const toast = useToast();
    const navigate = useNavigate();

    const [unitTypeSelected, setUnitTypeSelected] = useState<{ label: string, value: RateUnitType }>();
    const [sectorSelected, setSectorSelected] = useState<{ label: string, value: string }>();
    const [startDate, setStartDate] = useState<number>()
    const [units, setUnits] = useState<number>()
    const [unitTypes, setUnitTypes] = useState<{ label: string, value: RateUnitType }[]>([])
    const [description, setDescription] = useState('')
    const [allSectors, setAllSectors] = useState<{ label: string; value: string; }[]>([])
    const [bookings, setAllBookings] = useState([])

    const [startFormatted, setStartFormatted] = useState<string>()
    const [endFormatted, setEndFormatted] = useState<string>()

    const getEndDateCommand = httpsCallable(
        firebaseFunctions,
        'getEndDateCommandCallable'
    );

    const showError = (message: string) => {
        showToast(toast, {
            title: message,
            status: 'error'
        });
        navigate('../dashboard')
    }

    if (!id) {
        showError('No exixte la sala');
    }

    const [settingsSnapshot, isLoadingSettings, errorLoadingSettings] = useDocumentData(
        doc(firebaseFirestore, 'settings', 'public')
    );
    const [facilityDataSnapshot, isLoadingFacility, errorLoadingFacility] = useDocumentData(
        doc(firebaseFirestore, 'facilities', id!!)
    );

    const queryConstraints: QueryFieldFilterConstraint[] = [];
    queryConstraints.push(where('facilityId', '==', id));
    queryConstraints.push(where('status', 'in', [BookingStatus.WAITING_FOR_APPROVAL, BookingStatus.WAITING_FOR_PAYMENT, BookingStatus.RESERVED]));
    const [bookingsQuerySnapshot, isLoadingBookings, bookingsLoadingError] = useCollection(
        query(
            collection(firebaseFirestore, 'bookings'),
            ...queryConstraints,
        )
    );
    
    const [usersQuerySnapshot, isLoadingUsers, usersLoadingError] = useCollection(
        query(
            collection(firebaseFirestore, 'users')
        )
    );

    useEffect(() => {
        if (!isLoadingBookings && bookingsQuerySnapshot && !isLoadingUsers && usersQuerySnapshot) {
            const tempBookings = []
            const usersMap = new Map<string, string>()
            usersQuerySnapshot.docs.forEach((u)=>{
                usersMap.set(u.id, u.data().displayName)
            })
            bookingsQuerySnapshot.docs.forEach((booking) => {
                tempBookings.push(
                    {
                      id: booking.id,
                      title: booking.data().description,
                      calendarId: "3",
                      category: "time",
                      attendees: [usersMap.get(booking.data().userId)],
                      isVisible: true,
                      start: new Date(booking.data().info.start.seconds*1000),
                      end: new Date(booking.data().info.end.seconds*1000),
                    })
            });
            setAllBookings(tempBookings);
        }
    }, [isLoadingBookings, bookingsQuerySnapshot, isLoadingUsers, usersQuerySnapshot])


    useEffect(() => {
        if (!isLoadingSettings && settingsSnapshot) {
            const sectors: { label: string; value: string; }[] = [];
            settingsSnapshot.sectors.forEach((sector: string) => {
                sectors.push({ label: sector, value: sector })
            });
            setAllSectors(sectors);
        }
    }, [isLoadingSettings])

    useEffect(() => {
        if (errorLoadingFacility) {
            showError('Error al cargar la página. Inténtelo de nuevo.')
        }
        if (!isLoadingFacility && facilityDataSnapshot) {
            if (facilityDataSnapshot.status === FacilityStatus.UNAVAILABLE) {
                showError('La sala no está disponible para reservar.')
            } else {

                setUnitTypes((facilityDataSnapshot.rates.map((el: { unitType: RateUnitType; }) =>
                    ({ label: getLabel(el.unitType), value: el.unitType })
                )));
            }

        } else if (!isLoadingFacility) {
            showError('No exixte la sala');
        }
    }, [errorLoadingFacility, isLoadingFacility]);

    const {
        handleSubmit,
        register,
        setValue,
        control,
        watch,
        formState: { errors, isSubmitting },
    } = useForm<BookingFormData>({
        values: {
            facility: id,
            client: firebaseAuth.currentUser!.uid,
            start: startDate,
            units: units,
            unitType: unitTypeSelected,
            description: description,
            sector: (sectorSelected) ? sectorSelected.value : null

        },
        resetOptions: {
            keepDirtyValues: true
        }
    });

    const send = (data: BookingFormData) => {
        return new Promise<void>((resolve) => {
            const success = () => {
                showToast(toast, {
                    title: 'Success',
                    description: 'La reserva se ha creado con éxito',
                    status: 'success',
                });
                navigate('../bookings', { replace: true });
            }
            const error = (e: any) => {
                console.error(e);
                showToast(toast, {
                    title: 'Error',
                    description: e,
                    status: 'error',
                });
                resolve()
            }

            if (!data.start || !data.units || !data.unitType || !data.description || !data.sector) {
                error('Todos los campos son obligatorios')
            } else {
                getEndDateCommand({ start: data.start, units: data.units, unitType: data.unitType.value })
                    .then((result: { data: { valid: boolean, error?: string, startString: SetStateAction<string | undefined>; endString: SetStateAction<string | undefined>; start: SetStateAction<number | undefined>; }; }) => {
                        if (!result.data.valid) {
                            showToast(toast, {
                                title: 'Error',
                                description: result.data.error,
                                status: 'error',
                            })
                        } else {
                            setStartFormatted(result.data.startString)
                            setEndFormatted(result.data.endString)
                            setStartDate(result.data.start)
                            checkAndBookModalDisclosure.onOpen()
                        }
                    }).catch((e) => {
                        console.error(e);
                        showToast(toast, {
                            title: 'Error',
                            description: e,
                            status: 'error',
                        });
                    });
            }

        });
    }


    return (
        <>
            <CheckAndBookModal
                start={startDate}
                units={units}
                facilityId={id}
                clientId={firebaseAuth.currentUser!.uid}    
                unitType={unitTypeSelected?.value}
                endString={endFormatted}
                startString={startFormatted}
                description={description}
                sector={sectorSelected?.value}
                isOpen={checkAndBookModalDisclosure.isOpen}
                onClose={checkAndBookModalDisclosure.onClose} />

            <Helmet>
                <title>PCTT - Detalles sala</title>
            </Helmet>
            <Stack>
                {isLoadingFacility &&
                    <Flex justifyContent={'center'} py={24}>
                        <Spinner size='xl' />
                    </Flex>
                }
                {!isLoadingFacility && facilityDataSnapshot &&
                    <>
                        <Stack p={4} bg='bg-surface' boxShadow='sm' borderRadius='lg' flexGrow={1} >
                            <FacilityInformationCard doc={facilityDataSnapshot} />
                            <Stack as='form' spacing={8} onSubmit={handleSubmit(send)} noValidate>
                                <Heading mt={5} size={'md'}>Solicitar Reserva</Heading>
                                <Stack direction={{ base: 'column', lg: 'row' }} alignSelf={{ base: 'flex-start', lg: 'flex-end' }} width={{ base: '100%' }} mb={5} display='flex'>
                                    <FormControl>
                                        <FormLabel htmlFor='units'>Motivo de la reserva:</FormLabel>
                                        <Input
                                            placeholder="escriba una breve descripción"
                                            size="md"
                                            type="text"
                                            onChange={(e) => setDescription(e.currentTarget.value)} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor='sectors'>Sector:</FormLabel>
                                        <Select
                                            options={allSectors}
                                            value={sectorSelected}
                                            isLoading={isLoadingSettings}
                                            placeholder='Elija un sector'
                                            onChange={nv => setSectorSelected(nv!)}
                                        />
                                    </FormControl>
                                </Stack>
                                <Stack direction={{ base: 'column', lg: 'row' }} alignSelf={{ base: 'flex-start', lg: 'flex-end' }} width={{ base: '100%' }} mb={5} display='flex'>
                                    <FormControl >
                                        <FormLabel htmlFor='start'>Fecha inicio de la reserva:</FormLabel>
                                        <Input
                                            placeholder="Select Date and Time"
                                            size="md"
                                            type="datetime-local"
                                            onChange={nv => setStartDate(Date.parse(nv.target.value))}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor='units'>Cantidad de tiempo:</FormLabel>
                                        <Input
                                            placeholder="0"
                                            size="md"
                                            type="number"
                                            onChange={(e) => setUnits(Number(e.currentTarget.value))} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor='units'>Unidad de tiempo:</FormLabel>
                                        <Select
                                            options={unitTypes}
                                            value={unitTypeSelected}
                                            isLoading={isLoadingFacility}
                                            placeholder='Elija una opción'
                                            onChange={nv => setUnitTypeSelected(nv!)}
                                        />
                                    </FormControl>
                                    <ButtonGroup isAttached mt={6}>
                                        <Button
                                            variant='primary'
                                            isLoading={isSubmitting}
                                            isDisabled={false}
                                            type='submit'
                                            size='lg'
                                            colorScheme={'blue'}
                                        >
                                            Solicitar
                                        </Button>
                                        <BookingButtonInformationComponent color={'blue'} size={'lg'} />
                                    </ButtonGroup>
                                </Stack>
                            </Stack>

                            <FullCalendar schedules={bookings}></FullCalendar>
                        </Stack>
                    </>
                }
            </Stack>
        </>
    )

}