import {
    Box,
    Button,
    ButtonGroup,
    HStack,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast,
} from '@chakra-ui/react';
import { FiEdit, FiSearch } from 'react-icons/fi';
import { Link, useOutletContext } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { QueryDocumentSnapshot, QueryFieldFilterConstraint, collection, limit, orderBy, query, startAfter } from 'firebase/firestore';
import { firebaseFirestore } from '../../firebase';
import { formatDate, showToast } from '../../helpers';
import debounce from 'lodash.debounce';
import { TableLoadingSpinner } from '../../components/TableLoadingSpinner';
import { TableNoResultsMessage } from '../../components/TableNoResultsMessage';
import { Helmet } from 'react-helmet-async';
import { StatusBadge } from '../../components/StatusBadge';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { User } from 'firebase/auth';

export const ClientsList = () => {

    const [user, isAdmin, isManager, path]: [User, boolean, boolean, string] = useOutletContext();

    const toast = useToast();
    const pageSize = 25;
    const pagination = [];
    const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot>();
    const [docs, setDocs] = useState<QueryDocumentSnapshot[]>([]);

    const [searchQuery, setSearchQuery] = useState<string>();
    const changeHandler = (event: any) => setSearchQuery(event.target.value);
    const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

    const queryConstraints: QueryFieldFilterConstraint[] = [];
    if (!searchQuery) {
        if (lastVisible) {
            pagination.push(startAfter(lastVisible), limit(pageSize))
        } else {
            pagination.push(limit(pageSize))
        }
    }
    const [clientsQuerySnapshot, isLoading, clientsLoadingError] = useCollection(
        query(
            collection(firebaseFirestore, 'users'),
            ...queryConstraints,
            orderBy('created', 'desc'),
            ...pagination
        )
    );

    useEffect(() => {
        if (clientsLoadingError) {
            showToast(toast, {
                title: 'Could not fetch clients list.',
                status: 'error'
            });
        }
    }, [clientsLoadingError])

    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();
        }
    }, []);

    useEffect(() => {
        if (searchQuery && searchQuery.length > 1) {
            const temp = clientsQuerySnapshot?.docs.filter((d) => {
                if (!searchQuery) return d;
                const lowerCaseQuery = searchQuery.toLowerCase();
                return d.data().displayName.toLowerCase().includes(lowerCaseQuery);
            })
            if (temp) {
                setDocs(temp);
            } else {
                setDocs([])
            }
        }
    }, [searchQuery]);
    useEffect(() => {
        if (clientsQuerySnapshot?.docs) {
            if (searchQuery) {
                const temp = clientsQuerySnapshot?.docs.filter((d) => {
                    if (!searchQuery) return d;
                    const lowerCaseQuery = searchQuery.toLowerCase();
                    return d.data().displayName.toLowerCase().includes(lowerCaseQuery);
                })
                if (temp) {
                    setDocs(temp);
                } else {
                    setDocs([])
                }
            } else if (lastVisible) {
                const mappedDocs: any = {};
                const newDocs = [...docs, ...clientsQuerySnapshot.docs];
                newDocs.map(d => {
                    mappedDocs[d.id] = d;
                })
                setDocs(Object.values(mappedDocs));
            } else {
                setDocs(clientsQuerySnapshot.docs);
            }
        }
    }, [clientsQuerySnapshot]);

    return (
        <>
            <Helmet>
                <title>PCTT - Listado de clientes</title>
            </Helmet>
            <Stack>
                <Box
                    bg='bg.surface'
                    boxShadow={{ base: 'none', md: 'sm' }}
                    borderRadius={{ base: 'none', md: 'lg' }}
                >
                    <Stack spacing='5'>
                        <Box px={{ base: '4', md: '6' }} pt='5'>
                            <Stack direction={{ base: 'column', md: 'row' }} justify='space-between'>
                                <Text fontSize={'3xl'} fontWeight='medium'>
                                    Clientes
                                </Text>
                                <Stack direction={{ base: 'column', lg: 'row' }} alignSelf={{ base: 'flex-start', lg: 'flex-end' }} width={{ base: '100%', lg: 'auto' }}>

                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none'>
                                            <Icon as={FiSearch} color='fg.muted' boxSize='5' />
                                        </InputLeftElement>
                                        <Input placeholder='Buscar' onChange={debouncedChangeHandler} />
                                    </InputGroup>
                                </Stack>
                                {(isAdmin &&
                                    <Menu>
                                        <MenuButton as={Button} colorScheme='blue' rightIcon={<ChevronDownIcon />}>
                                            Acciones
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem
                                                as='a'
                                                href={`../app/user/new`}>
                                                Crear Usuario (Admin o gestor)
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                )}

                            </Stack>
                        </Box>

                        <Box overflowX='auto' overflowY='hidden'>
                            <Table>
                                {isLoading &&
                                    <TableLoadingSpinner />
                                }
                                {(!isLoading && docs?.length === 0) &&
                                    <TableNoResultsMessage />
                                }
                                <Thead>
                                    <Tr>
                                        {(isAdmin &&
                                            <Th></Th>)}
                                        <Th>Nombre</Th>
                                        <Th>Creado</Th>
                                        <Th>Tipo Usuario</Th>
                                        <Th>Estado</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {docs?.map((client) => (
                                        <Tr key={client.id}>
                                            {(isAdmin &&
                                                <Td>
                                                    <IconButton
                                                        icon={<FiEdit />}
                                                        as={Link}
                                                        to={`../clients/${client.id}`}
                                                        variant='tertiary'
                                                        aria-label='Edit client' />
                                                </Td>
                                            )}
                                            <Td><Text fontWeight='medium'>{client.data().displayName}</Text></Td>
                                            <Td><Text color='fg.muted'>{formatDate(client.data().created)}</Text></Td>
                                            <Td><StatusBadge size='sm' label={client.data().category} /></Td>
                                            <Td><StatusBadge size='sm' label={client.data().status} /></Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Box>
                        <Box px={{ base: '4', md: '6' }} pb='5'>
                            <HStack spacing='3' justify='space-between'>
                                <Text color='fg.muted' textStyle='sm'>
                                    {`Mostrando ${docs.length} resultados`}
                                </Text>
                                <ButtonGroup
                                    spacing='3'
                                    justifyContent='flex-end'
                                    variant='secondary'>
                                    <Button
                                        isDisabled={(docs.length ?? 0) < pageSize}
                                        onClick={() => {
                                            setLastVisible(clientsQuerySnapshot?.docs[clientsQuerySnapshot.docs.length - 1])
                                        }}>
                                        Mostrar más
                                    </Button>
                                </ButtonGroup>
                            </HStack>
                        </Box>
                    </Stack>
                </Box>
            </Stack>
        </>
    )

}