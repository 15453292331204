import {
    Card,
    CardHeader,
    HStack,
    Heading,
    CardBody,
    Stack,
    StackDivider,
    useDisclosure,
} from '@chakra-ui/react'
import { formatDate } from '../../../helpers'
import { DocumentData } from 'firebase/firestore'
import { useState } from 'react'
import { DataBox } from '../../components/DataBox'
import { StatusBadge } from '../../../components/StatusBadge'
import { EditBonusModal } from './EditBonusModal'
import { BadgeDataBox } from '../../components/BadgeDataBox'
import { EditClientStatusModal } from './EditClientStatusModal'
import { InputEditModal } from './InputEditModal'

export const GeneralInformation = ({
    id,
    clientDataSnapshot,
    bonusDataSnapshot,
}: {
    id: string,
    clientDataSnapshot: DocumentData,
    bonusDataSnapshot: DocumentData | undefined,
}) => {
    const [downloadUrl, setDownloadUrl] = useState<string>();
    const editClientModalDisclosure = useDisclosure();
    const editClientStatusModalDisclosure = useDisclosure();
    const editAdditionalInformationModalDisclosure = useDisclosure();
    const editCif = useDisclosure();

    return (
        <>
            <EditBonusModal
                clientDataSnapshot={clientDataSnapshot}
                clientId={id}
                isOpen={editClientModalDisclosure.isOpen}
                onClose={editClientModalDisclosure.onClose} />
            <EditClientStatusModal
                clientDataSnapshot={clientDataSnapshot}
                clientId={id}
                isOpen={editClientStatusModalDisclosure.isOpen}
                onClose={editClientStatusModalDisclosure.onClose} />
            <InputEditModal
                isDisabled={false}
                readableFieldName={'Información adicional del cliente'}
                documentPath={`users/${id}`}
                fieldName={'additionalInformation'}
                value={clientDataSnapshot?.additionalInformation}
                isOpen={editAdditionalInformationModalDisclosure.isOpen}
                onClose={editAdditionalInformationModalDisclosure.onClose} />
            <Card>

                <CardHeader>
                    <HStack justify={'space-between'}>
                        <Heading size='md'>Datos cliente</Heading>
                    </HStack>
                </CardHeader>
                <CardBody>
                    <Stack
                        direction={{ base: 'column', md: 'row' }}
                        spacing={4} >
                        <Stack divider={<StackDivider />} spacing='2' flex='1'>
                            <DataBox title='Nombre' content={clientDataSnapshot.displayName} />
                            <DataBox title='ID' content={id} />
                            <DataBox title='Tipo usuario'>
                                <StatusBadge size='md' label={clientDataSnapshot.category} />
                            </DataBox>
                            <DataBox title='Creado' content={formatDate(clientDataSnapshot.created)} />
                            <DataBox title='Modificado' content={formatDate(clientDataSnapshot.modified)} />
                        </Stack>
                        <Stack divider={<StackDivider />} spacing='2' flex='1'>
                            <BadgeDataBox
                                title='Estado'
                                content={clientDataSnapshot.status}
                                onEdit={editClientStatusModalDisclosure.onOpen} />
                            <DataBox
                                title='Bono'
                                content={clientDataSnapshot.bonusId ? bonusDataSnapshot?.docs.find((c: DocumentData) => c.id === clientDataSnapshot.bonusId)?.data().description : '-'}
                                onEdit={editClientModalDisclosure.onOpen} />
                            <DataBox
                                title='Información adicional'
                                content={clientDataSnapshot.additionalInformation ?? '-'}
                                onEdit={editAdditionalInformationModalDisclosure.onOpen} />

                        </Stack>
                    </Stack>
                </CardBody>
            </Card>

            <Card>
                <CardHeader>
                    <HStack justify={'space-between'}>
                        <Heading size='md'>Datos facturación</Heading>
                    </HStack>
                </CardHeader>
                <CardBody>
                    <Stack
                        direction={{ base: 'column', md: 'row' }}
                        spacing={4} >
                        <Stack divider={<StackDivider />} spacing='2' flex='1'>
                            <DataBox
                                title='CIF/NIF'
                                content={clientDataSnapshot.infoCompany?.cif ?? '-'}
                                />
                        </Stack>
                        <Stack divider={<StackDivider />} spacing='2' flex='1'>

                        </Stack>
                    </Stack>
                </CardBody>
            </Card>

        </>
    )
}