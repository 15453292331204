'use client'

import {
    Button,
    Stack,
    FormControl,
    FormLabel,
    Input,
    useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { showToast } from '../../../helpers'
import { createUserWithEmailAndPassword, sendEmailVerification, signOut } from 'firebase/auth'
import { firebaseAuth, firebaseFunctions } from '../../../firebase'
import { httpsCallable } from 'firebase/functions'


type ProfileFormData = {
    nombre: string,
    nombre_corto: string,
    direccion: string,
    localidad: string,
    cp: string,
    provincia: string,
    nombre_responsable: string,
    email_responsable: string
}

export function ProfileFormComponent(
    props: {
        email: string,
        password: string,
        cif: string,
        displayName: string
    }) {

    const createUserCommand = httpsCallable(
        firebaseFunctions,
        'createUserCommandCallable'
    );

    const createCompanyCommand = httpsCallable(
        firebaseFunctions,
        'createCompanyInErpCommandCallable'
    );

    const toast = useToast();
    const [name, setName] = useState('')
    const [shortName, setShortName] = useState('')
    const [address, setaddress] = useState('')
    const [town, setTown] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [province, setProvince] = useState('')
    const [responsibleName, setResponsibleName] = useState('')
    const [responsibleEmail, setResponsibleEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false);


    const {
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<ProfileFormData>({
        values: {
            nombre: name,
            nombre_corto: shortName,
            direccion: address,
            localidad: town,
            cp: postalCode,
            provincia: province,
            nombre_responsable: responsibleName,
            email_responsable: responsibleEmail
        }
    });

    const getMessageError = (code: string) => {
        if (code === 'auth/email-already-in-use') {
            return 'El correo ya está registrado.'
        } else if (code === 'auth/weak-password') {
            return 'La contraseña debe contener al menos 6 carácteres, minúsculas, mayúsculas y un número';
        } else {
            return code
        }
    }
    const send = async (data: ProfileFormData) => {
        return await new Promise<void>(async (resolve) => {
            setIsLoading(true)
            if (
                !data.nombre &&
                !data.nombre_corto &&
                !data.direccion &&
                !data.localidad &&
                !data.cp &&
                !data.provincia &&
                !data.nombre_responsable &&
                !data.email_responsable
            ) {
                showToast(toast, {
                    status: 'error',
                    title: 'Error',
                    description: 'Todos los campos son obligatorios'
                });
                setIsLoading(false);
                resolve();
            } else {
                createCompanyCommand({
                    data: { cif: props.cif, ...data }
                }).then((result) => {
                    if (result.data.success) {
                        createUserWithEmailAndPassword(firebaseAuth, props.email, props.password)
                            .then(async (userCredential) => {
                                var user = userCredential.user;
                                sendEmailVerification(user)
                                signOut(firebaseAuth)
                                createUserCommand({
                                    uid: user.uid,
                                    email: props.email,
                                    displayName: props.displayName,
                                    cif: props.cif,
                                    forceAuthorize: false
                                });
                                showToast(toast, {
                                    status: 'success',
                                    title: 'Success',
                                    description: 'Usuario creado. Verifique el correo'
                                });
                            })
                            .catch((error) => {
                                showToast(toast, {
                                    status: 'error',
                                    title: 'Error',
                                    description: getMessageError(error.code)
                                });
                            });
                    } else {
                        showToast(toast, {
                            status: 'error',
                            title: 'Error',
                            description: result.data.errorMessage
                        });
                    }

                }).catch((e) => {
                    console.error(e);
                });

            }
            setIsLoading(false)
        });
    }


    return (
        <Stack as='form' onSubmit={handleSubmit(send)} spacing="6">
            <Stack spacing="5">
                <FormControl>
                    <FormLabel htmlFor="nombre">Nombre fiscal</FormLabel>
                    <Input id="nombre" type="text" onChange={(e) => setName(e.currentTarget.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="nombre_corto">Nombre comercial</FormLabel>
                    <Input id="nombre_corto" type="text" onChange={(e) => setShortName(e.currentTarget.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="direccion">Dirección</FormLabel>
                    <Input id="direccion" type="text" onChange={(e) => setaddress(e.currentTarget.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="localidad">Localidad</FormLabel>
                    <Input id="localidad" type="text" onChange={(e) => setTown(e.currentTarget.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="cp">Código postal</FormLabel>
                    <Input id="cp" type="text" onChange={(e) => setPostalCode(e.currentTarget.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="provincia">Provincia</FormLabel>
                    <Input id="provincia" type="text" onChange={(e) => setProvince(e.currentTarget.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="nombre_responsable">Nombre responsable facturación</FormLabel>
                    <Input id="nombre_responsable" type="text" onChange={(e) => setResponsibleName(e.currentTarget.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="email_responsable">Email del responsable de facturación</FormLabel>
                    <Input id="email_responsable" type="email" onChange={(e) => setResponsibleEmail(e.currentTarget.value)} />
                </FormControl>

            </Stack>
            <Stack spacing="6">
                <Button
                    colorScheme='blue'
                    type='submit'
                    isLoading={isLoading}>
                    Crear</Button>
            </Stack>
        </Stack>
    )
}
