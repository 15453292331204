import {
    Box,
    Button,
    Center,
    Container,
    FormControl,
    FormLabel,
    Heading,
    Image,
    Input,
    Stack,
    Text,
    useToast,
} from '@chakra-ui/react'
import Logo from '../../assets/img/logo.png';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { sendPasswordResetEmail } from 'firebase/auth';
import { firebaseAuth } from '../../firebase';
import { showToast } from '../../helpers';
import { useNavigate } from 'react-router';

type ResetPasswordFormData = {
    email: string,
}

export const ResetPassword = () => {


    const toast = useToast();
    const navigate = useNavigate();
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    const {
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<ResetPasswordFormData>({
        values: {
            email,
        }
    });

    const send = async (data: ResetPasswordFormData) => {
        return await new Promise<void>(async (resolve) => {
            setIsLoading(true)
            if (!data.email) {
                showToast(toast, {
                    status: 'error',
                    title: 'Error',
                    description: 'Introduzca su correo'
                });
                resolve();
            } else {
                sendPasswordResetEmail(firebaseAuth, email)
                    .then(() => {
                        showToast(toast, {
                            status: 'success',
                            title: 'Success',
                            description: '¡Enviado! Revise su correo'
                        });
                        navigate('../../auth/login');
                    })
                    .catch((error) => {
                        if (error.code === 'auth/user-not-found'){
                            showToast(toast, {
                                status: 'error',
                                title: 'Error',
                                description: 'El correo no existe'
                            });
         
                        }
                    });

            }

            setIsLoading(false)
        });
    }

    return (
        <Container maxW="lg" py={{ base: '12', md: '14' }} px={{ base: '0', sm: '8' }}>
            <Stack spacing="8">
                <Stack spacing="6">
                    <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                        <Center>
                            <Image width={32} src={Logo} />
                        </Center>
                        <Heading size={{ base: 'xs', md: 'sm' }}>Cambiar Contraseña</Heading>
                        <Text color="fg.muted">
                            Enviar correo para reestablecer la contraseña
                        </Text>
                    </Stack>
                </Stack>
                <Box
                    py={{ base: '0', sm: '8' }}
                    px={{ base: '4', sm: '10' }}
                    bg={{ base: 'transparent', sm: 'bg.surface' }}
                    boxShadow={{ base: 'none', sm: 'md' }}
                    borderRadius={{ base: 'none', sm: 'xl' }}
                >
                    <Stack as='form' onSubmit={handleSubmit(send)} spacing="6">
                        <Stack spacing="5">
                            <FormControl>
                                <FormLabel htmlFor="email">Email</FormLabel>
                                <Input id="email" type="email" onChange={(e) => setEmail(e.currentTarget.value)} />
                            </FormControl>
                        </Stack>
                        <Stack spacing="6">
                            <Button
                                colorScheme='blue'
                                type='submit'
                                isLoading={isLoading}>
                                Enviar </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Container>
    )
}