import {
    Box,
    Button,
    ButtonGroup,
    HStack,
    IconButton,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Tooltip,
    Popover,
    PopoverTrigger,
    Portal,
    PopoverContent,
    PopoverArrow,
    PopoverHeader,
    PopoverCloseButton,
    PopoverBody,
    PopoverFooter,
} from '@chakra-ui/react';
import { FiCheckCircle, FiCreditCard, FiEdit, FiXCircle } from 'react-icons/fi';
import { Select as ReactSelect } from 'chakra-react-select';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { DocumentData, QueryDocumentSnapshot, QueryFieldFilterConstraint, collection, doc, limit, orderBy, query, startAfter, where } from 'firebase/firestore';
import { firebaseAuth, firebaseFirestore, firebaseFunctions } from '../../firebase';
import { formatDate, showToast } from '../../helpers';
import debounce from 'lodash.debounce';
import { TableLoadingSpinner } from '../../components/TableLoadingSpinner';
import { TableNoResultsMessage } from '../../components/TableNoResultsMessage';
import { Helmet } from 'react-helmet-async';
import { StatusBadge } from '../../components/StatusBadge';
import { BookingStatus, getStatusOptions } from '../../types/BookingStatus';
import { httpsCallable } from 'firebase/functions';
import { User } from 'firebase/auth';

export const BookingsList = () => {
    const [user, isAdmin, isManager, path]: [User, boolean, boolean, string] = useOutletContext();

    const checkAvailabilityCommand = httpsCallable(
        firebaseFunctions,
        'checkAvailabilityCallable'
    );

    const ApproveBookingCommand = httpsCallable(
        firebaseFunctions,
        'approveBookingCallable'
    );

    const DenyBookingCommand = httpsCallable(
        firebaseFunctions,
        'denyBookingCallable'
    );

    const RefundBookingCommand = httpsCallable(
        firebaseFunctions,
        'refundBookingCallable'
    );


    const toast = useToast();
    const pageSize = 25;
    const pagination = [];
    const [textTable, setTextTable] = useState<string>('');
    const [isLoadingAction, setIsloadingAction] = useState<boolean>(false)

    const [allClientsOptions, setAllClientsOptions] = useState<{ label: string, value: string }[]>([])
    const [selectedClient, setSelectedClient] = useState<string>((!isAdmin && !isManager) ? user.uid : '');
    const [selectedFacility, setSelectedFacility] = useState<string>();
    const [selectedStatus, setSelectedStatus] = useState<{ label: string, value: string }>();
    const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot>();
    const onStatusSelect = (e: any) => {
        setSelectedStatus(e);
    }

    const [docs, setDocs] = useState<QueryDocumentSnapshot[]>([]);

    let { clientId, facilityId } = useParams();

    if (!isAdmin && !isManager) {
        clientId = user.uid
    }

    const [clientsMap, setClients] = useState<Map<string, DocumentData>>(new Map<string, DocumentData>());
    const [facilitiesMap, setFacilities] = useState<Map<string, DocumentData>>(new Map<string, DocumentData>());

    const [searchQuery, setSearchQuery] = useState<string>();
    const changeHandler = (event: any) => setSearchQuery(event.target.value);
    const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

    const queryConstraints: QueryFieldFilterConstraint[] = [];
    if (selectedStatus) {
        queryConstraints.push(where('status', 'in', [selectedStatus.value]));
    }
    if (selectedFacility) {
        queryConstraints.push(where('facilityId', '==', selectedFacility))
    }
    if (clientId || selectedClient) {
        queryConstraints.push(where('userId', '==', (clientId) ? clientId : selectedClient));
    } else if (facilityId) {
        queryConstraints.push(where('facilityId', '==', facilityId));
    }
    if (lastVisible) {
        pagination.push(startAfter(lastVisible), limit(pageSize))
    } else {
        pagination.push(limit(pageSize))
    }

    const [bookingsQuerySnapshot, isLoading, bookingsLoadingError] = useCollection(
        query(
            collection(firebaseFirestore, 'bookings'),
            ...queryConstraints,
            orderBy('dates.created', 'desc'),
            ...pagination
        )
    );

    const [facilitiesDataSnapshot, isLoadingFacilities, facilitiesLoadingError] = useCollection(
        query(collection(firebaseFirestore, 'facilities'))
    );
    const [clientsDataSnapshot, isLoadingClients, clientsLoadingError] = useCollection(
        query(collection(firebaseFirestore, 'users'))
    );

    const [clientDataSnapshot, isLoadingClient, clientLoadingError] = useDocumentData(
        doc(firebaseFirestore, 'users', (clientId ? clientId : 'nonexistingclient'))
    );


    useEffect(() => {
        if (!isLoadingFacilities) {
            facilitiesDataSnapshot!.docs.map(el =>
                setFacilities(new Map(facilitiesMap.set(el.id, el.data())))
            )
            if (facilityId) {
                setTextTable(` - ${facilitiesMap.get(facilityId)!.name}`)
            }
        }

    }, [isLoadingFacilities]);

    useEffect(() => {
        if (!isLoadingClients && !clientId) {
            clientsDataSnapshot!.docs.map(el =>
                setClients(new Map(clientsMap.set(el.id, el.data())))
            )
            const clients: { label: string, value: string }[] = clientsDataSnapshot!.docs.map(el =>
                ({ label: el.data().displayName, value: el.id })
            )
            setAllClientsOptions(clients);
        }
        if (!isLoadingClient && clientId && clientDataSnapshot) {
            setClients(new Map(clientsMap.set(clientId, clientDataSnapshot)))
            setTextTable(` - ${clientsMap.get(clientId)!.displayName}`)
            setSelectedClient(firebaseAuth.currentUser!.uid);
        }
    }, [isLoadingClients, isLoadingClient]);

    useEffect(() => {
        if (bookingsLoadingError || facilitiesLoadingError) {
            showToast(toast, {
                title: 'Could not fetch data.',
                status: 'error'
            });
        }
    }, [bookingsLoadingError, facilitiesLoadingError, clientsLoadingError])

    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();
        }
    }, []);

    useEffect(() => {
        if (facilityId) {
            setTextTable(` - ${facilitiesMap.get(facilityId)}`);
        } else {
            setTextTable('');
        }

    }, [facilityId]);

    useEffect(() => {
        setLastVisible(undefined);
        setDocs([]);
    }, [
        selectedStatus,
    ]);

    useEffect(() => {
        if (bookingsQuerySnapshot?.docs) {
            if (lastVisible) {
                const mappedDocs: any = {};
                const newDocs = [...docs, ...bookingsQuerySnapshot.docs];
                newDocs.map(d => {
                    mappedDocs[d.id] = d;
                })
                setDocs(Object.values(mappedDocs));
            } else {
                setDocs(bookingsQuerySnapshot.docs);
            }
        }
    }, [bookingsQuerySnapshot]);

    const approveBooking = (bookingId: string, booking: DocumentData, closeFunction: () => void) => {
        setIsloadingAction(true)
        const success = () => {
            showToast(toast, {
                title: 'Success',
                description: 'La reserva ha sido aprobada',
                status: 'success',
            });
            setIsloadingAction(false);
            closeFunction()
        }
        const error = (e: any) => {
            console.error(e);
            showToast(toast, {
                title: 'Error',
                description: e,
                status: 'error',
            });
            setIsloadingAction(false);
            closeFunction()
        }

        checkAvailabilityCommand({ start: booking.start, end: booking.end, facilityId: booking.facilityId })
            .then((result) => {
                if (result.data.available) {
                    ApproveBookingCommand({ bookingId })
                        .then((result1) => {
                            if (result1.data.success) {
                                success()
                            } else {
                                error(result1.data.error)
                            }
                        }).catch((e) => {
                            console.error(e);
                            error('')
                        });

                } else {
                    error('No hay disponibilidad para las fechas indicadas')
                }

            }).catch((e) => {
                console.error(e);
                error('Ups! algo no funcionó. Inténtelo de nuevo')
            });

    }

    const denyBooking = (bookingId: string, closeFunction: () => void) => {
        setIsloadingAction(true)
        return new Promise<void>((resolve) => {
            const success = () => {
                showToast(toast, {
                    title: 'Success',
                    description: 'La reserva ha sido denegada',
                    status: 'success',
                });
                setIsloadingAction(false);
                closeFunction()
            }
            const error = (e: any) => {
                showToast(toast, {
                    title: 'Ups! Algo ha ido mal, inténtelo de nuevo',
                    status: 'error',
                });
                setIsloadingAction(false);
                closeFunction()
            }

            DenyBookingCommand({ bookingId })
                .then((result) => {
                    if (result.data.success) {
                        success()
                    } else {
                        error(result.data.error)
                    }
                }).catch((e) => {
                    console.error(e);
                    error('')
                });

            resolve()

        });
    }

    const refundBooking = (bookingId: string, closeFunction: () => void) => {
        setIsloadingAction(true)
        return new Promise<void>((resolve) => {
            const success = () => {
                showToast(toast, {
                    title: 'Success',
                    description: 'La reserva ha sido reembolsada',
                    status: 'success',
                });
                setIsloadingAction(false);
                closeFunction()
            }
            const error = (e: any) => {
                showToast(toast, {
                    title: 'Ups! Algo ha ido mal, inténtelo de nuevo',
                    status: 'error',
                });
                setIsloadingAction(false);
                closeFunction()
            }

            RefundBookingCommand({ bookingId })
                .then((result) => {
                    if (result.data.success) {
                        success()
                    } else {
                        error(result.data.error)
                    }
                }).catch((e) => {
                    console.error(e);
                    error('')
                });

            resolve()

        });
    }


    const getApprovalItem = (item, booking, closeFunction?) => {
        if (item === 'icon') {
            if (booking.data().status === BookingStatus.WAITING_FOR_PAYMENT) {
                return <FiCreditCard />
            } else {
                return <FiCheckCircle />
            }
        } else if (item === 'text') {
            if (booking.data().status === BookingStatus.WAITING_FOR_APPROVAL) {
                return '¿Desea aprobar la reserva sin descuento?';
            } else if (booking.data().status === BookingStatus.WAITING_FOR_PAYMENT) {
                return '¿Desea aprobar el pago de la reserva?'
            } else {
                return 'No hay acciones que realizar aquí'
            }
        } else {
            approveBooking(booking.id, booking, closeFunction)
        }

    }

    const getDenialItem = (item: string, booking: QueryDocumentSnapshot<DocumentData, DocumentData>, closeFunction?: { (): void; (): void; (): void; } | undefined) => {
        if (item === 'icon') {
            if (booking.data().status === BookingStatus.RESERVED) {
                return <FiCreditCard />
            } else {
                return <FiXCircle />
            }
        } else if (item === 'text') {
            if (booking.data().status === BookingStatus.RESERVED) {
                return '¿Desea marcar la reserva como reembolsada?';
            } else if ([BookingStatus.DENIED, BookingStatus.REFUNDED].includes(booking.data().status)) {
                return 'No Hay acciones que realizar aquí'
            } else {
                return '¿Desea denegar la reserva?'
            }
        } else if (item === 'function') {
            if (booking.data().status === BookingStatus.RESERVED) {
                refundBooking(booking.id, closeFunction)
            } else if (![BookingStatus.RESERVED, BookingStatus.REFUNDED].includes(booking.data().status)) {
                denyBooking(booking.id, closeFunction)
            }
        } else if (item === 'textButton') {
            if (booking.data().status === BookingStatus.RESERVED) {
                return 'Reembolsar';
            } else {
                return 'Denegar'
            }

        }
    }

    return (
        <>
            <Helmet>
                <title>PCTT - Listado de reservas</title>
            </Helmet>
            <Stack>
                <Box
                    bg='bg.surface'
                    boxShadow={{ base: 'none', md: 'sm' }}
                    borderRadius={{ base: 'none', md: 'lg' }}
                >
                    <Stack spacing='5'>
                        <Box px={{ base: '4', md: '6' }} pt='5'>
                            <Stack direction={{ base: 'column', md: 'row' }} justify='space-between'>
                                <Text fontSize={'3xl'} fontWeight='medium'>
                                    Reservas {textTable}
                                </Text>
                                <Stack direction={{ base: 'column', lg: 'row' }} alignSelf={{ base: 'flex-start', lg: 'flex-end' }} width={{ base: '100%', lg: 'auto' }}>
                                    {((isAdmin || isManager) && <ReactSelect
                                        chakraStyles={{
                                            container: (provided) => ({
                                                ...provided,
                                                display: 'flex',
                                                width: '100%',
                                                maxWidth: { base: 'full', lg: 'xs' }
                                            })
                                        }}
                                        isClearable={true}
                                        isLoading={isLoadingClients}
                                        placeholder='Cliente'
                                        onChange={nv => setSelectedClient(nv?.value!!)}
                                        options={allClientsOptions}
                                        noOptionsMessage={(_i) => 'No hay clientes.'}
                                    />)}
                                    <ReactSelect
                                        chakraStyles={{
                                            container: (provided) => ({
                                                ...provided,
                                                display: 'flex',
                                                width: '100%',
                                                maxWidth: { base: 'full', lg: 'xs' }
                                            })
                                        }}
                                        isClearable={true}
                                        isLoading={isLoadingFacilities}
                                        placeholder='Salas'
                                        onChange={nv => setSelectedFacility(nv?.value!!)}
                                        options={facilitiesDataSnapshot?.docs.map(o => ({ label: o.data().name, value: o.id }))}
                                        noOptionsMessage={(_i) => 'No hay salas.'}
                                    />

                                    <ReactSelect
                                        chakraStyles={{
                                            container: (provided) => ({
                                                ...provided,
                                                display: 'flex',
                                                width: '100%',
                                                maxWidth: { base: 'full', lg: 'xs' }
                                            })
                                        }}

                                        isClearable={true}
                                        value={selectedStatus}
                                        options={getStatusOptions()}
                                        placeholder='Estados'
                                        onChange={nv => onStatusSelect(nv)}
                                    />
                                </Stack>
                                <Menu>
                                    <MenuButton as={Button} colorScheme='blue' rightIcon={<ChevronDownIcon />}>
                                        Acciones
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem
                                            as='a'
                                            href={`../${path}/booking/new`}>
                                            Crear Reserva
                                        </MenuItem>
                                        <MenuItem
                                            as='a'
                                            href={`../${path}/bookings/calendar`}>
                                            {(isAdmin || isManager) ? 'Ver' : 'Mi'} calendario
                                        </MenuItem>
                                    </MenuList>
                                </Menu>

                            </Stack>
                        </Box>
                        <Box overflowX='auto' overflowY='hidden'>
                            <Table>
                                {(isLoading || isLoadingClients || isLoadingFacilities) &&
                                    <TableLoadingSpinner />
                                }
                                {(!isLoading && docs?.length === 0) &&
                                    <TableNoResultsMessage />
                                }
                                <Thead>
                                    <Tr>
                                        {(isAdmin || isManager) && <Th>Cliente</Th>}
                                        <Th>Sala</Th>
                                        <Th>Reserva</Th>
                                        <Th>Estado</Th>
                                        <Th></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {docs.map(booking => (
                                        <Tr key={booking.id}>

                                            {(isAdmin || isManager) && <Td>
                                                <Tooltip hasArrow bg='blue.400' label={(clientsMap.has(booking.data().userId) && isAdmin) ? clientsMap.get(booking.data().userId)!.additionalInformation : ''}>
                                                    <Text>{(clientsMap.has(booking.data().userId) ? clientsMap.get(booking.data().userId)!.displayName : '')}</Text>
                                                </Tooltip>
                                            </Td>}
                                            <Td>
                                                <Tooltip hasArrow bg='blue.400' label={(facilitiesMap.has(booking.data().facilityId) && (isAdmin || isManager)) ? facilitiesMap.get(booking.data().facilityId)!.info.additionalInformation : ''}>
                                                    <Text>{facilitiesMap.has(booking.data().facilityId) ? facilitiesMap.get(booking.data().facilityId)!.name : ''}</Text>
                                                </Tooltip>
                                            </Td>
                                            <Td><Tooltip placement='bottom-start' bg='blue.400' label={((booking.data().info.sector) ? booking.data().info.sector + ': ' : '') + ((booking.data().info.description) ? booking.data().info.description : 'Sin descripción')}><Text color='fg.muted'>{formatDate(booking.data().info.start)} - {formatDate(booking.data().info.end)}</Text></Tooltip></Td>
                                            <Td><StatusBadge size='sm' label={booking.data().dates.cancelled_at ? 'Cancelada' : booking.data().status} /></Td>
                                            <Td>
                                                <IconButton
                                                    icon={<FiEdit />}
                                                    as={Link}
                                                    to={`../bookings/${booking.id}`}
                                                    variant='tertiary'
                                                    aria-label='Edit booking' />
                                                {(isAdmin &&
                                                    <Popover>
                                                        {({ isOpen, onClose }) => (
                                                            <>
                                                                <PopoverTrigger>

                                                                    <IconButton
                                                                        isDisabled={([BookingStatus.WAITING_FOR_APPROVAL, BookingStatus.WAITING_FOR_PAYMENT].includes(booking.data().status)) ? false : true}
                                                                        icon={getApprovalItem('icon', booking)}
                                                                        variant='tertiary'
                                                                        aria-label='Edit booking'
                                                                        color={!([BookingStatus.WAITING_FOR_APPROVAL, BookingStatus.WAITING_FOR_PAYMENT].includes(booking.data().status)) ? 'grey' : 'green'}
                                                                    />
                                                                </PopoverTrigger>
                                                                <Portal>
                                                                    <PopoverContent>
                                                                        <PopoverHeader fontWeight='semibold'>¡Atención!</PopoverHeader>
                                                                        <PopoverArrow />
                                                                        <PopoverCloseButton />
                                                                        <PopoverBody>
                                                                            {getApprovalItem('text', booking)}
                                                                        </PopoverBody>
                                                                        <PopoverFooter display='flex' justifyContent='flex-end'>
                                                                            <ButtonGroup size='sm'>
                                                                                <Button
                                                                                    isLoading={isLoadingAction}
                                                                                    colorScheme='red'
                                                                                    onClick={(e) => getApprovalItem('function', booking, onClose)}>Aprobar
                                                                                </Button>
                                                                            </ButtonGroup>
                                                                        </PopoverFooter>
                                                                    </PopoverContent>
                                                                </Portal>
                                                            </>
                                                        )}
                                                    </Popover>
                                                )}
                                                {(isAdmin &&
                                                    <Popover>
                                                        {({ isOpen, onClose }) => (
                                                            <>
                                                                <PopoverTrigger>

                                                                    <IconButton
                                                                        isDisabled={[BookingStatus.REFUNDED, BookingStatus.DENIED].includes(booking.data().status) ? true : false}
                                                                        icon={getDenialItem('icon', booking)}
                                                                        variant='tertiary'
                                                                        aria-label='Edit booking'
                                                                        color={[BookingStatus.REFUNDED, BookingStatus.DENIED].includes(booking.data().status) ? 'grey' : 'red'} />
                                                                </PopoverTrigger>
                                                                <Portal>
                                                                    <PopoverContent>
                                                                        <PopoverHeader fontWeight='semibold'>¡Atención!</PopoverHeader>
                                                                        <PopoverArrow />
                                                                        <PopoverCloseButton size={'sm'} />
                                                                        <PopoverBody>
                                                                            {getDenialItem('text', booking)}
                                                                        </PopoverBody>
                                                                        <PopoverFooter display='flex' justifyContent='flex-end'>
                                                                            <ButtonGroup size='sm'>
                                                                                <Button
                                                                                    isDisabled={[BookingStatus.REFUNDED, BookingStatus.DENIED].includes(booking.data().status) ? true : false}
                                                                                    isLoading={isLoadingAction}
                                                                                    colorScheme='red'
                                                                                    onClick={(e) => getDenialItem('function', booking, onClose)}>{getDenialItem('textButton', booking)}
                                                                                </Button>
                                                                            </ButtonGroup>
                                                                        </PopoverFooter>
                                                                    </PopoverContent>
                                                                </Portal>
                                                            </>
                                                        )}

                                                    </Popover>
                                                )}
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Box>
                        <Box px={{ base: '4', md: '6' }} pb='5'>
                            <HStack spacing='3' justify='space-between'>
                                <Text color='fg.muted' textStyle='sm'>
                                    {`Mostrando ${docs.length} resultados`}
                                </Text>
                                <ButtonGroup
                                    spacing='3'
                                    justifyContent='flex-end'
                                    variant='secondary'>
                                    <Button
                                        isDisabled={(bookingsQuerySnapshot?.docs?.length ?? 0) < pageSize}
                                        onClick={() => {
                                            setLastVisible(bookingsQuerySnapshot?.docs[bookingsQuerySnapshot.docs.length - 1])
                                        }}>
                                        Mostrar más
                                    </Button>
                                </ButtonGroup>
                            </HStack>
                        </Box>
                    </Stack>
                </Box>
            </Stack>
        </>
    )

}