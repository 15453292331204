import { Box, HStack, IconButton, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { FiEdit2 } from 'react-icons/fi'
import { StatusBadge } from '../../components/StatusBadge'

export const BadgeDataBox = ({
    title,
    content,
    children,
    onEdit
}: {
    title: string,
    content?: string,
    children?: ReactNode | ReactNode[],
    onEdit?: () => void

}) => (
    <Box h={16}>
        <Text fontSize='lg' fontWeight='bold'>
            {title}
        </Text>
        {(content !== undefined) &&
            <HStack justify={'space-between'}>
                <StatusBadge size='md' label={content} />
                {onEdit &&
                    <Box pr={4}>
                        <IconButton
                            onClick={onEdit}
                            icon={<FiEdit2 />}
                            variant='tertiary'
                            aria-label={`Edit ${title}`} />
                    </Box>
                }
            </HStack>
        }
        {children}
        
        
    </Box>
)