import {
    Image,
    Text,
    Center,
    Stack,
    Button,
    LinkBox,
    LinkOverlay
} from '@chakra-ui/react';
import Logo from '../../../assets/img/logo.png';
import { ArrowBackIcon, CheckCircleIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

export const PaymentOk = () => {

    return (
        <Stack spacing={12}>
            <Stack>
                <Center>
                    <LinkBox>
                        <LinkOverlay as={Link} to='/'>
                            <Image sx={{ width: 150 }} src={Logo} />
                        </LinkOverlay>
                    </LinkBox>
                </Center>
            </Stack>
            <Stack spacing={4}>
                <Center>
                    <CheckCircleIcon boxSize={16} color='#FFC500' />
                </Center>
                <Text fontWeight={500} align={'center'} fontSize={'2xl'}>¡Pago realizado correctamente!</Text>
                <Text align={'center'}>Hemos recibido el pago y le hemos enviado la confirmación de compra por correo electrónico. </Text>
                <Text align={'center'}> Si no encuentra el correo asegúrese de revisar el buzón de correo no deseado.</Text>
            </Stack>
            <Stack spacing={4}>
                <Center px={8}>
                    <Button
                        variant={'outline'}
                        colorScheme='blue'
                        as={Link}
                        to={`/user/bookings`}
                        width='500px'
                        size='xl'
                        color={'#1E3689'}
                        borderRadius={'20px'}
                        leftIcon={<ArrowBackIcon boxSize={6} />}
                        >
                        Volver al inicio
                    </Button>
                </Center>
            </Stack>
        </Stack>
    )
}