import { CalendarIcon } from '@chakra-ui/icons'
import { Box, Button, FormControl, FormLabel, HStack, Heading, Input, Stack, useToast } from '@chakra-ui/react'
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { useState } from 'react';
import { showToast } from '../../helpers';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet-async';
import { Select } from 'chakra-react-select';
import { firebaseFunctions } from '../../firebase';
import { httpsCallable } from 'firebase/functions';
import { User } from 'firebase/auth';

type BookingFormData = {
    email?: string;
    displayName?: string;
    password?: string;
    category?: any
}

export const ClientForm = () => {

    const toast = useToast();
    const navigate = useNavigate();
    const [user, isAdmin, isManager, path]: [User, boolean, boolean, string] = useOutletContext();

    const createAdminUserCommand = httpsCallable(
        firebaseFunctions,
        'createUserAdminCommandCallable'
    );

    const [email, setEmail] = useState('')
    const [displayName, setDisplayName] = useState('')
    const [category, setCategory] = useState<{ label: string, value: string }>();
    const [password, setPassword] = useState('')

    const send = (data: BookingFormData) => {
        return new Promise<void>(async (resolve) => {
            const success = () => {
                showToast(toast, {
                    title: 'Success',
                    description: 'El cliente se ha creado con éxito',
                    status: 'success',
                });
                navigate('../clients', { replace: true });
                resolve()
            }
            const error = (e: any) => {
                console.error(e);
                showToast(toast, {
                    title: 'Error',
                    description: e,
                    status: 'error',
                });
                resolve()
            }

            if (!data.displayName || !data.email || !data.category || !data.password) {
                error('Debe rellenar todos los campos');
            } else {
                await createAdminUserCommand(
                    {
                        password,
                        email,
                        displayName,
                        category: category!.value
                    }).then((result) => {
                        if (!result.data.success) {
                            error(result.data.error)
                        } else {
                            success()
                        }
                    }).catch((e) => {
                        console.error(e);
                        error('')
                    });
            }
        });
    }

    const {
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<BookingFormData>({
        values: {
            password,
            email,
            displayName,
            category
        },
        resetOptions: {
            keepDirtyValues: true
        }
    });

    return (
        <>
            <Helmet>
                <title>PCTT - Bonus</title>
            </Helmet>
            <Stack spacing={8}>
                <HStack spacing={4}>
                    <CalendarIcon boxSize={8} />
                    <Heading size='md'>
                        {'Crear Reserva'}
                    </Heading>
                </HStack>
                <Stack as='form' spacing={8} onSubmit={handleSubmit(send)} noValidate>
                    <Box bg='bg-surface' boxShadow='sm' borderRadius='lg' flexGrow={1} pb={4}>
                        <Stack spacing='5' px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                            <Stack direction={{ base: 'column', lg: 'row' }} alignSelf={{ base: 'flex-start', lg: 'flex-end' }} width={{ base: '100%' }} mb={5} display='flex'>
                                <FormControl>
                                    <FormLabel htmlFor='description'>Email</FormLabel>
                                    <Input
                                        placeholder="Escriba una email válido"
                                        size="md"
                                        type="email"
                                        onChange={(e) => setEmail(e.currentTarget.value)} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor='description'>Contraseña</FormLabel>
                                    <Input
                                        placeholder="Escriba una contraseña"
                                        size="md"
                                        type="password"
                                        onChange={(e) => setPassword(e.currentTarget.value)} />
                                </FormControl>
                            </Stack>
                            <Stack direction={{ base: 'column', lg: 'row' }} alignSelf={{ base: 'flex-start', lg: 'flex-end' }} width={{ base: '100%' }} mb={5} display='flex'>
                                <FormControl>
                                    <FormLabel htmlFor='description'>Nombre</FormLabel>
                                    <Input
                                        placeholder="Escriba un nombre"
                                        size="md"
                                        type="text"
                                        onChange={(e) => setDisplayName(e.currentTarget.value)} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor='sectors'>Rol:</FormLabel>
                                    <Select
                                        options={[{ label: 'Admin', value: 'admin' }, { label: 'Gestor', value: 'manager' }]}
                                        value={category ? category : 'ADMIN'}
                                        placeholder='Elija un rol'
                                        onChange={nv => setCategory(nv!)}
                                    />
                                </FormControl>
                            </Stack>
                        </Stack>
                    </Box>
                    <HStack justify={'flex-end'}>
                        <Button as={Link} to={'../../clients'} replace variant='ghost' size='lg'>Cancelar</Button>
                        <Button
                            variant='primary'
                            isLoading={isSubmitting}
                            isDisabled={false}
                            type='submit'
                            size='lg'>
                            Crear
                        </Button>
                    </HStack>
                </Stack>
            </Stack>
        </>
    )
}