import { AccordionPanel, Avatar, AvatarBadge, Box, Button, Card, CardBody, Heading, Stack, Text, WrapItem } from "@chakra-ui/react";
import { QueryDocumentSnapshot } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { useState } from "react";
import { FacilityStatus } from "../../types/FacilityStatus";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router";
import Loading from '../../assets/img/loading.gif'
import parse from 'html-react-parser';


export function PublicFacilityItem(props: { doc: QueryDocumentSnapshot }) {

  const [urlImage, setUrlImage] = useState<string>(Loading);
  const doc = props.doc;
  const storage = getStorage();
  const navigate = useNavigate();

  getDownloadURL(ref(storage, doc.data().info.smallImage)).then((result) => setUrlImage(result))

  const goTo = (id: string) => {
    navigate('../facilities/' + id + '/book', { replace: false });
  }

  return (
    <AccordionPanel pb={4} key={doc.id}>
      <Card
        direction={{ base: 'column', sm: 'row' }}
        overflow='hidden'
        variant='outline'
      >
        <WrapItem p={4}>
          <Avatar borderRadius={'0.5em'} size='2xl' src={urlImage}>
            <AvatarBadge boxSize='0.7em' border={'4px'} bg={(doc.data().status === FacilityStatus.AVAILABLE) ? 'green.500' : 'red'} />
          </Avatar>
        </WrapItem>

        <Stack>
          <CardBody>
            <Heading size='sm'>{doc.data().name}</Heading>
            <Box py={2}>
              <div
                dangerouslySetInnerHTML={{ __html: doc.data().info.shortDescription }}
              />
            </Box>
          </CardBody>

        </Stack>
        <Stack position={'absolute'} right={0} mt={4} mr={2}>
          <Button
            isDisabled={doc.data().status === FacilityStatus.UNAVAILABLE}
            onClick={(e) => goTo(doc.id)}
            size='sm'
            rightIcon={<ArrowForwardIcon />}
            colorScheme='teal'
          >
            Reservar
          </Button>
        </Stack>

      </Card>
    </AccordionPanel>

  )
}
