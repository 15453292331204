import { AddIcon, CloseIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { AbsoluteCenter, Box, Button, Card, CardBody, FormControl, FormLabel, HStack, Heading, IconButton, Input, Link, ScaleFade, Stack, Switch, Text, useToast } from '@chakra-ui/react'
import { DocumentData, addDoc, collection, doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { firebaseFirestore } from '../../firebase';
import { useEffect, useState } from 'react';
import { showToast } from '../../helpers';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet-async';
import { MultiSelect } from 'chakra-multiselect'
import { RateUnitType, getRateUnitTypes } from '../../types/RateUnitType';
import { getLabel } from '../../common/labelsFactory';
import { FacilityStatus } from '../../types/FacilityStatus';
import { FacilityType, getFacilityTypes } from '../../types/FacilityType';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { formats, modules } from '../components/EditorToolbar';
import { Dropzone } from '../components/Dropzone';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

type FacilityFormData = {
    name?: string,
    status?: FacilityStatus,
    type?: FacilityType,
    info?: {
        fullDescription?: string,
        shortDescription?: string,
        dimensions?: string,
        capacity?: number,
        location?: any,
        images?: string[],
        mainImage?: string,
        smallImage?: string,
        additionalInformation?: string,
    },
    rates?: {
        unitType?: RateUnitType,
        price?: number,
    }[],
}

export const FacilitiesForm = () => {

    const { id } = useParams();
    const isNew = id === 'new';
    const toast = useToast();
    const navigate = useNavigate();

    const [name, setName] = useState<string>();
    const [status, setStatus] = useState<boolean>();
    const [type, setType] = useState<FacilityType>(FacilityType.MEETING_ROOM);
    const [fullDescription, setFullDescription] = useState<string>();
    const [shortDescription, setShortDescription] = useState<string>();
    const [additionalInformation, setAdditionalInformation] = useState<string>('');
    const [dimensions, setDimensions] = useState<string>();
    const [capacity, setCapacity] = useState<number>();
    const [location, setLocation] = useState<string>('');
    const [allLocations, setAllLocations] = useState<{ label: string, value: string }[]>([])
    const [AllLocationsMap, setAllLocationsMap] = useState<Map<string, { name: string, externalId: string }>>()
    const [mainImage, setMainImage] = useState<string>();
    const [smallImage, setSmallImage] = useState<string>();
    const [images, setImages] = useState<string[]>([]);
    const [rateFields, setRateFields] = useState<{ unitType?: RateUnitType | undefined, price?: number | undefined }[]>([
        { unitType: RateUnitType.HOUR, price: undefined }
    ])

    const [mainImageUrl, setMainImageUrl] = useState<string>()
    const [mainSmallImageUrl, setMainSmallImageUrl] = useState<string>()
    const [slideImagesUrl, setSlideImageUrl] = useState<string[]>([])

    const [facilityDataSnapshot, _isLoading, errorLoadingFacility] = useDocumentData(
        doc(firebaseFirestore, 'facilities', id!!)
    );

    const [settingsSnapshot, isLoadingSettings, errorLoadingSettings] = useDocumentData(
        doc(firebaseFirestore, 'settings', 'public')
    );

    useEffect(() => {
        if (!isLoadingSettings && settingsSnapshot) {
            const locations = (settingsSnapshot.locations.map((l: { name: any; externalId: any; }) => ({ label: l.name, value: l.externalId })));
            setAllLocations(locations);
            const tempMap = new Map();
            settingsSnapshot.locations.forEach((l: { externalId: string; name: any; }) => {
                tempMap.set(l.externalId, { name: l.name, externalId: l.externalId })
            });
            setAllLocationsMap(tempMap);
        }
    }, [isLoadingSettings])


    const addRateFields = () => {
        let newfield = { unitType: RateUnitType.HOUR, price: undefined }
        setRateFields([...rateFields, newfield])
    }
    const removeRateFields = (index: number) => {
        let data = [...rateFields];
        data.splice(index, 1)
        setRateFields(data)
    }
    const getUnitRates = (unit?: RateUnitType): any => {
        if (unit) {
            return { label: getLabel(unit), value: unit }
        } else {
            return getRateUnitTypes()
        }
    }

    const setRateFieldsFromFacility = (facilityDataSnapshot: DocumentData) => {
        if (facilityDataSnapshot) {
            let fieldsFromFacility: { unitType: RateUnitType, price: number }[] = [];
            for (let i = 0; i < facilityDataSnapshot.rates.length; i++) {
                fieldsFromFacility.push({
                    price: facilityDataSnapshot.rates[i].price,
                    unitType: facilityDataSnapshot.rates[i].unitType,
                });
            }
            setRateFields(fieldsFromFacility);
        }
    }

    useEffect(() => {

        if (errorLoadingFacility) {
            console.error(errorLoadingFacility);
            showToast(toast, {
                title: 'No se puede actualizar la sala.',
                status: 'error'
            });
            navigate('../facilities', { replace: true });
        }
        if (_isLoading === false) {
            if (facilityDataSnapshot) {
                setName(facilityDataSnapshot.name);
                setStatus(facilityDataSnapshot.status === FacilityStatus.AVAILABLE)
                setType(facilityDataSnapshot.type);
                setFullDescription(facilityDataSnapshot.info.fullDescription);
                setShortDescription(facilityDataSnapshot.info.shortDescription);
                setDimensions(facilityDataSnapshot.info.dimensions);
                setCapacity(facilityDataSnapshot.info.capacity);
                setLocation(facilityDataSnapshot.info.location.externalId);
                setMainImage(facilityDataSnapshot.info.mainImage);
                setSmallImage(facilityDataSnapshot.info.smallImage);
                setImages(facilityDataSnapshot.info.images);
                setRateFieldsFromFacility(facilityDataSnapshot);
                setAdditionalInformation(facilityDataSnapshot.info.additionalInformation)

                if (facilityDataSnapshot.info.mainImage) {
                    setUrl('main', facilityDataSnapshot.info.mainImage);
                }
                if (facilityDataSnapshot.info.smallImage) {
                    setUrl('small', facilityDataSnapshot.info.smallImage);
                }
                if (facilityDataSnapshot.info.images && facilityDataSnapshot.info.images.length > 0) {
                    setSlideImageUrl(facilityDataSnapshot.info.images);
                }
            } else if (!isNew) {
                showToast(toast, {
                    title: 'No existe la sala',
                    status: 'error',
                });
                navigate('../facilities', { replace: true });
            }
        }
    }, [errorLoadingFacility, _isLoading]);

    const send = (data: FacilityFormData) => {
        return new Promise<void>((resolve) => {
            const success = () => {
                showToast(toast, {
                    title: isNew ? 'Sala creada' : 'Sala editada',
                    description: isNew ?
                        'La sala ha sido creada satisfactoriamente.' :
                        'La sala se ha editado satisfactoriamente.',
                    status: 'success',
                });
                navigate('../facilities', { replace: true });
            }
            const error = (message: string) => {
                console.error(message);
                showToast(toast, {
                    title: message,
                    status: 'error',
                });
            }

            let errorMessage = null
            if (
                !data.name ||
                !data.type ||
                !data.info ||
                !data.info.fullDescription ||
                !data.info.shortDescription ||
                !data.info.dimensions ||
                !data.info.capacity ||
                !data.info.location ||
                !data.info.images ||
                !data.info.mainImage ||
                !data.info.smallImage ||
                !rateFields ||
                rateFields.length === 0
            ) {
                errorMessage = 'Todos los campos son obligatorios';
            } else {
                data.rates = rateFields;
                const unitTypesSelected: RateUnitType[] = []
                data.rates.forEach(rate => {
                    if (!rate.price || rate.price === 0 || !rate.unitType) {
                        errorMessage = "Todos los campos de las tarifas son obligatorios"
                    }
                    if (unitTypesSelected.includes(rate.unitType!)) {
                        errorMessage = "No se puede elegir varias tarifas para la misma unidad de tiempo"
                    }
                    unitTypesSelected.push(rate.unitType!);
                });
            }

            if (errorMessage) {
                error(errorMessage)
                resolve()
            } else {
                data.info!.location = {
                    name: AllLocationsMap!.get(location)!.name,
                    externalId: location
                }
                if (isNew) {
                    addDoc(
                        collection(firebaseFirestore, 'facilities'), {
                        ...data,
                        created: serverTimestamp(),
                        updated: serverTimestamp()
                    })
                        .then(success)
                        .catch(() => error('Error creando la sala'))
                        .finally(resolve);
                } else {
                    updateDoc(
                        doc(firebaseFirestore, 'facilities', id!!), {
                        ...data,
                        updated: serverTimestamp()
                    })
                        .then(success)
                        .catch(() => error('Error editando la sala'))
                        .finally(resolve);
                }
            }
        });
    }

    const {
        handleSubmit,
        register,
        setValue,
        control,
        watch,
        formState: { errors, isSubmitting },
    } = useForm<FacilityFormData>({
        values: {
            name,
            status: (status) ? FacilityStatus.AVAILABLE : FacilityStatus.UNAVAILABLE,
            type,
            info: {
                fullDescription,
                shortDescription,
                dimensions,
                capacity,
                location,
                images,
                mainImage,
                smallImage,
                additionalInformation
            },
            rates: rateFields
        },
        resetOptions: {
            keepDirtyValues: true
        }
    });

    const handleFormChange = (index: number, event?: any, name?: string, value?: string) => {
        let data = [...rateFields];
        data[index][(event) ? event.target.name : name] = (event) ? Number(event.target.value) : value;
        setRateFields(data);
    }

    const deleteImageFromSlide = (index: number) => {
        const urls = [...slideImagesUrl];
        const paths = [...images];
        urls.splice(index, 1);
        paths.splice(index, 1);
        setSlideImageUrl(urls);
        setImages(paths);
    }

    const setSlideImages = async (files: string[]) => {
        const urls = [];
        const paths = [];
        for (const file of files) {
            urls.push(await getUrl(file));
            paths.push(file);
        }
        if (paths.length > 0) {
            setImages(paths);
            setSlideImageUrl(urls);
        }
    }
    const setUrl = async (imgType: string, path: string) => {

        const url = path ? await getUrl(path) : '';
        if (imgType === 'main') {
            setMainImageUrl(url)
        } else if (imgType === 'small') {
            setMainSmallImageUrl(url)
        }
    }
    const getUrl = async (path: string) => {
        const storage = getStorage();
        return await getDownloadURL(ref(storage, path));
    }

    return (
        <>
            <Helmet>
                <title>PCTT - Bonus</title>
            </Helmet>
            <Stack spacing={8}>
                <HStack spacing={4}>
                    <EditIcon boxSize={8} />
                    <Heading size='md'>
                        {(isNew) ? 'Añadir nueva sala' : 'Editar sala'}
                    </Heading>
                </HStack>
                <Stack as='form' spacing={7} onSubmit={handleSubmit(send)} noValidate>
                    <Box bg='bg-surface' boxShadow='sm' borderRadius='lg' flexGrow={1} pb={4}>
                        <Stack spacing='3' px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                            <FormControl>
                                <Box display={'flex'} position={'absolute'} right={0} top={0}>
                                    <Switch isChecked={status} id='status' onChange={() => setStatus(!status)} />
                                    <FormLabel pl={4} htmlFor='status'>Sala Disponible</FormLabel>
                                </Box>
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor='description'>Nombre de la sala</FormLabel>
                                <Input id='name' value={name} type='text' onChange={(e) => setName(e.currentTarget.value)} />
                            </FormControl>
                            <FormControl>
                                <MultiSelect
                                    options={getFacilityTypes()}
                                    name='type'
                                    value={type}
                                    label='Elige el tipo de sala'
                                    onChange={(event: FacilityType) => setType(event)}
                                    single
                                />
                            </FormControl>
                            <Box display={'flex'}>
                                <FormControl pt={3}>
                                    <FormLabel htmlFor='dimensions'>Medidas de la sala</FormLabel>
                                    <Input id='dimensions' value={dimensions} type='text' onChange={(e) => setDimensions(e.currentTarget.value)} />
                                </FormControl>
                                <FormControl pt={3}>
                                    <FormLabel htmlFor='capacity'>Capacidad de la sala</FormLabel>
                                    <Input
                                        id='capacity'
                                        value={capacity ?? ''}
                                        type='number'
                                        step='1'
                                        onChange={(e) => { setCapacity(Number(e.currentTarget.value)) }}
                                        onKeyDown={(e) => {
                                            if (
                                                !(
                                                    (e.key >= '0' && e.key <= '9') ||
                                                    e.key === 'Backspace' ||
                                                    e.key === 'ArrowLeft' ||
                                                    e.key === 'ArrowRight' ||
                                                    e.key === 'Delete'
                                                )
                                            ) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </FormControl>
                                <FormControl pt={3}>
                                    <MultiSelect
                                        options={allLocations}
                                        name='location'
                                        value={location}
                                        label='Edificio'
                                        onChange={(event: any) => setLocation(event)}
                                        single
                                    />
                                </FormControl>
                            </Box>
                            <FormControl>
                                <FormLabel htmlFor='shortDescription'>Descripción breve de la sala</FormLabel>
                                <ReactQuill
                                    value={shortDescription}
                                    onChange={setShortDescription}
                                    placeholder={"Escribe una breve descripción de la sala..."}
                                    modules={modules}
                                    formats={formats}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor='fullDescription'>Descripción completa de la sala</FormLabel>
                                <ReactQuill
                                    value={fullDescription}
                                    onChange={setFullDescription}
                                    placeholder={"Escribe la descripción completa de la sala..."}
                                    modules={modules}
                                    formats={formats}
                                />

                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor='description'>Observaciones privadas</FormLabel>
                                <Input id='additionalInformation' value={additionalInformation} type='text' onChange={(e) => setAdditionalInformation(e.currentTarget.value)} />
                            </FormControl>

                            <Box mt={8} backgroundColor={'gray.100'} bg='bg-surface' boxShadow='sm' borderRadius='lg' flexGrow={1} p={4}>
                                <FormLabel >Imágenes:</FormLabel>

                                <FormControl>
                                    <Box pt={4} display={'flex'}>
                                        <Text pr={5}>Imagen principal: </Text>
                                        {mainImageUrl && <Link isExternal={true} href={mainImageUrl}>Imagen seleccionada</Link>}
                                        {mainImageUrl &&
                                            <Button
                                                aria-label="Eliminar imagen"
                                                colorScheme="red"
                                                size="xs"
                                                height={'20px'}
                                                ml={2}
                                                onClick={() => {
                                                    setMainImage('');
                                                    setUrl('main', '')
                                                }}
                                            >Borrar</Button>
                                        }
                                    </Box>
                                    <Dropzone
                                        location='imgfacilities'
                                        onFileUploaded={(files) => {
                                            setMainImage(files[0].path);
                                            setUrl('main', files[0].path)
                                        }}
                                        multiple={false}
                                    />

                                </FormControl>
                                <FormControl>
                                    <Box pt={4} display={'flex'}>
                                        <Text pr={5}>Imagen principal pequeña: </Text>
                                        {mainSmallImageUrl && <Link isExternal href={mainSmallImageUrl}>Imagen seleccionada</Link>}
                                        {mainSmallImageUrl &&
                                            <Button
                                                aria-label="Eliminar imagen"
                                                colorScheme="red"
                                                size="xs"
                                                height={'20px'}
                                                ml={2}
                                                onClick={() => {
                                                    setSmallImage('');
                                                    setUrl('small', '')
                                                }}
                                            >Borrar</Button>
                                        }

                                    </Box>
                                    <Dropzone
                                        location='imgfacilities'
                                        onFileUploaded={(files) => {
                                            setSmallImage(files[0].path);
                                            setUrl('small', files[0].path)
                                        }}
                                        multiple={false}
                                    />
                                </FormControl>
                                <FormControl>
                                    <Box pt={4} display={'flex'}>
                                        <Text pr={2}>Imágenes del carrusel: </Text>
                                        {slideImagesUrl.map((url, index) => {
                                            return (
                                                <>
                                                    <Link pl={2} isExternal href={url}>Imagen {index + 1}</Link>
                                                    <Button
                                                        aria-label="Eliminar imagen"
                                                        colorScheme="red"
                                                        size="xs"
                                                        height={'20px'}
                                                        ml={2}
                                                        onClick={() => {
                                                            deleteImageFromSlide(index);
                                                        }}
                                                    >Borrar</Button>
                                                </>
                                            )
                                        })}
                                    </Box>
                                    <Dropzone
                                        location='imgfacilities'
                                        onFileUploaded={(files) => {
                                            setSlideImages(files.map((e => (e.path))));
                                        }}
                                        multiple={true}
                                    />
                                </FormControl>
                            </Box>
                            <Box mt={8} backgroundColor={'gray.100'} bg='bg-surface' boxShadow='sm' borderRadius='lg' flexGrow={1} p={4}>
                                <FormLabel >Tarifas:</FormLabel>

                                {rateFields.map((input, index) => {
                                    return (
                                        <Box>
                                            <ScaleFade initialScale={0.9} in={true}>
                                                <Card key={index} mt={2}>
                                                    <CardBody backgroundColor={'gray.300'}>
                                                        <Box position={'absolute'} right={2} top={2}>
                                                            <IconButton
                                                                colorScheme='red'
                                                                aria-label='Search database'
                                                                icon={<DeleteIcon />}
                                                                size={'xs'}
                                                                onClick={() => removeRateFields(index)}
                                                            />
                                                        </Box>
                                                        <Box display={'flex'}>
                                                            <FormControl pt={3}>
                                                                <FormLabel htmlFor='price'>Precio</FormLabel>
                                                                <Input
                                                                    name='price'
                                                                    value={input.price}
                                                                    type='number'
                                                                    onChange={event => handleFormChange(index, event)}
                                                                />
                                                            </FormControl>

                                                            <FormControl pt={3}>
                                                                <MultiSelect
                                                                    options={getUnitRates()}
                                                                    name='unitType'
                                                                    value={input.unitType}
                                                                    label='Unidad de tiempo'
                                                                    onChange={(event: string | undefined) => handleFormChange(index, null, 'unitType', event)}
                                                                    single
                                                                />
                                                            </FormControl>

                                                        </Box>
                                                    </CardBody>
                                                </Card>
                                            </ScaleFade>
                                        </Box>
                                    )
                                })}
                                <Box position='relative' h='100px'>
                                    <AbsoluteCenter p='4' >
                                        <IconButton
                                            isRound={true}
                                            variant='solid'
                                            colorScheme='teal'
                                            aria-label='Done'
                                            fontSize='20px'
                                            icon={<AddIcon />}
                                            onClick={addRateFields}
                                        />
                                    </AbsoluteCenter>
                                </Box>
                            </Box>
                        </Stack>
                    </Box>
                    <HStack justify={'flex-end'}>
                        <Button as={Link} href={'../facilities'} variant='ghost' size='lg'>Cancelar</Button>
                        <Button
                            variant='primary'
                            isLoading={isSubmitting}
                            isDisabled={false}
                            type='submit'
                            size='lg'>
                            Guardar
                        </Button>
                    </HStack>
                </Stack>
            </Stack>
        </>
    )

}