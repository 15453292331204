import {
    Image,
    Text,
    Center,
    Stack,
    Button,
    LinkBox,
    LinkOverlay,
} from '@chakra-ui/react';
import Logo from '../../assets/img/logo.png';
import { InfoIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { useParams } from 'react-router';
import { httpsCallable } from '@firebase/functions';
import { Link } from 'react-router-dom';
import { firebaseFunctions } from '../../firebase';
import { BookingStatus } from '../../types/BookingStatus';

const checkoutCallable = httpsCallable<any, any>(firebaseFunctions, 'checkoutCommandCallable');

export const Checkout = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [mainMessage, setMainMessage] = useState('Está a un solo paso de completar la reserva');
    const [errorMessage, setErrorMessage] = useState('')
    const { bookingId } = useParams();

    const send = async () => {
        setIsLoading(true);
        setMainMessage('Redirigiendo a la pasarela de pago...')
        try {
            const response = await checkoutCallable({ bookingId });
            if (response.data.paymentLink) {
                window.location.href = response.data.paymentLink;
            } else {
                setIsLoading(false);
                if (response.data.bookingStatus === BookingStatus.WAITING_FOR_APPROVAL) {
                    setMainMessage('Esta reserva aún no ha sido aprobada');
                } else if (response.data.bookingStatus === BookingStatus.RESERVED) {
                    setMainMessage('Esta reserva ya ha sido pagada');
                } else {
                    setMainMessage('Esta reserva está cancelada por el administrador. No es posible su pago');
                }
            }
        } catch (e) {
            setIsLoading(false);
            setMainMessage('Tenemos problemas para preparar el pago. Inténtelo más tarde.')
            console.error(e);
        }
    }

    return (
        <Stack spacing={8}>
            <Stack>
                <Center>
                    <LinkBox>
                        <LinkOverlay as={Link} to='/'>
                            <Image sx={{ width: 150 }} src={Logo} />
                        </LinkOverlay>
                    </LinkBox>
                </Center>
            </Stack>
            <Stack spacing={4}>
                <Center>
                    <InfoIcon boxSize={16} color='#FFC500' />
                </Center>
                <Text fontWeight={500} align={'center'} fontSize={'2xl'}>{mainMessage}</Text>
                <Text align={'center'}>{errorMessage}</Text>
            </Stack>
            <Stack spacing={4}>
                <Center px={8}>
                    <Button
                        onClick={send}
                        isLoading={isLoading}
                        width='500px'
                        size='xl'
                        borderRadius={'20px'}
                        variant='outline'
                        colorScheme='blue'
                        color={'#1E3689'}>
                        Pagar con tarjeta
                    </Button>
                </Center>
            </Stack>
        </Stack>
    )
}