import { CreateToastFnReturn } from '@chakra-ui/react'
import { Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';

type ToastProps = {
    title: string,
    description?: string;
    status: 'success' | 'error';
}
export const showToast = (toastInstace: CreateToastFnReturn, props: ToastProps) => {
    toastInstace({
        title: props.title,
        position: 'top-right',
        description: props.description ?? '',
        status: props.status,
        duration: 3000,
        isClosable: true
    });
}

export function formatPrice(
    price?: number,
) {
    return new Intl.NumberFormat('es', {
        style: 'currency',
        currency: 'EUR'
    }).format(price ?? 0)
}


export function formatDate(timestamp?: Timestamp) {
    let dt = DateTime.now();
    if (timestamp) {
        dt = DateTime.fromMillis(timestamp.toMillis())
    }
    return dt.setLocale('es').toLocaleString(DateTime.DATETIME_SHORT);
}