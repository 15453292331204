import { Stack, Center, VStack, CircularProgress, Icon, Text, useToast } from '@chakra-ui/react';
import { SetStateAction, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FiUploadCloud } from 'react-icons/fi';
import { firebaseStorage } from '../../firebase';
import { showToast } from '../../helpers';
import { ref, uploadBytes } from '@firebase/storage';
import { nanoid } from 'nanoid';

type DropzoneProps = {
    location?: string;
    isDisabled?: boolean;
    multiple?: boolean;
    onFileUploaded?: (files: { path: string, name: string }[]) => void
}

export const Dropzone = (props: DropzoneProps) => {
    let location = props.location;
    if (!location) {
        location = `uploads`
    }
    const toast = useToast();

    const [isUploading, setIsUploading] = useState(false);
    const [uploadedFileNameList, setUploadedFileNameList] = useState<string[]>([]);

    const uploadFile = async (files: File[]) => {
        const uploadedFileNames: SetStateAction<string[]> = []
        const result: { path: string; name: string; }[] = [];
        for (const file of files) {
            if (file.size <= 0) {
                showToast(toast, {
                    title: 'Error',
                    description: 'El fichero '+file.name+' no se ha subido correctamente',
                    status: 'error'
                });
                continue;
            }
            const id = nanoid();
            const extension = file.name.split('.').pop();
            const fullUploadPath = `${location}/${id}.${extension}`;
            const storageRef = ref(firebaseStorage, fullUploadPath);
            setIsUploading(true);
            await uploadBytes(
                storageRef,
                file,
                { customMetadata: { originalName: file.name } })
                .then(() => {
                    if (props.onFileUploaded) {
                        result.push({ name: file.name, path: fullUploadPath });
                    }
                    uploadedFileNames.push(file.name);
                })
                .catch((e) => {
                    console.error(e);
                    showToast(toast, {
                        title: 'Error guardando el fichero '+file.name+'. Inténtelo de nuevo.',
                        status: 'error'
                    });
                })
                .finally(() => {
                    setIsUploading(false);
                });
        }

        if (props.onFileUploaded){
            props.onFileUploaded(result);
        }
        setUploadedFileNameList(uploadedFileNames)

    }

    const onInvalidFile = () => {
        showToast(toast, {
            title: 'Sólo imágenes jpg o png de menos de 3MB. '+((props.multiple)?'Máximo 5 imágenes.':''),
            status: 'error',
        });
    }
    const {
        open,
        isDragActive,
        getRootProps,
        getInputProps,
    } = useDropzone({
        disabled: props.isDisabled || isUploading,
        async onDropAccepted(files) {
            await uploadFile(files);
        },
        onDropRejected(_fileRejections) {
            onInvalidFile();
        },
        noClick: true,
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        multiple: (props.multiple) ? true : false,
        maxSize: 5 * 1024 * 1024,
        maxFiles: 5
    });

    const getBorderColor = () => {
        if (isDragActive) {
            return 'brand.800';
        }
        return 'gray.300';
    }

    return (
        <Stack spacing={4} width={'full'} direction={'row'} alignItems='center'>
            <Center
                width={'full'}
                borderWidth='1px'
                borderRadius='lg'
                borderColor={getBorderColor()}
                px='6'
                py='4'
                bgColor={'gray.200'}
                {...getRootProps({ className: 'dropzone' })}
            >
                <input {...getInputProps()} />
                <VStack spacing='3' >
                    {isUploading &&
                        <CircularProgress isIndeterminate color='brand.900' />
                    }
                    {!isUploading &&
                        <Icon as={FiUploadCloud} boxSize='10' />
                    }
                    <VStack spacing='1'>
                        <Center onClick={open}>
                            <Text fontSize='sm' color='muted' align={'center'}>
                                <Text as='span' cursor={'pointer'}>
                                    Click <b>aquí</b> para subir {(props.multiple) ? 'las imágenes o arrástralas' : 'la imagen o arrástrala'} hasta aquí.
                                </Text>
                            </Text>
                        </Center>
                        <Text fontSize='xs' color='muted'>
                            archivos .jpg o .png como máximo de 3MB
                        </Text>
                        {uploadedFileNameList.map((name) => {
                                    return (
                                        <Text fontSize='md' fontWeight={'bold'} pt={4}>
                                        {name}
                                    </Text>
                                    )
                        })}
                    </VStack>
                </VStack>
            </Center>
        </Stack>
    )
}
