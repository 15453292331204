import { getLabel } from "../common/labelsFactory"

export enum BookingStatus {
    WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
    WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
    RESERVED = 'RESERVED',
    DENIED = 'DENIED',
    REFUNDED = 'REFUNDED',
}

export const getStatusOptions = () => {
    return [
        { label: getLabel(BookingStatus.DENIED), value: BookingStatus.DENIED },
        { label: getLabel(BookingStatus.REFUNDED), value: BookingStatus.REFUNDED },
        { label: getLabel(BookingStatus.RESERVED), value: BookingStatus.RESERVED },
        { label: getLabel(BookingStatus.WAITING_FOR_APPROVAL), value: BookingStatus.WAITING_FOR_APPROVAL },
        { label: getLabel(BookingStatus.WAITING_FOR_PAYMENT), value: BookingStatus.WAITING_FOR_PAYMENT }
    ]
}