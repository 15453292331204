import {
    Box,
    Button,
    Center,
    Container,
    FormControl,
    FormLabel,
    Heading,
    Image,
    Input,
    Link,
    Stack,
    Text,
    useToast,
} from '@chakra-ui/react'
import Logo from '../../assets/img/logo.png';
import { useState } from 'react';
import { firebaseAuth } from '../../firebase';
import { showToast } from '../../helpers';
import { useForm } from 'react-hook-form';
import { signInWithEmailAndPassword } from '@firebase/auth';
import { sendEmailVerification } from 'firebase/auth';
import { useNavigate } from "react-router";

type ResendVerificationMailData = {
    email: string,
    password: string
}

export const ResendVerificationMail = () => {
    const toast = useToast();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const {
        handleSubmit,
    } = useForm<ResendVerificationMailData>({
        values: {
            email,
            password
        }
    });

    const send = async (data: ResendVerificationMailData) => {
        return await new Promise<void>(async (resolve) => {
            setIsLoading(true)
            let errorMessage = null
            await signInWithEmailAndPassword(firebaseAuth, data.email, data.password)
                .then(async (userCredential) => {
                    resolve()
                })
                .catch((error) => {
                    errorMessage = error.code;
                });

            if (firebaseAuth.currentUser && firebaseAuth.currentUser.emailVerified === false) {
                sendEmailVerification(firebaseAuth.currentUser);
                firebaseAuth.signOut()
                showToast(toast, {
                    status: 'success',
                    title: 'Success',
                    description: 'Email de verificación reenviado, revise su correo'
                });
                navigate('../login', { replace: false });
            } else if (firebaseAuth.currentUser && firebaseAuth.currentUser.emailVerified === true) {
                firebaseAuth.signOut()
                showToast(toast, {
                    status: 'success',
                    title: 'Success',
                    description: 'La cuenta ya está verificada, inicie sesión'
                });
                navigate('../login', { replace: false });
            } else if (firebaseAuth.currentUser || errorMessage) {
                firebaseAuth.signOut()
                if (errorMessage === 'auth/user-disabled') {
                    errorMessage = 'Su empresa debe aprobar su cuenta';
                } else {
                    errorMessage = 'Usuario o contraseña incorrectos';
                }

                showToast(toast, {
                    status: 'error',
                    title: 'Error',
                    description: errorMessage
                });
            }

            setIsLoading(false)
        });
    }

    return (
        <Container maxW="lg" py={{ base: '12', md: '14' }} px={{ base: '0', sm: '8' }}>
            <Stack spacing="8">
                <Stack spacing="6">
                    <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                        <Center>
                            <Image width={32} src={Logo} />
                        </Center>
                        <Heading size={{ base: 'xs', md: 'sm' }}>Reenviar email de verificación</Heading>
                        <Text color="fg.muted">
                            Volver al <Link href="../auth/login">inicio de sesión</Link>
                        </Text>
                    </Stack>
                </Stack>
                <Box
                    py={{ base: '0', sm: '8' }}
                    px={{ base: '4', sm: '10' }}
                    bg={{ base: 'transparent', sm: 'bg.surface' }}
                    boxShadow={{ base: 'none', sm: 'md' }}
                    borderRadius={{ base: 'none', sm: 'xl' }}
                >
                    <Stack as='form' onSubmit={handleSubmit(send)} spacing="6">
                        <Stack spacing="5">
                            <FormControl>
                                <FormLabel htmlFor="email">Email</FormLabel>
                                <Input id="email" type="email" onChange={(e) => setEmail(e.currentTarget.value)} />
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="email">Password</FormLabel>
                                <Input id="password" type="password" onChange={(e) => setPassword(e.currentTarget.value)} />
                            </FormControl>
                        </Stack>
                        <Stack spacing="6">
                            <Button
                                colorScheme='blue'
                                type='submit'
                                isLoading={isLoading}>
                                Reenviar email</Button>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Container>
    )
}