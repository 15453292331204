import {
    Box,
    Button,
    Card,
    Checkbox,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    InputGroup,
    InputRightAddon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Switch,
    useToast
} from '@chakra-ui/react'
import { DocumentData } from 'firebase/firestore'
import { useAppContext } from '../../../layout/app/AppLayout';
import { useForm } from 'react-hook-form';
import { showToast } from '../../../helpers';
import { firebaseFunctions } from '../../../firebase';
import { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { DtoType } from '../../../types/DtoType';
import { BookingStatus } from '../../../types/BookingStatus';


const checkAvailabilityCommand = httpsCallable(
    firebaseFunctions,
    'checkAvailabilityCallable'
);

const ApproveBookingCommand = httpsCallable(
    firebaseFunctions,
    'approveBookingCallable'
);

type FormData = {
    percentage: string;
    dtoEuros: string;
}

export const ApproveBookingModal = ({
    bookingId, bookingDataSnapshot, isOpen, onClose
}: {
    bookingId: string,
    bookingDataSnapshot: DocumentData,
    isOpen: boolean,
    onClose: () => void
}) => {
    const context = useAppContext();
    const toast = useToast();
    const [dtoEnabled, setDtoEnabled] = useState<boolean>(false);
    const [percentageEnabled, setPercentageEnabled] = useState<boolean>(false);
    const [percentage, setPercentage] = useState<string>('');
    const [dtoMoney, setDtoMoney] = useState<string>('');
    const [isLoading, setIsloading] = useState<boolean>(false)

    const {
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
    } = useForm<FormData>({
        values: {
            percentage: percentage,
            dtoEuros: dtoMoney
        },
        resetOptions: {
            keepDirtyValues: true
        }
    });

    const isValidAmount = (value: string) => {
        return (
            (value != null) &&
            (value !== '') &&
            (value !== undefined) &&
            !isNaN(Number(value.toString())) &&
            Number(value.toString()) >= 0
        );
    }
    const isPercentage = (value: string) => {
        return (
            (value != null) &&
            (value !== '') &&
            (value !== undefined) &&
            !isNaN(Number(value.toString())) &&
            Number(value.toString()) <= 100 &&
            Number(value.toString()) >= 0
        );
    }

    const isDtoVisible = (booking: DocumentData) => {
        return ( booking.status === BookingStatus.WAITING_FOR_APPROVAL)
    }

    const send = (data: FormData) => {
        setIsloading(true)
        return new Promise<void>((resolve) => {
            const success = () => {
                showToast(toast, {
                    title: 'Success',
                    description: 'La reserva ha sido aprobada',
                    status: 'success',
                });
                setIsloading(false)
                onClose();
            }
            const error = (e: any) => {
                console.error(e);
                showToast(toast, {
                    title: 'Error',
                    description: e,
                    status: 'error',
                });
                setIsloading(false)
            }

            if (dtoEnabled && percentageEnabled && !isPercentage(data.percentage)) {
                error('El valor de descuento porcentual no es correcto')
            }
            else if (dtoEnabled && !percentageEnabled && !isValidAmount(data.dtoEuros)) {
                error('El valor de descuento en euros no es correcto')
            } else {

                checkAvailabilityCommand({ start: 1694559610000, end: 1694559610000, facilityId: 'test' })
                    .then((result) => {
                        if (result.data.available) {
                            let dto = null;
                            if (dtoEnabled) {
                                dto = {
                                    type: (percentageEnabled) ? DtoType.PORCENTUAL : DtoType.REGULAR,
                                    value: (percentageEnabled) ? data.percentage : data.dtoEuros
                                }
                            }
                            ApproveBookingCommand({ bookingId, dto })
                                .then((result1) => {
                                    if (result1.data.success) {
                                        success()
                                    } else {
                                        error(result1.data.error)
                                    }
                                }).catch((e) => {
                                    console.error(e);
                                    error('')
                                });

                        } else {
                            error('No hay disponibilidad para las fechas indicadas')
                        }

                    }).catch((e) => {
                        console.error(e);
                        error('')
                    });
            }
            resolve()

        });
    }

    return (
        <Modal
            size='2xl'
            isOpen={isOpen}
            onClose={onClose}
            isCentered={true}>
            <ModalOverlay
                bg='blackAlpha.300'
                backdropFilter='blur(10px) hue-rotate(90deg)'
            />
            <ModalContent as='form' noValidate onSubmit={handleSubmit(send)}>
                <ModalHeader>Aprobar {(bookingDataSnapshot.status === BookingStatus.WAITING_FOR_PAYMENT)?'pago':'reserva'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl isInvalid={errors?.percentage !== undefined}>
                        <Box display={isDtoVisible(bookingDataSnapshot) ? 'flex' : 'none'}>
                            <Switch isChecked={dtoEnabled} id='isChecked' onChange={() => setDtoEnabled(!dtoEnabled)} />
                            <FormLabel pl={4} htmlFor='isChecked'>Aplicar descuento</FormLabel>
                        </Box>
                        <Box display={(isDtoVisible(bookingDataSnapshot) && dtoEnabled ? '' : 'none')}>
                            <Card
                                overflow='hidden'
                                variant='outline'
                                p={5}
                            >
                                <Box display='flex' mb={5}>
                                    <Checkbox
                                        pr={4}
                                        colorScheme='green'
                                        isChecked={!percentageEnabled}
                                        onChange={(e) => setPercentageEnabled(!percentageEnabled)}
                                    />
                                    <InputGroup size='sm'>
                                        <Input
                                            isInvalid={!percentageEnabled && !isValidAmount(dtoMoney)}
                                            isDisabled={percentageEnabled}
                                            size='xm'
                                            type='number'
                                            placeholder='Centidad en euros'
                                            onChange={(e) => setDtoMoney(e.currentTarget.value)}
                                        />
                                        <InputRightAddon children='€' />
                                    </InputGroup>
                                </Box>
                                <Box display='flex'>

                                    <Checkbox
                                        pr={4}
                                        colorScheme='green'
                                        isChecked={percentageEnabled}
                                        onChange={(e) => setPercentageEnabled(!percentageEnabled)}
                                    />
                                    <InputGroup size='sm'>
                                        <Input
                                            isInvalid={percentageEnabled && !isPercentage(percentage)}
                                            isDisabled={!percentageEnabled}
                                            size='xm'
                                            type='number'
                                            placeholder='Descuento porcentual'
                                            onChange={(e) => setPercentage(e.currentTarget.value)}
                                        />
                                        <InputRightAddon children='%' />
                                    </InputGroup>

                                </Box>

                            </Card>
                        </Box>
                        <FormErrorMessage>
                            {errors.percentage &&
                                <>{errors.percentage.message}</>
                            }
                        </FormErrorMessage>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button variant='ghost' mr={3} onClick={onClose} color='black'>
                        Cerrar
                    </Button>
                    <Button
                        type='submit'
                        isDisabled={false}
                        isLoading={isLoading}>
                        Aprobar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}