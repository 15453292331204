import { Box, Card, CardBody, CardFooter, Center, Heading, Image, SimpleGrid } from "@chakra-ui/react";
import { DocumentData } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { UpDownIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router";
import ImageSlider from "../../../components/ImageSlider";
import { StatsCard } from "../../../components/StatsCard";
import { BsPerson } from "react-icons/bs";
import { GoLocation } from "react-icons/go";


export function FacilityInformationCard(props: { doc: DocumentData }) {

    const doc = props.doc;
    const storage = getStorage();
    const navigate = useNavigate();
    const [mainImage, setMainImage] = useState('')
    const [slideImages, setSliceImages] = useState<{ image: string }[]>(doc.info.images.map(() => ({ image: '' })))

    const getImagesUrl = async () => {
        const urls: { image: string; }[] = []
        for (let path of doc.info.images) {
            await getDownloadURL(ref(storage, path)).then((result) => urls.push({ image: result }));
        }
        setSliceImages(urls);
    }

    useEffect(() => {
        getImagesUrl()
    }, [mainImage])

    getDownloadURL(ref(storage, doc.info.mainImage)).then((result) => setMainImage(result));

    const goTo = (id: string) => {
        navigate('../facilities/' + id + '/book', { replace: false });
    }

    return (
        <Card>
            <Image src={mainImage} borderTopRadius={15} />
            <CardBody ml={'6%'} mr={'6%'}>
                <Box maxW="7xl" mx={'auto'} pt={5} pb={5} px={{ base: 2, sm: 12, md: 17 }}>
                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
                        <StatsCard title={'Capacidad'} stat={doc.info.capacity} icon={<BsPerson size={'3em'} />} />
                        <StatsCard title={'Dimensiones'} stat={doc.info.dimensions + 'm2'} icon={<UpDownIcon boxSize={6} />} />
                        <StatsCard title={'Edificio'} stat={doc.info.location.name} icon={<GoLocation size={'3em'} />} />
                    </SimpleGrid>
                </Box>
                <Center>
                    <Heading size={'md'}>{doc.name}</Heading>
                </Center>
                <Box mt={3}>
                    <div
                        dangerouslySetInnerHTML={{ __html: doc.info.fullDescription }}
                    />

                    <Box>
                        <ImageSlider slides={slideImages} />
                    </Box>
                </Box>
            </CardBody>
            <CardFooter>
            </CardFooter>
        </Card>
    )
}
