import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast
} from '@chakra-ui/react'
import { DocumentData, collection, doc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { useAppContext } from '../../../layout/app/AppLayout';
import { Controller, useForm } from 'react-hook-form';
import { showToast } from '../../../helpers';
import { firebaseFirestore, firebaseFunctions } from '../../../firebase';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Select } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';

type FormData = {
    bonusId: string;
}

export const EditBonusModal = ({
    clientId, clientDataSnapshot, isOpen, onClose
}: {
    clientId: string,
    clientDataSnapshot: DocumentData,
    isOpen: boolean,
    onClose: () => void
}) => {

    const changeBonusCompanyCommand = httpsCallable(
        firebaseFunctions,
        'ChangeBonusCompanyCommandCallable'
    );

    const context = useAppContext();
    const [options, setOptions] = useState<{label: string, value: string}[]>([]);
    const toast = useToast();

    const [
        bonusDataSnapshot,
        isLoadingBonus,
        hasBonusLoadingError
    ] = useCollection(collection(firebaseFirestore, 'bonus'));

    const {
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
    } = useForm<FormData>({
        values: {
            bonusId: clientDataSnapshot?.bonusId
        },
        resetOptions: {
            keepDirtyValues: true
        }
    });

    useEffect(() => {
        if (hasBonusLoadingError) {
            console.error(hasBonusLoadingError);
        }
    }, [hasBonusLoadingError]);

    useEffect(() => {
      if (!isLoadingBonus && bonusDataSnapshot?.docs){
        const filteredDocs:{label: string, value: string}[] = [];
        bonusDataSnapshot.docs.forEach(c => {if (c.id !== 'bonogestorinterno'){filteredDocs.push({ label: c.data().description, value: c.id })}})
        setOptions(filteredDocs);
  }
    
    }, [isLoadingBonus, bonusDataSnapshot])
    
    const send = (data: FormData) => {
        return new Promise<void>((resolve) => {
            const success = () => {
                showToast(toast, {
                    title: 'Success',
                    description: 'Bono actualizado',
                    status: 'success',
                });
                onClose();
            }
            const error = (e: any) => {
                console.error(e);
                showToast(toast, {
                    title: 'Error',
                    description: 'No se pudo actualizar el bono.',
                    status: 'error',
                });
            }
            changeBonusCompanyCommand({ cif: clientDataSnapshot.infoCompany.cif, bonusId: data.bonusId })
                .then((result) => {
                    if (result.data.success) {
                        success()
                    } else {
                        error(result.data.error)
                    }
                }).catch((e) => {
                    console.error(e);
                    error('')
                });
        });
    }

    const deleteBonus = () => {
        const success = () => {
            showToast(toast, {
                title: 'Success',
                description: 'Bono actualizado',
                status: 'success',
            });
            onClose();
        }
        const error = (e: any) => {
            console.error(e);
            showToast(toast, {
                title: 'Error',
                description: 'No se pudo actualizar el bono.',
                status: 'error',
            });
        }

        changeBonusCompanyCommand({ cif: clientDataSnapshot.infoCompany.cif, bonusId: null })
            .then((result) => {
                if (result.data.success) {
                    success()
                } else {
                    error(result.data.error)
                }
            }).catch((e) => {
                console.error(e);
                error('')
            });
    }

    return (
        <Modal
            size='2xl'
            isOpen={isOpen}
            onClose={onClose}
            isCentered={true}>
            <ModalOverlay
                bg='blackAlpha.300'
                backdropFilter='blur(10px) hue-rotate(90deg)'
            />
            <ModalContent as='form' noValidate onSubmit={handleSubmit(send)}>
                <ModalHeader>Asignar bono</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl isInvalid={errors?.bonusId !== undefined}>
                        <FormLabel htmlFor='bonus'>Bonos</FormLabel>
                        <Controller
                            control={control}
                            name='bonusId'
                            render={({ field: { onChange, value, ref } }) => (
                                <Select
                                    isLoading={isLoadingBonus}
                                    placeholder='Elige un Bono'
                                    onChange={nv => onChange(nv?.value!!)}
                                    value={{ label: bonusDataSnapshot?.docs.find(c => c.id === value)?.data().description, value }}
                                    options={options}
                                    noOptionsMessage={(_i) => 'No hay bonos. Por favor, crea uno primero.'}
                                    ref={ref}
                                />
                            )} />
                        <FormErrorMessage>
                            {errors.bonusId &&
                                <>{errors.bonusId.message}</>
                            }
                        </FormErrorMessage>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button variant='ghost' mr={3} onClick={deleteBonus} color='black'>
                        Quitar bono del cliente
                    </Button>
                    <Button
                        type='submit'
                        isDisabled={false}
                        isLoading={isSubmitting}>
                        Guardar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}