import {
    Card,
    CardHeader,
    HStack,
    Heading,
    Button,
    CardBody,
    Stack,
    StackDivider,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useDisclosure,
} from '@chakra-ui/react'
import { formatDate } from '../../../helpers'
import { DocumentData } from 'firebase/firestore'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { DataBox } from '../../components/DataBox'
import { BadgeDataBox } from '../../components/BadgeDataBox'
import { ApproveBookingModal } from './ApproveBookingModal'
import { DtoType } from '../../../types/DtoType'
import { BookingStatus } from '../../../types/BookingStatus'
import { DenyBookingModal } from './DenyBookingModal'
import { RefundBookingModal } from './RefundBookingModal'
import { getLabel } from '../../../common/labelsFactory'
import { CancelBookingModal } from './CancelBookingModal'
import { User } from 'firebase/auth'

export const GeneralInformation = ({
    id,
    isAdmin,
    user,
    bookingDataSnapshot,
    bonusDataSnapshot,
    facilitiesDataSnapshot,
    usersDataSnapshot
}: {
    id: string,
    isAdmin: boolean,
    user: User
    bookingDataSnapshot: DocumentData,
    bonusDataSnapshot: DocumentData | undefined,
    facilitiesDataSnapshot: DocumentData | undefined,
    usersDataSnapshot: DocumentData | undefined
}) => {
    const approveBookingModalDisclosure = useDisclosure();
    const denyBookingModalDisclosure = useDisclosure();
    const refundBookingModalDisclosure = useDisclosure();
    const cancelBookingModalDisclosure = useDisclosure();

    const getDiscount = (dto: any) => {
        if (dto) {
            return dto.value + ((dto.type === DtoType.PORCENTUAL) ? '%' : '€');
        }
        return '-'
    }
    const isApproveDisable = () => {
        return (
            !([BookingStatus.WAITING_FOR_APPROVAL, BookingStatus.WAITING_FOR_PAYMENT].includes(bookingDataSnapshot.status))
        )
    }

    const isDenyDisable = () => {
        return ![
            BookingStatus.WAITING_FOR_APPROVAL,
            BookingStatus.WAITING_FOR_PAYMENT
        ].includes(bookingDataSnapshot.status);
    }

    const isCancelDisable = () => {
        return ![
            BookingStatus.WAITING_FOR_APPROVAL,
            BookingStatus.WAITING_FOR_PAYMENT,
            BookingStatus.RESERVED
        ].includes(bookingDataSnapshot.status);
    }

    const isRefundDisable = () => {
        return BookingStatus.RESERVED !== bookingDataSnapshot.status;
    }

    return (
        <>
            <ApproveBookingModal
                bookingDataSnapshot={bookingDataSnapshot}
                bookingId={id}
                isOpen={approveBookingModalDisclosure.isOpen}
                onClose={approveBookingModalDisclosure.onClose} />

            <DenyBookingModal
                bookingId={id}
                isOpen={denyBookingModalDisclosure.isOpen}
                onClose={denyBookingModalDisclosure.onClose} />

            <CancelBookingModal
                bookingId={id}
                isOpen={cancelBookingModalDisclosure.isOpen}
                onClose={cancelBookingModalDisclosure.onClose} />

            <RefundBookingModal
                bookingId={id}
                isOpen={refundBookingModalDisclosure.isOpen}
                onClose={refundBookingModalDisclosure.onClose} />

            <Card>
                <CardHeader>
                    <HStack justify={'space-between'}>
                        <Heading size='md'></Heading>
                        <Menu>
                            <MenuButton as={Button} colorScheme='blue' rightIcon={<ChevronDownIcon />}>
                                Acciones
                            </MenuButton>
                            {(isAdmin &&
                                <MenuList>
                                    {(bookingDataSnapshot.accounting?.linkInvoice &&
                                        <MenuItem
                                            download={bookingDataSnapshot.accounting.linkInvoice}
                                            isDisabled={!bookingDataSnapshot.accounting.linkInvoice}
                                            as='a'
                                            href={bookingDataSnapshot.accounting.linkInvoice}
                                        >
                                            Descargar factura
                                        </MenuItem>
                                    )}
                                    {(bookingDataSnapshot.accounting?.linkCorrectiveInvoice &&
                                        <MenuItem
                                            download={bookingDataSnapshot.accounting.linkCorrectiveInvoice}
                                            isDisabled={!bookingDataSnapshot.accounting.linkCorrectiveInvoice}
                                            as='a'
                                            href={bookingDataSnapshot.accounting.linkCorrectiveInvoice}
                                        >
                                            Descargar factura correctiva
                                        </MenuItem>
                                    )}
                                    <MenuItem
                                        isDisabled={isApproveDisable()}
                                        onClick={approveBookingModalDisclosure.onOpen} >
                                        {(bookingDataSnapshot.status === BookingStatus.WAITING_FOR_PAYMENT) ? 'Aprobar pago' : 'Aprobar reserva'}
                                    </MenuItem>
                                    <MenuItem
                                        isDisabled={isDenyDisable()}
                                        onClick={denyBookingModalDisclosure.onOpen} >
                                        Denegar reserva
                                    </MenuItem>
                                    <MenuItem
                                        isDisabled={isRefundDisable()}
                                        onClick={refundBookingModalDisclosure.onOpen} >
                                        Reembolsar reserva
                                    </MenuItem>
                                </MenuList>
                            )}
                            {(!isAdmin && user.uid == bookingDataSnapshot.userId &&
                                <MenuList>
                                    {(bookingDataSnapshot.accounting?.linkInvoice &&
                                        <MenuItem
                                            download={bookingDataSnapshot.accounting.linkInvoice}
                                            isDisabled={!bookingDataSnapshot.accounting.linkInvoice}
                                            as='a'
                                            href={bookingDataSnapshot.accounting.linkInvoice}
                                        >
                                            Descargar factura
                                        </MenuItem>
                                    )}
                                    {(bookingDataSnapshot.accounting?.linkCorrectiveInvoice &&
                                        <MenuItem
                                            download={bookingDataSnapshot.accounting.linkCorrectiveInvoice}
                                            isDisabled={!bookingDataSnapshot.accounting.linkCorrectiveInvoice}
                                            as='a'
                                            href={bookingDataSnapshot.accounting.linkCorrectiveInvoice}
                                        >
                                            Descargar factura correctiva
                                        </MenuItem>
                                    )}
                                    <MenuItem
                                        isDisabled={isCancelDisable()}
                                        onClick={cancelBookingModalDisclosure.onOpen} >
                                        Cancelar reserva
                                    </MenuItem>
                                </MenuList>
                            )}

                        </Menu>
                    </HStack>
                </CardHeader>
                <CardBody>
                    <Stack
                        direction={{ base: 'column', md: 'row' }}
                        spacing={4} >
                        <Stack divider={<StackDivider />} spacing='2' flex='1'>
                            <DataBox
                                title='Cliente'
                                content={bookingDataSnapshot.userId ? usersDataSnapshot?.docs.find((c: { id: any }) => c.id === bookingDataSnapshot.userId)?.data().displayName : '-'}
                            />
                            <DataBox
                                title='Sala'
                                content={bookingDataSnapshot.facilityId ? facilitiesDataSnapshot?.docs.find((c: { id: any }) => c.id === bookingDataSnapshot.facilityId)?.data().name : '-'}
                            />
                            <DataBox title='Descripción' content={bookingDataSnapshot.info.description} />
                            <DataBox title='Sector' content={bookingDataSnapshot.info.sector} />
                            <DataBox title='Inicio reserva' content={formatDate(bookingDataSnapshot.info.start)} />
                            <DataBox title='Fin reserva' content={formatDate(bookingDataSnapshot.info.end)} />
                            <DataBox title='Creado' content={formatDate(bookingDataSnapshot.dates.created)} />
                            <DataBox title='Modificado' content={formatDate(bookingDataSnapshot.dates.modified)} />
                            {(bookingDataSnapshot.dates.approved_at) && <DataBox title='Aprobada' content={formatDate(bookingDataSnapshot.dates.approved_at)} />}
                            {(bookingDataSnapshot.dates.paid_at) && <DataBox title='Pagada' content={formatDate(bookingDataSnapshot.dates.paid_at)} />}
                            {(bookingDataSnapshot.dates.reserved_at) && <DataBox title='Completada' content={formatDate(bookingDataSnapshot.dates.reserved_at)} />}
                            {(bookingDataSnapshot.dates.denied_at) && <DataBox title={(bookingDataSnapshot.dates.cancelled_at) ? 'Cancelada' : 'Denegada'} content={formatDate(bookingDataSnapshot.dates.denied_at)} />}
                            {(!bookingDataSnapshot.dates.denied_at && bookingDataSnapshot.dates.cancelled_at) && <DataBox title={(bookingDataSnapshot.dates.cancelled_at) ? 'Cancelada' : 'Denegada'} content={formatDate(bookingDataSnapshot.dates.denied_at)} />}
                            {(bookingDataSnapshot.dates.refunded_at) && <DataBox title='Reembolsada' content={formatDate(bookingDataSnapshot.dates.refunded_at)} />}
                        </Stack>
                        <Stack divider={<StackDivider />} spacing='2' flex='1'>
                            <BadgeDataBox
                                title='Estado'
                                content={bookingDataSnapshot.status} />
                            <DataBox title='Tipo Unidad' content={getLabel(bookingDataSnapshot.info.unitType)} />
                            <DataBox title='Total unidades' content={bookingDataSnapshot.info.unitsTotal} />
                            {(bookingDataSnapshot.accounting) && <>
                                <DataBox
                                    title='Bono Aplicado'
                                    {...(bookingDataSnapshot.bonusId ? { link: '../bonus/' + bookingDataSnapshot.bonusId } : {})}
                                    content={bookingDataSnapshot.bonusId ? bonusDataSnapshot?.docs.find((c: { id: any }) => c.id === bookingDataSnapshot.bonusId)?.data().description : '-'}
                                />
                                <DataBox title='Unidades gratis' content={(bookingDataSnapshot.info.unitsTotal - bookingDataSnapshot.accounting.unitsToPay).toString()} />
                                <DataBox title='Precio unidad' content={bookingDataSnapshot.accounting.priceUnit} />
                                <DataBox title='Unidades a pagar' content={bookingDataSnapshot.accounting.unitsToPay} />
                                <DataBox title='Descuento' content={getDiscount(bookingDataSnapshot.accounting.dto)} />
                                <DataBox title='Total Descuento' content={bookingDataSnapshot.accounting.discountAmount} />
                                <DataBox title='Base imponible' content={bookingDataSnapshot.accounting.subTotal} />
                                {(bookingDataSnapshot.accounting.unitsToPay != 0 && <DataBox title='IRPF' content={bookingDataSnapshot.accounting.taxes.irpf} />)}
                                {(bookingDataSnapshot.accounting.unitsToPay != 0 && <DataBox title='IVA' content={bookingDataSnapshot.accounting.taxes.iva} />)}
                                <DataBox title='Precio total' content={bookingDataSnapshot.accounting.total} />
                            </>}
                        </Stack>

                    </Stack>

                </CardBody>
            </Card >
        </>
    )
}