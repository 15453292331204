import { CalendarIcon } from '@chakra-ui/icons';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, Box, HStack, Heading, Stack } from '@chakra-ui/react';
import { Helmet } from 'react-helmet-async';
import { QueryDocumentSnapshot, collection, doc, query } from 'firebase/firestore';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { firebaseFirestore } from '../firebase';
import { SetStateAction, useEffect, useState } from 'react';
import { PublicFacilityItem } from './components/PublicFacilityItem';

export const ClientDashboard = () => {

    const [allLocations, setAllLocations] = useState<{ label: string, value: string }[]>([])
    const [facilitiesDataSnapshot, isLoadingFacilities, errorLoadingFacilities] = useCollection(
        query(
            collection(firebaseFirestore, 'facilities'),
        )
    );
    const [facilitiesMap, setFacilitiesMap] = useState<Map<string, QueryDocumentSnapshot[]>>()
    const [settingsSnapshot, isLoadingSettings, errorLoadingSettings] = useDocumentData(
        doc(firebaseFirestore, 'settings', 'public')
    );

    useEffect(() => {
        if (!isLoadingSettings && settingsSnapshot) {
            const locations: { label: string; value: string; }[] = [];
            settingsSnapshot.locations.forEach((l: { visible: boolean; name: string; externalId: string; }) => {
                if (l.visible) {
                    locations.push({ label: l.name, value: l.externalId })
                }
            });
            setAllLocations(locations);
        }
    }, [isLoadingSettings])

    useEffect(() => {
        if (!isLoadingFacilities) {
            const map = new Map<string, QueryDocumentSnapshot[]>()
            if (facilitiesDataSnapshot) {
                facilitiesDataSnapshot!!.docs.map((doc) => {
                    const location = doc.data().info.location.name;
                    const docs = map.get(location)
                    if (docs) {
                        docs.push(doc);
                        map.set(location, docs);
                    } else {
                        map.set(location, [doc])
                    }
                });
            }
            setFacilitiesMap(map);
        }
    }, [isLoadingFacilities]);

    return (
        <>
            <Helmet>
                <title>PCTT - Dashboard</title>
            </Helmet>
            <Stack spacing={8}>
                <HStack spacing={4}>
                    <CalendarIcon boxSize={8} />
                    <Heading size='md'>
                        Gestor de reservas PCTT
                    </Heading>
                </HStack>
                <Stack spacing={8} >
                    <Box bg='bg-surface' boxShadow='sm' borderRadius='lg' flexGrow={1} >
                        <Accordion defaultIndex={[0]} allowToggle>
                            {facilitiesMap && allLocations && allLocations.map((location) => {
                                return (
                                    <AccordionItem key={location.label}>
                                        <h2>
                                            <AccordionButton _expanded={{ bg: 'gray.400', color: 'white' }}>
                                                <Box as="span" flex='1' textAlign='left'>
                                                    {location.label}
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        {facilitiesMap.get(location.label) && facilitiesMap.get(location.label)!!.map((doc) => {
                                            return (
                                                <PublicFacilityItem doc={doc} key={doc.id} />
                                            )
                                        })}
                                    </AccordionItem>
                                )
                            })}
                        </Accordion>
                    </Box>
                </Stack>
            </Stack>
        </>
    )

}