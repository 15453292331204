import { Navigate, Outlet, useOutletContext } from 'react-router';
import { firebaseAuth } from '../../firebase';
import { useEffect, useState } from 'react';
import { User } from 'firebase/auth';
import {
    Box,
    Container,
    Flex,
    Spinner
} from '@chakra-ui/react';
import { Navbar } from './Navbar';

type AppContext = {
    user: User;
    isAdmin: boolean,
    isManager: boolean,
    path: string
}

export const UserLayout = () => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [user, setUser] = useState<User | null>(null);
    const [isAdmin, setIsAdmin] = useState(false)
    const [isLoadingLayout, setIsLoadingLayout] = useState(true)
    const [isManager, setIsManager] = useState(false)

    useEffect(() => {
        const unsubscribe = firebaseAuth.onAuthStateChanged((authentiCatedUser) => {
            if (authentiCatedUser) {
                authentiCatedUser.getIdTokenResult().then((idTokenResult) => {
                    if (idTokenResult.claims.admin) {
                        setIsAdmin(true);
                    } else if (idTokenResult.claims.manager) {
                        setIsManager(true);
                    } 
                    setIsLoadingLayout(false);
                })
            }
            setUser(authentiCatedUser);
            setIsInitialized(true);
        });
        return unsubscribe;
    }, []);

    if (!isInitialized) {
        return (
            <Flex
                justifyContent='center'
                alignItems='center'
                flexGrow='1'>
                <Spinner color='brand.600' size='lg' />
            </Flex>
        )
    }

    if (!user) {
        return <Navigate to='/auth/login' replace />
    }

    return (
        <>
            {(!isLoadingLayout && !isAdmin &&
                <Box as='section' minH='lg'>
                    <Navbar user={user} isAdmin={isAdmin} isManager={isManager} />
                    <Container maxW={'container.2xl'} py={8}>
                        <Outlet context={[user, isAdmin, isManager, 'user']} />
                    </Container>
                </Box>
            )}
            {(!isLoadingLayout && isAdmin &&
                <Navigate to='/not-found' replace />
            )}
            {(isLoadingLayout &&
                <Flex
                    justifyContent='center'
                    alignItems='center'
                    flexGrow='1'>
                    <Spinner color='brand.600' size='lg' />
                </Flex>
            )}
        </>
    )
}

export function useAppContext() {
    return useOutletContext<AppContext>();
}