import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { AbsoluteCenter, Box, Button, Card, CardBody, Flex, FormControl, FormLabel, HStack, Heading, IconButton, Input, ScaleFade, Stack, Switch, useToast } from '@chakra-ui/react'
import { DocumentData, addDoc, collection, doc, query, serverTimestamp, updateDoc } from 'firebase/firestore';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { firebaseFirestore } from '../../firebase';
import { useEffect, useState } from 'react';
import { showToast } from '../../helpers';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet-async';
import { MultiSelect } from 'chakra-multiselect'
import { RateUnitType, getRateUnitTypes } from '../../types/RateUnitType';
import { getLabel } from '../../common/labelsFactory';

type BonusFormData = {
    description: string;
    unlimited: boolean;
    freePasses?: {
        renewal?: string;
        facilities?: { label: string, value: string }[] | string[];
        units?: number;
        unitType?: string;
    }[]
}

export const BonusForm = () => {

    const { id } = useParams();
    const isNew = id === 'new';
    const toast = useToast();
    const navigate = useNavigate();

    const [allFacilities, setAllfacilities] = useState<{ label: string, value: string }[]>([])
    const [unlimited, setUnlimited] = useState(false);
    const [description, setDescription] = useState<string>('');
    const facilitiesMap = new Map<string, string>();
    const [freePassFields, setFreePassFields] = useState<{ renewal: string, facilities: { label: string, value: string }[], units?: number, unitType: string }[]>([
        { renewal: '', facilities: [], units: undefined, unitType: '' }
    ])

    const [facilitiesDataSnapshot, isLoadingFacilities, errorLoadingFacilities] = useCollection(
        query(
            collection(firebaseFirestore, 'facilities'),
        )
    );

    const [bonusDataSnapshot, _isLoading, errorLoadingBonus] = useDocumentData(
        doc(firebaseFirestore, 'bonus', id!!)
    );

    const addFreePassFields = () => {
        let newfield = { renewal: '', facilities: [], units: undefined, unitType: '' }
        setFreePassFields([...freePassFields, newfield])
    }
    const removeFreePassFields = (index: number) => {
        let data = [...freePassFields];
        data.splice(index, 1)
        setFreePassFields(data)
    }
    const getUnitRates = (unit?: RateUnitType): any => {
        if (unit) {
            return { label: getLabel(unit), value: unit }
        } else {
            return getRateUnitTypes()
        }
    }

    const setFreePassFieldsFromBonus = (bonusDataSnapshot: DocumentData, facilitiesMap: Map<string, string>) => {
        if (bonusDataSnapshot) {
            let fieldsFromBonus: { renewal: string, facilities: { label: string, value: string }[], units: number, unitType: string }[] = [];
            for (let i = 0; i < bonusDataSnapshot.freePasses.length; i++) {
                fieldsFromBonus.push({
                    renewal: bonusDataSnapshot.freePasses[i].renewal,
                    facilities: bonusDataSnapshot.freePasses[i].facilities!!.map((facilityId: string) => ({ label: facilitiesMap.get(facilityId), value: facilityId })),
                    units: bonusDataSnapshot.freePasses[i].units,
                    unitType: bonusDataSnapshot.freePasses[i].unitType
                });

            }
            setFreePassFields(fieldsFromBonus);
        }
    }

    useEffect(() => {
        if (errorLoadingBonus) {
            console.error(errorLoadingBonus);
            showToast(toast, {
                title: 'No se puede actualizar el bono.',
                status: 'error'
            });
            navigate('../bonus', { replace: true });
        }
        if (_isLoading === false) {
            if (bonusDataSnapshot) {
                setDescription(bonusDataSnapshot.description);
                setUnlimited(bonusDataSnapshot.unlimited)
                if (!isLoadingFacilities && facilitiesMap.size > 0) {
                    const temp =
                        bonusDataSnapshot.freePasses[0].facilities.map((el: string) => (
                            { label: facilitiesMap.get(el), value: el }
                        ))
                    setFreePassFieldsFromBonus(bonusDataSnapshot, facilitiesMap);
                }

            } else if (!isNew) {
                showToast(toast, {
                    title: 'No existe el bono',
                    status: 'error',
                });
                navigate('../bonus', { replace: true });
            }
        }
    }, [errorLoadingBonus, _isLoading]);


    useEffect(() => {
        if (!isLoadingFacilities) {
            const facilities: { label: string, value: string }[] = facilitiesDataSnapshot!.docs.map(el =>
                ({ label: el.data().name, value: el.id })
            )
            setAllfacilities(facilities);
            for (const facility of facilities) {
                facilitiesMap.set(facility.value, facility.label);
            }
            if (!_isLoading && bonusDataSnapshot) {
                setFreePassFieldsFromBonus(bonusDataSnapshot, facilitiesMap);
            }
        }
    }, [isLoadingFacilities]);

    const send = (data: BonusFormData) => {

        return new Promise<void>((resolve) => {

            const success = () => {
                showToast(toast, {
                    title: isNew ? 'Bono creado' : 'Bono editado',
                    description: isNew ?
                        'El bono ha sido creado satisfactoriamente.' :
                        'El bono se ha editado satisfactoriamente.',
                    status: 'success',
                });
                navigate('../bonus', { replace: true });
            }
            const error = (message: string) => {
                console.error(message);
                showToast(toast, {
                    title: message,
                    status: 'error',
                });
            }

            let errorMessage = null
            if (!data.description) {
                errorMessage = 'El nombre de la descripción es obligatorio';
            } else if (!unlimited) {
                if (!data.freePasses || data.freePasses.length === 0) {
                    errorMessage = 'Necesitas añadir al menos un Pase gratuito'
                } else {
                    const facilitiesSelected: string[] = []
                    for (let i = 0; i < data.freePasses.length; i++) {
                        if (
                            !data.freePasses[i].renewal ||
                            !data.freePasses[i].facilities || data.freePasses[i].facilities!!.length < 1 ||
                            !data.freePasses[i].unitType ||
                            !data.freePasses[i].units
                        ) {
                            errorMessage = 'Los campos de los pases gratuitos son todos obligatorios'
                            break;
                        }
                        data.freePasses[i].facilities = data.freePasses[i].facilities!!.map(e => e.value)
                        data.freePasses[i].units = Number(data.freePasses[i].units)
                        for (let facilityId of data.freePasses[i].facilities!!) {
                            if (facilitiesSelected.includes(facilityId as string)) {
                                errorMessage = 'Las salas no se pueden seleccionar en distintos pases gratuitos';
                                break;
                            } else {
                                facilitiesSelected.push(facilityId as string);
                            }
                        }
                    }
                }
            } else {
                data.freePasses = []
            }

            if (errorMessage) {
                error(errorMessage)
                resolve()
            } else {
                if (isNew) {
                    addDoc(
                        collection(firebaseFirestore, 'bonus'), {
                        ...data,
                        created: serverTimestamp(),
                        updated: serverTimestamp()
                    })
                        .then(success)
                        .catch(() => error('Error creando el bono'))
                        .finally(resolve);
                } else {
                    updateDoc(
                        doc(firebaseFirestore, 'bonus', id!!), {
                        ...data,
                        updated: serverTimestamp()
                    })
                        .then(success)
                        .catch(() => error('Error editando el bono'))
                        .finally(resolve);
                }
            }
        });
    }

    const {
        handleSubmit,
        register,
        setValue,
        control,
        watch,
        formState: { errors, isSubmitting },
    } = useForm<BonusFormData>({
        values: {
            description: description,
            unlimited: unlimited,
            freePasses: freePassFields.map(e => ({
                renewal: e.renewal,
                facilities: e.facilities,
                units: e.units,
                unitType: e.unitType
            }))
        },
        resetOptions: {
            keepDirtyValues: true
        }
    });

    const handleFormChange = (index: number, event?: any, name?: string, value?: any) => {
        let data = [...freePassFields];
        data[index][(event) ? event.target.name : name] = (event) ? event.target.value : value;
        setFreePassFields(data);
    }

    return (
        <>
            <Helmet>
                <title>PCTT - Bonus</title>
            </Helmet>
            <Stack spacing={8}>
                <HStack spacing={4}>
                    <EditIcon boxSize={8} />
                    <Heading size='md'>
                        {(isNew) ? 'Añadir nuevo bono' : 'Editar bono'}
                    </Heading>
                </HStack>
                <Stack as='form' spacing={8} onSubmit={handleSubmit(send)} noValidate>
                    <Box bg='bg-surface' boxShadow='sm' borderRadius='lg' flexGrow={1} pb={4}>
                        <Stack spacing='5' px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                            <FormControl>
                                <FormLabel htmlFor='description'>Descripción</FormLabel>
                                <Input id='description' value={description} type='text' onChange={(e) => setDescription(e.currentTarget.value)} />
                            </FormControl>
                            <FormControl>
                                <Box display={'flex'}>
                                    <Switch isChecked={unlimited} id='isChecked' onChange={() => setUnlimited(!unlimited)} />
                                    <FormLabel pl={4} htmlFor='isChecked'>Horas ilimitadas en todas las salas</FormLabel>
                                </Box>
                            </FormControl>
                            <Box display={unlimited ? 'none' : ''}>
                                {freePassFields.map((input, index) => {
                                    return (
                                        <ScaleFade initialScale={0.9} in={!unlimited} key={index}>
                                            <Card mt={2}>
                                                <CardBody backgroundColor={'gray.100'}>
                                                    <Box position={'absolute'} right={2} top={2}>
                                                        <IconButton
                                                            colorScheme='red'
                                                            aria-label='Search database'
                                                            icon={<DeleteIcon />}
                                                            size={'xs'}
                                                            onClick={() => removeFreePassFields(index)}
                                                        />
                                                    </Box>
                                                    <FormControl pt={3}>
                                                        <MultiSelect
                                                            options={[{ label: 'Mensual', value: 'MONTHLY' }, { label: 'Anual', value: 'YEARLY' }]}
                                                            name='renewal'
                                                            value={input.renewal}
                                                            label='Elige el tipo de renovación'
                                                            onChange={(event: string | undefined) => handleFormChange(index, null, 'renewal', event)}
                                                            single
                                                        />
                                                    </FormControl>
                                                    <FormControl pt={3}>
                                                        <MultiSelect
                                                            options={allFacilities}
                                                            value={input.facilities}
                                                            name={'facilities'}
                                                            label='Selecciona las salas'
                                                            onChange={(event: string | undefined) => handleFormChange(index, null, 'facilities', event)}
                                                        />
                                                    </FormControl>
                                                    <Box display={'flex'}>
                                                        <FormControl pt={3}>
                                                            <FormLabel htmlFor='units'>Cantidad de tiempo</FormLabel>
                                                            <Input
                                                                name='units'
                                                                value={input.units}
                                                                type='number'
                                                                onChange={event => handleFormChange(index, null, event.target.name, Number(event.target.value))}
                                                            />
                                                        </FormControl>
                                                        <FormControl pt={3}>
                                                            <MultiSelect
                                                                options={getUnitRates()}
                                                                name='unitType'
                                                                value={input.unitType}
                                                                label='Unidad de tiempo'
                                                                onChange={(event: string | undefined) => handleFormChange(index, null, 'unitType', event)}
                                                                single
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                </CardBody>
                                            </Card>
                                        </ScaleFade>
                                    )
                                })}
                                <Box position='relative' h='100px'>
                                    <AbsoluteCenter p='4' >
                                        <IconButton
                                            isRound={true}
                                            variant='solid'
                                            colorScheme='teal'
                                            aria-label='Done'
                                            fontSize='20px'
                                            icon={<AddIcon />}
                                            onClick={addFreePassFields}
                                        />
                                    </AbsoluteCenter>
                                </Box>
                            </Box>

                        </Stack>
                    </Box>
                    <HStack justify={'flex-end'}>
                        <Button as={Link} to={'../bonus'} replace variant='ghost' size='lg'>Cancelar</Button>
                        <Button
                            variant='primary'
                            isLoading={isSubmitting}
                            isDisabled={false}
                            type='submit'
                            size='lg'>
                            Guardar
                        </Button>
                    </HStack>
                </Stack>
            </Stack>
        </>
    )

}