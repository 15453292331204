// Modules object for setting up the Quill editor
export const modules = {
  toolbar: [
    [ { 'size': [] }],
    [ 'bold', 'italic', 'underline', 'strike' ,{ 'color': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'align': [] }],
    [ 'clean' ]
],
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};

export const formats = [
  "header",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block"
];
