import {
    Button,
    FormControl,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast
} from '@chakra-ui/react'
import { useAppContext } from '../../../layout/app/AppLayout';
import { useForm } from 'react-hook-form';
import { showToast } from '../../../helpers';
import { firebaseFunctions } from '../../../firebase';
import { httpsCallable } from 'firebase/functions';
import { useState } from 'react';



const DenyBookingCommand = httpsCallable(
    firebaseFunctions,
    'denyBookingCallable'
);

type FormData = {
}

export const DenyBookingModal = ({
    bookingId, isOpen, onClose
}: {
    bookingId: string,
    isOpen: boolean,
    onClose: () => void
}) => {
    const context = useAppContext();
    const toast = useToast();
    const [isLoading, setIsloading] = useState<boolean>(false)

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<FormData>({
        resetOptions: {
            keepDirtyValues: true
        }
    });


    const send = (data: FormData) => {
        setIsloading(true);
        return new Promise<void>((resolve) => {
            const success = () => {
                showToast(toast, {
                    title: 'Success',
                    description: 'La reserva ha sido denegada',
                    status: 'success',
                });
                setIsloading(false)
                onClose();
            }
            const error = (e: any) => {
                showToast(toast, {
                    title: 'Error',
                    description: e,
                    status: 'error',
                });
                setIsloading(false)
            }

            DenyBookingCommand({ bookingId })
                .then((result) => {
                    if (result.data.success) {
                        success()
                    } else {
                        error(result.data.error)
                    }
                }).catch((e) => {
                    console.error(e);
                    error('')
                });

            resolve()

        });
    }
    return (
        <Modal
            size='2xl'
            isOpen={isOpen}
            onClose={onClose}
            isCentered={true}>
            <ModalOverlay
                bg='blackAlpha.300'
                backdropFilter='blur(10px) hue-rotate(90deg)'
            />
            <ModalContent as='form' noValidate onSubmit={handleSubmit(send)}>
                <ModalHeader>Denegar reserva</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button variant='ghost' mr={3} onClick={onClose} color='black'>
                        Cerrar
                    </Button>
                    <Button
                        type='submit'
                        isDisabled={false}
                        isLoading={isLoading}>
                        Denegar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}