'use client'

import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
    Button,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Flex,
    List,
    ListItem,
    ListIcon,
} from '@chakra-ui/react'
import { CalendarIcon, ChevronDownIcon, InfoIcon, RepeatClockIcon, TimeIcon } from '@chakra-ui/icons'

export function BookingButtonInformationComponent(props: { size: string , color: string}) {
    return (
        <Popover placement="bottom" isLazy>
            <PopoverTrigger>
                <Button pl={2} rightIcon={<InfoIcon />} colorScheme={props.color} size={props.size} />
            </PopoverTrigger>
            <PopoverContent color='white' bg='blue.800' borderColor='blue.800' _focus={{ boxShadown: 'none' }} >
                <PopoverArrow />
                <PopoverCloseButton color={'green'} _hover={{backgroundColor:'blue.900'}}/>
                <PopoverHeader fontWeight="bold">Información</PopoverHeader>
                <PopoverBody w="full">
                    <Tabs isLazy colorScheme="green">
                        <TabList>
                            <Tab
                                _focus={{ boxShadow: 'none' }}
                                fontSize="xs"
                                fontWeight="bold"
                                w="50%">
                                Restricciones
                            </Tab>
                            <Tab
                                _focus={{ boxShadow: 'none' }}
                                fontSize="xs"
                                fontWeight="bold"
                                w="50%">
                                Bonos
                            </Tab>
                            <Tab
                                _focus={{ boxShadow: 'none' }}
                                fontSize="xs"
                                fontWeight="bold"
                                w="50%">
                                Otros
                            </Tab>
                        </TabList>
                        <TabPanels fontSize="xs">
                            <TabPanel>
                                <List spacing={3}>
                                    <ListItem>
                                        <ListIcon as={CalendarIcon} color='green.500' />
                                        El Horario laboral es de 8 a 14 y de 16 a 21, de Lunes a Viernes.
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={TimeIcon} color='green.500' />
                                        Para fracciones de tiempo como semana, mes o día laboral se emplean periodos de días completos
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={TimeIcon} color='green.500' />
                                        La media jornada laboral se reserva en franja completa: mañana o tarde.
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={TimeIcon} color='green.500' />
                                        El máximo de jornadas laborales seguidas reservables es de 14.
                                    </ListItem>
                                </List>
                            </TabPanel>
                            <TabPanel>
                                <List spacing={3}>
                                    <ListItem>
                                        <ListIcon as={TimeIcon} color='green.500' />
                                        Si dispones de bonos, la cantidad de tiempo del bono será descontada del total y estará reflejada en el correo de información una vez sea aprobada la reserva.
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={TimeIcon} color='green.500' />
                                        Los bonos mensuales sólo son válidos para reservas del mes vigente, y los anuales en reservas dentro de los tres meses próximos.
                                    </ListItem>
                                </List>
                            </TabPanel>
                            <TabPanel>
                                Si su reserva no cumple con nuestras restricciones y aún así quiere realizarla. Póngase en contacto con nosotros.
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}
