import { ArrowBackIcon } from '@chakra-ui/icons'
import { HStack, Heading, Stack, useToast, IconButton, Flex, Spinner } from '@chakra-ui/react'
import { collection, doc, limit, orderBy, query, where } from 'firebase/firestore';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { firebaseFirestore } from '../../firebase';
import { useEffect } from 'react';
import { showToast } from '../../helpers';
import { Helmet } from 'react-helmet-async';
import { GeneralInformation } from './details/GeneralInformation';
import { User } from 'firebase/auth';

type ClientFormData = {
    name: string;
    regCodeGeneratorDictionary?: {
        name: string;
        value: string;
    }
}

export const BookingDetails = () => {
    const [user, isAdmin, isManager, path]: [User, boolean, boolean, string] = useOutletContext();
    const { id } = useParams();
    const toast = useToast();
    const navigate = useNavigate();

    const [
        bookingDataSnapshot,
        isLoadingBookingData,
        hasBookingLoadingError
    ] = useDocumentData(doc(firebaseFirestore, `bookings/${id}`));

    const [
        bonusDataSnapshot,
        _isLoadingBonusData,
        hasBonusLoadingError
    ] = useCollection(query(collection(firebaseFirestore, 'bonus')));

    const [
        facilitiesDataSnapshot,
        _isLoadingFacilitiesData,
        hasFacilitiesLoadingError
    ] = useCollection(query(collection(firebaseFirestore, 'facilities')));

    const [
        usersDataSnapshot,
        _isLoadingUsersData,
        hasUsersLoadingError
    ] = useCollection(query(collection(firebaseFirestore, 'users')));

    useEffect(() => {
        if (hasBookingLoadingError) {
            console.error(hasBookingLoadingError);
            showToast(toast, {
                status: 'error',
                title: 'Error',
                description: 'No se puedo cargar los datos de la reserva. Inténtelo de nuevo.'
            });
            navigate('../bookings', { replace: true });
        }
        if (hasBonusLoadingError) {
            console.error(hasBonusLoadingError);
        }
        if (hasFacilitiesLoadingError) {
            console.error(hasBonusLoadingError);
        }
        if (hasUsersLoadingError) {
            console.error(hasBonusLoadingError);
        }

    }, [
        hasBookingLoadingError,
        hasBonusLoadingError,
        hasFacilitiesLoadingError,
        hasUsersLoadingError
    ]);

    useEffect(() => {
        if (!isLoadingBookingData && !bookingDataSnapshot) {
            showToast(toast, {
                status: 'error',
                title: 'Error',
                description: 'La reserva no existe.'
            });
            navigate('../bookings', { replace: true });
        }

    }, [isLoadingBookingData]);


    return (
        <>
            <Helmet>
                <title>Reservas: Detalles de la reserva</title>
            </Helmet>
            <Stack spacing={8}>
                <HStack spacing={4}>
                    <IconButton
                        as={Link}
                        to='../bookings'
                        icon={<ArrowBackIcon boxSize={8} />}
                        aria-label={'Back to list'}
                        variant={'ghost'} />
                    <Heading size='md'>
                        {'Reservas: Detalles de la reserva'}
                    </Heading>
                </HStack>
                {isLoadingBookingData &&
                    <Flex justifyContent={'center'} py={24}>
                        <Spinner size='xl' />
                    </Flex>
                }
                {bookingDataSnapshot &&
                    <Stack spacing={12}>
                        <GeneralInformation
                            id={id ?? ''}
                            isAdmin={isAdmin}
                            user={user}
                            bookingDataSnapshot={bookingDataSnapshot}
                            bonusDataSnapshot={bonusDataSnapshot}
                            facilitiesDataSnapshot={facilitiesDataSnapshot}
                            usersDataSnapshot={usersDataSnapshot} />
                    </Stack>
                }
            </Stack>
        </>
    )
}