import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Stack,
  useDisclosure,
  Image,
  Center
} from '@chakra-ui/react'
import { ToggleButton } from './ToggleButton'
import { AppRoutes } from './AppRoutes'
import { Link } from 'react-router-dom'
import Logo from '../../assets/img/pctt-logo.png';
export const MobileDrawer = ({ isAdmin, isManager }: { isAdmin: boolean, isManager: boolean }) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  return (
    <>
      <ToggleButton
        isOpen={isOpen}
        onClick={onToggle}
        aria-label='Open menu'
        display={{ base: 'inline-flex', lg: 'none' }}
      />
      <Drawer placement='top' isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody p='4'>
            <Center p={8}>
              <Image width={{ base: 28 }} src={Logo} />
            </Center>
            <Stack spacing='1'>
              {AppRoutes.map(r => {
                if (r.type === 'button') {
                  if (
                    (r.AdminAllow && isAdmin) ||
                    (r.ManagerAllow && isManager) ||
                    (r.UserAllow && !isAdmin && !isManager)
                  ) {
                    return (
                      <Button
                        size='lg'
                        onClick={onClose}
                        variant='tertiary'
                        justifyContent='start'
                        key={r.name}
                        as={Link}
                        to={r.to}>
                        {r.name}
                      </Button>
                    )
                  }
                  return
                }
                else return //TODO quitar si son todos button <NavCollapse key={r.name} name={r.name} routes={r.elements as NavElement[]} onClick={onClose} />
              })}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}