import { Navigate, Outlet } from 'react-router'
import { firebaseAuth } from '../../firebase'
import { useEffect, useState } from 'react'

export const AuthLayout = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false)
    const [isLoadingLayout, setIsLoadingLayout] = useState(true)
    const [isManager, setIsManager] = useState(false)

    useEffect(() => {
        const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
            setIsLoggedIn(user !== null);
            if (user) {
                user.getIdTokenResult().then((idTokenResult) => {
                    if (idTokenResult.claims.admin) {
                        setIsAdmin(true);
                    } else if (idTokenResult.claims.manager) {
                        setIsManager(true);
                    }
                    setIsLoadingLayout(false);
                })
            }
        });
        return unsubscribe;
    }, []);

    if (isLoggedIn) {
        if (isAdmin && !isLoadingLayout) {
            return <Navigate to='/app/dashboard' replace />
        } else if (isManager && !isLoadingLayout) {
            return <Navigate to='/manager/dashboard' replace />
        } else {
            return <Navigate to='/user/dashboard' replace />
        }
    }

    return (
        <Outlet />
    )
}