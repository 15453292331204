import { getLabel } from "../common/labelsFactory"

export enum FacilityType {
    MEETING_ROOM = 'MEETING_ROOM',
    TRAINING_ROOM = 'TRAINING_ROOM',
    LABORATORY = 'LABORATORY',
    MULTIPURPOSE_ROOM = 'MULTIPURPOSE_ROOM',
    WORKSPACE = 'WORKSPACE',
    OTHER = 'OTHER'
}

export const getFacilityTypes = () => {
    return [
        { label: getLabel(FacilityType.MEETING_ROOM), value: FacilityType.MEETING_ROOM },
        { label: getLabel(FacilityType.TRAINING_ROOM), value: FacilityType.TRAINING_ROOM },
        { label: getLabel(FacilityType.LABORATORY), value: FacilityType.LABORATORY },
        { label: getLabel(FacilityType.MULTIPURPOSE_ROOM), value: FacilityType.MULTIPURPOSE_ROOM },
        { label: getLabel(FacilityType.WORKSPACE), value: FacilityType.WORKSPACE },
        { label: getLabel(FacilityType.OTHER), value: FacilityType.OTHER },
    ]
}
