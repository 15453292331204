import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export const NotFound = () => {
    return (
        <Box textAlign='center' py={{ base: 10, md: 24 }} px={6}>
            <Heading
                display='inline-block'
                color={'violet.900'}
                size='2xl'
            >
                404
            </Heading>
            <Heading as='h2' size='2xl' >
                🙉
            </Heading>
            <Text fontSize='xl' mt={3} mb={2}>
                Página no encontrada
            </Text>
            <Text color={'gray.500'} mb={6}>
                La pagina a la que estás intentando acceder no existe
            </Text>

            <Button
                as={Link}
                to='/'
                size='lg'
                replace
                colorScheme='brand'
                variant='outline'>
                Volver al inicio
            </Button>
        </Box>
    );
}