import { getLabel } from "../common/labelsFactory"

export enum RateUnitType {
    HOUR = 'HOUR',
    HALFWORKINGDAY = 'HALFWORKINGDAY',
    WORKINGDAY = 'WORKINGDAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH'
}

export const getRateUnitTypes = () => {
    return [
        { label: getLabel(RateUnitType.HOUR), value: RateUnitType.HOUR },
        { label: getLabel(RateUnitType.HALFWORKINGDAY), value: RateUnitType.HALFWORKINGDAY },
        { label: getLabel(RateUnitType.WORKINGDAY), value: RateUnitType.WORKINGDAY },
        { label: getLabel(RateUnitType.WEEK), value: RateUnitType.WEEK },
        { label: getLabel(RateUnitType.MONTH), value: RateUnitType.MONTH }
    ]
}
  