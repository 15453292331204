import {
    Box,
    Button,
    HStack,
    IconButton,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast,
} from '@chakra-ui/react';
import { FiEdit } from 'react-icons/fi';
import { AddIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { QueryDocumentSnapshot, collection, query } from 'firebase/firestore';
import { firebaseFirestore } from '../../firebase';
import { showToast } from '../../helpers';
import { TableLoadingSpinner } from '../../components/TableLoadingSpinner';
import { TableNoResultsMessage } from '../../components/TableNoResultsMessage';
import { Helmet } from 'react-helmet-async';

export const BonusList = () => {

    const toast = useToast();
    const [docs, setDocs] = useState<QueryDocumentSnapshot[]>([]);

    const [bonusQuerySnapshot, isLoading, bonusLoadingError] = useCollection(
        query(
            collection(firebaseFirestore, 'bonus'),
        )
    );

    useEffect(() => {
        if (bonusLoadingError) {
            showToast(toast, {
                title: 'Could not fetch data.',
                status: 'error'
            });
        }
    }, [bonusLoadingError])

    useEffect(() => {
        if (bonusQuerySnapshot?.docs) {
            const filteredDocs: QueryDocumentSnapshot[] = [];
            bonusQuerySnapshot.docs.forEach(doc => {if (doc.id !== 'bonogestorinterno'){filteredDocs.push(doc)}})
            setDocs(filteredDocs);
        }
    }, [bonusQuerySnapshot]);

    return (
        <>
            <Helmet>
                <title>PCTT - Listado de bonus</title>
            </Helmet>
            <Stack>
                <Box
                    bg='bg.surface'
                    boxShadow={{ base: 'none', md: 'sm' }}
                    borderRadius={{ base: 'none', md: 'lg' }}
                >
                    <Stack spacing='5'>
                        <Box px={{ base: '4', md: '6' }} pt='5'>
                            <Stack direction={{ base: 'column', md: 'row' }} justify='space-between'>
                                <Text fontSize={'3xl'} fontWeight='medium'>
                                    Bonos
                                </Text>
                                <Button
                                    width='auto'
                                    as={Link}
                                    colorScheme='blue'
                                    to='../bonus/new'
                                    leftIcon={<AddIcon boxSize={3.5} />}>
                                    Añadir bono
                                </Button>
                            </Stack>
                        </Box>
                        <Box overflowX='auto' overflowY='hidden'>
                            <Table>
                                {(isLoading) &&
                                    <TableLoadingSpinner />
                                }
                                {(!isLoading && docs?.length === 0) &&
                                    <TableNoResultsMessage />
                                }
                                <Thead>
                                    <Tr>
                                        <Th w={20}></Th>
                                        <Th>Descripción</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {docs.map(bonus => (
                                        <Tr key={bonus.id}>
                                            <Td>
                                                <IconButton
                                                    icon={<FiEdit />}
                                                    as={Link}
                                                    to={`../bonus/${bonus.id}`}
                                                    variant='tertiary'
                                                    aria-label='Edit booking' />
                                            </Td>
                                            <Td><Text color='fg.muted'>{bonus.data().description}</Text></Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Box>
                        <Box px={{ base: '4', md: '6' }} pb='5'>
                            <HStack spacing='3' justify='space-between'>
                            </HStack>
                        </Box>
                    </Stack>
                </Box>
            </Stack>
        </>
    )

}