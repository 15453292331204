import { AddIcon, CheckIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { AbsoluteCenter, Box, Button, Card, CardBody, Flex, FormControl, FormLabel, HStack, Heading, Icon, IconButton, Input, ScaleFade, Stack, Switch, useToast } from '@chakra-ui/react'
import { collection, doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { Link, useNavigate } from 'react-router-dom';
import { firebaseFirestore, firebaseFunctions } from '../../firebase';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { showToast } from '../../helpers';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet-async';
import ChakraTagInput from './components/ChakraTagInput';
import { Select } from 'chakra-react-select';
import { httpsCallable } from 'firebase/functions';

type SettingsFormData = {
    recipientsNotification: string[];
    sectors: string[];
    locations: {
        externalId?: string,
        name?: string,
        visible?: boolean
    }[]
    schedule: {
        endHour?: number,
        startHour?: number,
        endBreak?: number,
        startBreak?: number
    }
    smtp: {
        email?: string,
        password?: string,
        host?: string,
        port?: number,
    },
    erp: {
        host?: string,
        token?: string
    },
    bonusId?: string
}

export const SettingsForm = () => {

    const toast = useToast();
    const navigate = useNavigate();
    const [startHour, setStartHour] = useState<number>();
    const [endHour, setEndHour] = useState<number>();
    const [startBreak, setStartBreak] = useState<number>();
    const [endBreak, setEndBreak] = useState<number>();
    const [host, setHost] = useState<string>();
    const [port, setPort] = useState<number>();
    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [recipientsNotification, setRecipientsNotification] = useState<string[]>([])
    const [sectors, setSectors] = useState<string[]>([])
    const [locations, setLocations] = useState<{ externalId: number | null, name: string, visible: boolean }[]>([])
    const [erpHost, setErpHost] = useState<string>();
    const [erpToken, setErpToken] = useState<string>()
    const [bonusSelected, setBonusSelected] = useState<string>()

    const checkErpStatusCommand = httpsCallable(
        firebaseFunctions,
        'checkErpStatusCommandCallable'
    );

    const checkErpStatus = () => {
        return new Promise<void>(async (resolve) => {
            const success = () => {
                showToast(toast, {
                    title: 'Success',
                    description: 'El sistema ERP está operativo',
                    status: 'success',
                });
                resolve()
            }
            const error = () => {
                showToast(toast, {
                    title: 'Error',
                    description: 'El sistema ERP no está operativo o credenciales incorrectas',
                    status: 'error',
                });
                resolve()
            }

            await checkErpStatusCommand().then((result) => {
                    if (!result.data) {
                        console.log(result)
                        error()
                    } else {
                        success()
                    }
                }).catch((e) => {
                    error()
                });
        });
    }

    const isEmail = (email: string) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

    const [settingsDataSnapshot, isLoadingSettings, errorLoadingSettings] = useDocumentData(
        doc(firebaseFirestore, 'settings', 'main')
    );
    const [publicSettingsDataSnapshot, isLoadingPublicSettings, errorLoadingPublicSettings] = useDocumentData(
        doc(firebaseFirestore, 'settings', 'public')
    );

    const [
        bonusDataSnapshot,
        isLoadingBonus,
        hasBonusLoadingError
    ] = useCollection(collection(firebaseFirestore, 'bonus'));

    useEffect(() => {
        if (errorLoadingSettings || errorLoadingPublicSettings) {
            showToast(toast, {
                title: 'Ha ocurrido un error al cargar los datos',
                status: 'error',
            });
            navigate('../dashboard');
        }
        if (!isLoadingSettings && settingsDataSnapshot) {
            setRecipientsNotification(settingsDataSnapshot.recipientsNotification)
            setEmail(settingsDataSnapshot.smtp.email)
            setHost(settingsDataSnapshot.smtp.host)
            setPassword(settingsDataSnapshot.smtp.password)
            setPort(settingsDataSnapshot.smtp.port)
            setErpHost(settingsDataSnapshot.erp.host)
            setErpToken(settingsDataSnapshot.erp.token)
            setBonusSelected(settingsDataSnapshot.bonusId)
        }

        if (!isLoadingPublicSettings && publicSettingsDataSnapshot) {
            setStartHour(publicSettingsDataSnapshot.schedule.startHour)
            setEndHour(publicSettingsDataSnapshot.schedule.endHour)
            setStartBreak(publicSettingsDataSnapshot.schedule.startBreak)
            setEndBreak(publicSettingsDataSnapshot.schedule.endBreak)
            setLocations(publicSettingsDataSnapshot.locations);
            setSectors(publicSettingsDataSnapshot.sectors)
        }

    }, [isLoadingSettings, errorLoadingSettings, isLoadingPublicSettings, errorLoadingPublicSettings])


    const addLocationFields = () => {
        let newfield = { externalId: null, name: '', visible: true }
        setLocations([...locations, newfield])
    }
    const removeLocationFields = (index: number) => {
        let data = [...locations];
        data.splice(index, 1)
        setLocations(data)
    }

    const handleFormChange = (index: number, name: string, value?: any) => {
        let data = [...locations];
        data[index][name] = value;
        setLocations(data);
    }

    const handleTagsChange = useCallback((event: SyntheticEvent, tags: string[]) => {
        if (tags.length > 0 && !isEmail(tags[tags.length - 1])) {
            showToast(toast, {
                title: '"' + tags[tags.length - 1] + '" no es una email válido',
                status: 'error',
            });
        } else {
            setRecipientsNotification(tags)
        }
    }, [])

    const handleSectors = useCallback((event: SyntheticEvent, tags: string[]) => {
        setSectors(tags)
    }, [])

    const send = (data: SettingsFormData) => {

        return new Promise<void>(async (resolve) => {

            const success = () => {
                showToast(toast, {
                    title: 'Guardado correctamente',
                    status: 'success',
                });
                resolve()
            }
            const error = (message: string) => {
                showToast(toast, {
                    title: message,
                    status: 'error',
                });
                resolve()
            }

            let errorMessage = null;
            if (!startHour || !endHour) {
                errorMessage = 'Los campos del horario laboral son todos obligatorios'
            } else if (
                !(startHour < endHour)
            ) {
                errorMessage = 'La hora de fin no puede ser inferior a la de inicio'
            } else if (
                !email || !password || !host || !port
            ) {
                errorMessage = 'Todos los campos del SMTP son obligatorios'
            } else if (
                !erpHost || !erpToken
            ) {
                errorMessage = 'Todos los campos del ERP son obligatorios'
            } else if (recipientsNotification.length < 0) {
                errorMessage = 'Tiene que exisitir al menos un destinatario para las notificaciones'
            } else {
                locations.forEach(location => {
                    if (!location.externalId || !location.externalId) {
                        errorMessage = 'Todos los campos de los edificios son obligatorios'
                    }
                });
            }
            if (errorMessage) {
                error(errorMessage);
            } else {
                await updateDoc(
                    doc(firebaseFirestore, 'settings', 'main'), {
                    bonusId: data.bonusId,
                    erp: data.erp,
                    recipientsNotification: data.recipientsNotification,
                    smtp: data.smtp,
                    updated: serverTimestamp()
                })

                updateDoc(
                    doc(firebaseFirestore, 'settings', 'public'), {
                    locations: data.locations,
                    sectors: data.sectors,
                    schedule: data.schedule,
                    updated: serverTimestamp()
                })
                    .then(success)
                    .catch(() => error('Error guardando los ajustes'))
                    .finally(resolve);

            }

        });
    }
    const {
        handleSubmit,
        register,
        setValue,
        control,
        watch,
        formState: { errors, isSubmitting },
    } = useForm<SettingsFormData>({
        values: {
            schedule: {
                startHour,
                endHour,
                startBreak,
                endBreak
            },
            locations: locations.map((l) => ({ externalId: l.externalId, name: l.name, visible: l.visible })),
            recipientsNotification,
            sectors,
            smtp: {
                email,
                password,
                host,
                port
            },
            erp: {
                host: erpHost,
                token: erpToken
            },
            bonusId: bonusSelected
        },
        resetOptions: {
            keepDirtyValues: true
        },
    });

    return (
        <>
            <Helmet>
                <title>PCTT - Bonus</title>
            </Helmet>
            <Stack spacing={8}>
                <HStack spacing={4}>
                    <EditIcon boxSize={8} />
                    <Heading size='md'>
                        Ajustes del sistema
                    </Heading>
                </HStack>
                <Stack as='form' spacing={8} onSubmit={handleSubmit(send)} noValidate>
                    <Box bg='bg-surface' boxShadow='sm' borderRadius='lg' flexGrow={1} pb={2}>
                        <Heading mt={5} ml={5} mb={0} size={'sm'}>Horario laboral</Heading>
                        <Stack direction={{ base: 'column', lg: 'row' }} alignSelf={{ base: 'flex-start', lg: 'flex-end' }} width={{ base: '100%' }} p={5} display='flex'>
                            <FormControl>
                                <FormLabel htmlFor='shchedule'>Inicio Horario laboral</FormLabel>
                                <Input
                                    placeholder="0"
                                    size="md"
                                    type="number"
                                    value={startHour}
                                    onChange={(e) => setStartHour(Number(e.currentTarget.value))} />
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor='shchedule'>Inicio descanso mediodía</FormLabel>
                                <Input
                                    placeholder="0"
                                    size="md"
                                    type="number"
                                    value={startBreak}
                                    onChange={(e) => setStartBreak(Number(e.currentTarget.value))} />
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor='shchedule'>Fin descanso mediodía</FormLabel>
                                <Input
                                    placeholder="0"
                                    size="md"
                                    type="number"
                                    value={endBreak}
                                    onChange={(e) => setEndBreak(Number(e.currentTarget.value))} />
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor='shchedule'>Fin Jornada laboral</FormLabel>
                                <Input
                                    placeholder="0"
                                    size="md"
                                    type="number"
                                    value={endHour}
                                    onChange={(e) => setEndHour(Number(e.currentTarget.value))} />
                            </FormControl>
                        </Stack>
                    </Box>
                    <Box bg='bg-surface' boxShadow='sm' borderRadius='lg' flexGrow={1} pb={2}>
                        <Heading mt={5} ml={5} mb={0} size={'sm'}>Bono por defecto (Asociados)</Heading>
                        <Stack direction={{ base: 'column', lg: 'row' }} alignSelf={{ base: 'flex-start', lg: 'flex-end' }} width={{ base: '100%' }} p={5} display='flex'>
                            <FormControl>
                                <FormLabel htmlFor='shchedule'>Seleccione un bono</FormLabel>
                                <Select
                                    options={bonusDataSnapshot?.docs.filter(el => el.id !== 'bonogestorinterno').map(o => ({ label: o.data().description, value: o.id }))}
                                    value={{ label: bonusDataSnapshot?.docs.find(c => c.id === bonusSelected)?.data().description, value: bonusSelected }}
                                    isLoading={isLoadingBonus}
                                    placeholder='Elija un bono'
                                    noOptionsMessage={(_i) => 'No hay bonos.'}
                                    onChange={nv => setBonusSelected(nv?.value!!)}
                                />
                            </FormControl>
                        </Stack>
                    </Box>
                    <Box bg='bg-surface' boxShadow='sm' borderRadius='lg' flexGrow={1} pb={2}>
                        <Heading mt={5} ml={5} mb={0} size={'sm'}>Configuración ERP</Heading>
                        <Stack direction={{ base: 'column', lg: 'row' }} alignSelf={{ base: 'flex-start', lg: 'flex-end' }} width={{ base: '100%' }} p={5} display='flex'>
                            <FormControl flex="1">
                                <FormLabel htmlFor='erp'>Host</FormLabel>
                                <Input
                                    placeholder="http://example.com"
                                    size="md"
                                    type="text"
                                    value={erpHost}
                                    onChange={(e) => setErpHost(e.currentTarget.value)} />
                            </FormControl>
                            <FormControl flex="1">
                                <FormLabel htmlFor='erp'>Token</FormLabel>
                                <Input
                                    placeholder="secret"
                                    size="md"
                                    type="password"
                                    value={erpToken}
                                    onChange={(e) => setErpToken(e.currentTarget.value)} />
                            </FormControl>
                            <Flex align="flex-end" justify="flex-end" flex="0 0 auto">
                                <Button
                                    size="md"
                                    colorScheme="gray"
                                    rightIcon={<Icon as={CheckIcon} color="green.500" />}
                                    onClick={async () => {
                                        await handleSubmit(send)();
                                        await checkErpStatus()
                                    }}
                                >
                                    Guardar y comprobar la configuración
                                </Button>
                            </Flex>
                        </Stack>
                    </Box>                    <Box bg='bg-surface' boxShadow='sm' borderRadius='lg' flexGrow={1} pb={6}>
                        <Heading mt={5} ml={5} mb={0} size={'sm'}>Configuración servicio SMTP</Heading>
                        <Stack direction={{ base: 'column', lg: 'row' }} alignSelf={{ base: 'flex-start', lg: 'flex-end' }} width={{ base: '100%' }} p={5} display='flex'>
                            <FormControl>
                                <FormLabel htmlFor='shchedule'>Usuario (email)</FormLabel>
                                <Input
                                    placeholder="user@example.com"
                                    size="md"
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.currentTarget.value)} />
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor='shchedule'>Password</FormLabel>
                                <Input
                                    placeholder="test12345"
                                    size="md"
                                    type="text"
                                    value={password}
                                    onChange={(e) => setPassword(e.currentTarget.value)} />
                            </FormControl>
                        </Stack>
                        <Stack direction={{ base: 'column', lg: 'row' }} alignSelf={{ base: 'flex-start', lg: 'flex-end' }} width={{ base: '100%' }} pl={5} pr={5} display='flex'>
                            <FormControl>
                                <FormLabel htmlFor='shchedule'>Host</FormLabel>
                                <Input
                                    placeholder="smtp.example.com"
                                    size="md"
                                    type="text"
                                    value={host}
                                    onChange={(e) => setHost(e.currentTarget.value)} />
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor='shchedule'>Puerto</FormLabel>
                                <Input
                                    placeholder="587"
                                    size="md"
                                    type="number"
                                    value={port}
                                    onChange={(e) => setPort(Number(e.currentTarget.value))} />
                            </FormControl>
                        </Stack>
                    </Box>
                    <Box bg='bg-surface' boxShadow='sm' borderRadius='lg' flexGrow={1} p={4}>
                        <Heading pb={5} size={'sm'}>Lista destinatarios notificaciones</Heading>
                        <ChakraTagInput
                            tags={recipientsNotification}
                            onTagsChange={handleTagsChange}
                            wrapProps={{ direction: 'column', align: 'start' }}
                            placeholder='Introduce un email válido y pulse intro'
                            wrapItemProps={(isInput) => (isInput ? { alignSelf: 'stretch' } : null)}
                        />
                    </Box>
                    <Box bg='bg-surface' boxShadow='sm' borderRadius='lg' flexGrow={1} p={4}>
                        <Heading pb={5} size={'sm'}>Sectores de eventos</Heading>
                        <ChakraTagInput
                            tags={sectors}
                            onTagsChange={handleSectors}
                            wrapProps={{ direction: 'column', align: 'start' }}
                            placeholder='Introduce un sector y pulse intro'
                            wrapItemProps={(isInput) => (isInput ? { alignSelf: 'stretch' } : null)}
                        />
                    </Box>
                    <Box bg='bg-surface' boxShadow='sm' borderRadius='lg' flexGrow={1} pb={4}>
                        <Heading m={5} size={'sm'}>Edificios</Heading>
                        <Box>
                            {locations.map((input, index) => {
                                return (
                                    <ScaleFade initialScale={0.9} in={!false} key={index}>
                                        <Card mt={2}>
                                            <CardBody backgroundColor={'gray.100'}>
                                                <Box position={'absolute'} right={2} top={2}>
                                                    <IconButton
                                                        colorScheme='red'
                                                        aria-label='Search database'
                                                        icon={<DeleteIcon />}
                                                        size={'xs'}
                                                        onClick={() => removeLocationFields(index)}
                                                    />
                                                </Box>
                                                <FormControl>
                                                    <Box display={'flex'}>
                                                        <Switch
                                                            isChecked={input.visible}
                                                            name='visible'
                                                            onChange={event => handleFormChange(index, event.target.name, !input.visible)}
                                                        />
                                                        <FormLabel pl={4} htmlFor='isChecked'>Visible</FormLabel>
                                                    </Box>
                                                </FormControl>

                                                <Stack direction={{ base: 'column', lg: 'row' }} alignSelf={{ base: 'flex-start', lg: 'flex-end' }} width={{ base: '100%' }} p={5} display='flex'>
                                                    <FormControl >
                                                        <FormLabel htmlFor='units'>nombre</FormLabel>
                                                        <Input
                                                            name='name'
                                                            value={input.name}
                                                            onChange={event => handleFormChange(index, event.target.name, event.target.value)}
                                                        />
                                                    </FormControl>
                                                    <FormControl >
                                                        <FormLabel htmlFor='units'>Id en ERP</FormLabel>
                                                        <Input
                                                            name='externalId'
                                                            type='number'
                                                            value={input.externalId}
                                                            onChange={event => handleFormChange(index, event.target.name, event.target.value)}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </CardBody>
                                        </Card>
                                    </ScaleFade>
                                )
                            })}
                            <Box position='relative' h='100px'>
                                <AbsoluteCenter p='4' >
                                    <IconButton
                                        isRound={true}
                                        variant='solid'
                                        colorScheme='teal'
                                        aria-label='Done'
                                        fontSize='20px'
                                        icon={<AddIcon />}
                                        onClick={addLocationFields}
                                    />
                                </AbsoluteCenter>
                            </Box>
                        </Box>
                    </Box>
                    <HStack justify={'flex-end'}>
                        <Button as={Link} to={'../bonus'} replace variant='ghost' size='lg'>Cancelar</Button>
                        <Button
                            variant='primary'
                            isLoading={isSubmitting}
                            isDisabled={false}
                            type='submit'
                            size='lg'>
                            Guardar
                        </Button>
                    </HStack>
                </Stack>
            </Stack>
        </>
    )

}