import {
    Box,
    Button,
    Center,
    Container,
    FormControl,
    FormLabel,
    Heading,
    Image,
    Input,
    Link,
    Stack,
    Text,
    useToast,
} from '@chakra-ui/react'
import Logo from '../../assets/img/logo.png';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail, sendEmailVerification, signOut } from 'firebase/auth';
import { firebaseAuth, firebaseFunctions } from '../../firebase';
import { showToast } from '../../helpers';
import { httpsCallable } from 'firebase/functions';
import { ProfileFormComponent } from './components/ProfileForm';

type RegisterFormData = {
    email: string,
    password: string,
    cif: string,
    displayName: string
}

export const Register = () => {


    const createUserCommand = httpsCallable(
        firebaseFunctions,
        'createUserCommandCallable'
    );

    const findCompanyInErpCommand = httpsCallable(
        firebaseFunctions,
        'findCompanyInErpCommandCallable'
    );

    const toast = useToast();
    const [email, setEmail] = useState('')
    const [displayName, setDisplayName] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [cif, setCif] = useState('')
    const [profileFormVisible, setProfileFormVisible] = useState(false)

    const {
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<RegisterFormData>({
        values: {
            email,
            password,
            cif,
            displayName
        }
    });

    const getMessageError = (code: string) => {
        if (code === 'auth/email-already-in-use') {
            return 'El correo ya está registrado.'
        } else if (code === 'auth/weak-password') {
            return 'La contraseña debe contener al menos 6 carácteres, minúsculas, mayúsculas y un número';
        } else {
            return code
        }
    }

    const createUser = (
        email: string,
        password: string,
        cif: string,
        displayName: string
    ) => {
        createUserWithEmailAndPassword(firebaseAuth, email, password)
            .then(async (userCredential) => {
                var user = userCredential.user;
                sendEmailVerification(user)
                signOut(firebaseAuth)
                createUserCommand({
                    uid: user.uid,
                    email,
                    displayName,
                    cif,
                    forceAuthorize: true
                });
                showToast(toast, {
                    status: 'success',
                    title: 'Success',
                    description: 'Usuario creado. Verifique el correo'
                });
            })
            .catch((error) => {
                showToast(toast, {
                    status: 'error',
                    title: 'Error',
                    description: getMessageError(error.code)
                });
            });

    }

    const send = async (data: RegisterFormData) => {
        const checkEmailExists = async (email: string) => {
            return await fetchSignInMethodsForEmail(firebaseAuth, email)
                .then((signInMethods) => {
                    if (signInMethods.length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((error) => {
                    console.log('Error checking email:', error);
                });
        }

        return await new Promise<void>(async (resolve) => {
            setIsLoading(true)
            if (!data.email && !data.password && !data.cif && !data.displayName) {
                showToast(toast, {
                    status: 'error',
                    title: 'Error',
                    description: 'Todos los campos son obligatorios'
                });
                setIsLoading(false);
                resolve();
            } else if (data.cif.length < 5) {
                showToast(toast, {
                    status: 'error',
                    title: 'Error',
                    description: 'La longitud mínima del CIF/NIF son 5 carácteres'
                });
            } else if (data.password.length < 6) {
                showToast(toast, {
                    status: 'error',
                    title: 'Error',
                    description: 'La longitud mínima del password son 6 carácteres'
                });
            } else if (await checkEmailExists(data.email)) {
                showToast(toast, {
                    status: 'error',
                    title: 'Error',
                    description: 'El correo introducido ya está en uso'
                });
            } else {

                await findCompanyInErpCommand({ cif }
                ).then((result) => {
                    if (result.data.success) {
                        if (result.data.exists) {
                            createUser(
                                data.email,
                                data.password,
                                data.cif,
                                data.displayName
                            )
                        } else {
                            setProfileFormVisible(true);
                        }
                    } else {
                        showToast(toast, {
                            status: 'error',
                            title: 'Error',
                            description: 'Existe un problema en nuestros servidores. Inténtelo más tarde'
                        });
                    }
                }).catch((e) => {
                    console.error(e);
                    showToast(toast, {
                        status: 'error',
                        title: 'Error',
                        description: 'Existe un problema en nuestros servidores. Inténtelo más tarde'
                    });
                });
            }
            setIsLoading(false)
        });
    }

    return (
        <Container maxW="lg" py={{ base: '12', md: '14' }} px={{ base: '0', sm: '8' }}>
            <Stack spacing="8">
                <Stack spacing="6">
                    <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                        <Center>
                            <Image width={32} src={Logo} />
                        </Center>
                        <Heading size={{ base: 'xs', md: 'sm' }}>{!profileFormVisible ? 'Nuevo usuario' : 'Perfil de facturación'}</Heading>
                        {(!profileFormVisible &&
                            <Text color="fg.muted">
                                ¿Ya tienes cuenta? <Link href="../auth/login">Entra</Link>
                            </Text>
                        )}
                    </Stack>
                </Stack>

                <Box
                    py={{ base: '0', sm: '8' }}
                    px={{ base: '4', sm: '10' }}
                    bg={{ base: 'transparent', sm: 'bg.surface' }}
                    boxShadow={{ base: 'none', sm: 'md' }}
                    borderRadius={{ base: 'none', sm: 'xl' }}
                >
                    {(!profileFormVisible &&
                        <Stack as='form' onSubmit={handleSubmit(send)} spacing="6">
                            <Stack spacing="5">
                                <FormControl>
                                    <FormLabel htmlFor="displayName">Su nombre</FormLabel>
                                    <Input id="displayName" type="text" onChange={(e) => setDisplayName(e.currentTarget.value)} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="cif">CIF/NIF de facturación</FormLabel>
                                    <Input id="cif" type="text" onChange={(e) => setCif(e.currentTarget.value)} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="email">Email</FormLabel>
                                    <Input id="email" type="email" onChange={(e) => setEmail(e.currentTarget.value)} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="email">Password</FormLabel>
                                    <Input id="password" type="password" onChange={(e) => setPassword(e.currentTarget.value)} />
                                </FormControl>
                            </Stack>
                            <Stack spacing="6">
                                <Button
                                    colorScheme='blue'
                                    type='submit'
                                    isLoading={isLoading}>
                                    Crear</Button>
                            </Stack>
                        </Stack>
                    )}
                    {(profileFormVisible &&
                        <ProfileFormComponent cif={cif} displayName={displayName} email={email} password={password}></ProfileFormComponent>

                    )}
                </Box>
            </Stack>
        </Container>
    )
}