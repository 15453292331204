import { ArrowBackIcon } from '@chakra-ui/icons'
import { HStack, Heading, Stack, useToast, IconButton, Flex, Spinner } from '@chakra-ui/react'
import { collection, doc, limit, orderBy, query, where } from 'firebase/firestore';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { firebaseFirestore } from '../../firebase';
import { useEffect } from 'react';
import { showToast } from '../../helpers';
import { Helmet } from 'react-helmet-async';
import { GeneralInformation } from './details/GeneralInformation';

type ClientFormData = {
    name: string;
    regCodeGeneratorDictionary?: {
        name: string;
        value: string;
    }
}

export const ClientDetails = () => {
    const { id } = useParams();
    const toast = useToast();
    const navigate = useNavigate();

    const [
        clientDataSnapshot,
        isLoadingClientData,
        hasClientLoadingError
    ] = useDocumentData(doc(firebaseFirestore, `users/${id}`));

    const [
        bonusDataSnapshot,
        _isLoadingBonusData,
        hasBonusLoadingError
    ] = useCollection(query(collection(firebaseFirestore, 'bonus')));


    useEffect(() => {
        if (hasClientLoadingError) {
            console.error(hasClientLoadingError);
            showToast(toast, {
                status: 'error',
                title: 'Error',
                description: 'No se puedo cargar los datos del cliente. Inténtelo de nuevo.'
            });
            navigate('../clients', { replace: true });
        }
        if (hasBonusLoadingError) {
            console.error(hasBonusLoadingError);
        }
    }, [
        hasClientLoadingError,
        hasBonusLoadingError,
    ]);

    useEffect(() => {
        if (!isLoadingClientData && !clientDataSnapshot) {
            showToast(toast, {
                status: 'error',
                title: 'Error',
                description: 'El cliente no existe.'
            });
            navigate('../clients', { replace: true });
        }

    }, [isLoadingClientData]);


    return (
        <>
            <Helmet>
                <title>Clientes: Detalles de cliente</title>
            </Helmet>
            <Stack spacing={8}>
                <HStack spacing={4}>
                    <IconButton
                        as={Link}
                        to='../clients'
                        icon={<ArrowBackIcon boxSize={8} />}
                        aria-label={'Back to list'}
                        variant={'ghost'} />
                    <Heading size='md'>
                        {'Clientes: Detalles de cliente'}
                    </Heading>
                </HStack>
                {isLoadingClientData &&
                    <Flex justifyContent={'center'} py={24}>
                        <Spinner size='xl' />
                    </Flex>
                }
                {clientDataSnapshot &&
                    <Stack spacing={12}>
                        <GeneralInformation
                            id={id ?? ''}
                            clientDataSnapshot={clientDataSnapshot}
                            bonusDataSnapshot={bonusDataSnapshot} 
                            />
                    </Stack>
                }
            </Stack>
        </>
    )
}