import { extendTheme } from '@chakra-ui/react';
import { theme as proTheme } from '@chakra-ui/pro-theme'
import {  MultiSelectTheme } from 'chakra-multiselect'

export const theme = extendTheme(
    proTheme,
    {
        styles: {
            global: {
                '#root': {
                    height: '100%',
                },
                '.no-scroll-bar': {
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none'
                },
                '.no-scroll-bar::-webkit-scrollbar': {
                    display: 'none'
                },
            },
        },
        config: {
            initialColorMode: 'light',
            useSystemColorMode: false
        },
        colors: {
            ...proTheme.colors,
            brand: {
                50: '#f3f5ff',
                100: '#e8ebff',
                200: '#d5dcff',
                300: '#b3bdff',
                400: '#8892fd',
                500: '#585ffa',
                600: '#3a35f2',
                700: '#2c23de',
                800: '#241dba',
                900: '#201a98',
            },
        },
        components: {
            MultiSelect: MultiSelectTheme
        }    
    }
);