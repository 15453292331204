import { BookingStatus } from "../types/BookingStatus.js";
import { FacilityStatus } from "../types/FacilityStatus.js";
import { FacilityType } from "../types/FacilityType.js";
import { RateUnitType } from "../types/RateUnitType.js";
import { UserStatus } from "../types/UserStatus.js";
import { UserType } from "../types/UserType.js";

export const getLabel = (value: any, plural: boolean = false): string => {

    let description = '';

    if (value === UserStatus.ACTIVE || value === UserStatus.ACTIVE_BY_API) {
        description = 'ACTIVO';
    } else if (value === UserStatus.BLOCKED || value === UserStatus.BLOCKED_BY_API) {
        description = 'BLOQUEADO';
    } else if (value === UserStatus.ONLY_LOGIN || value === UserStatus.ONLY_LOGIN_BY_API) {
        description = 'SÓLO LOGIN';
    } else if (value === UserType.REGULAR) {
        description = 'Cliente';
    } else if (value === UserType.ADMIN) {
        description = 'Administrador';
    } else if (value === UserType.MANAGER) {
        description = 'Gestor';
    } else if (value === UserType.ASSOCIATED) {
        description = 'Asociado';
    } else if (value === BookingStatus.RESERVED) {
        description = 'Reservado';
    } else if (value === BookingStatus.DENIED) {
        description = 'Denegada';
    } else if (value === BookingStatus.REFUNDED) {
        description = 'Reembolsada';
    } else if (value === BookingStatus.WAITING_FOR_APPROVAL) {
        description = 'Esperando Aprobación';
    } else if (value === BookingStatus.WAITING_FOR_PAYMENT) {
        description = 'Esperando Pago';
    } else if (value === FacilityStatus.AVAILABLE) {
        description = 'Disponible';
    } else if (value === FacilityStatus.UNAVAILABLE) {
        description = 'No disponible';
    } else if (value === RateUnitType.HOUR) {
        description = (plural)?'Horas':'Hora';
    } else if (value === RateUnitType.HALFWORKINGDAY) {
        description = (plural)?'Medias jornadas laborales':'Media jornada laboral';
    } else if (value === RateUnitType.WORKINGDAY) {
        description = (plural)?'Jornadas laborales':'Jornada laboral';
    } else if (value === RateUnitType.WEEK) {
        description = (plural)?'Semanas':'Semana';
    } else if (value === RateUnitType.MONTH) {
        description = (plural)?'Meses':'Mes';
    } else if (value === FacilityType.MEETING_ROOM) {
        description = 'Sala de reuniones';
    } else if (value === FacilityType.TRAINING_ROOM) {
        description = 'Sala de formación';
    } else if (value === FacilityType.LABORATORY) {
        description = 'Laboratorio';
    } else if (value === FacilityType.MULTIPURPOSE_ROOM) {
        description = 'Sala multiusos';
    } else if (value === FacilityType.WORKSPACE) {
        description = 'Sala de trabajo';
    } else if (value === FacilityType.OTHER) {
        description = 'Otro tipo de sala';
    } else {
        description = value
    }

    return description
}