import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast
} from '@chakra-ui/react'
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { Controller, useForm } from 'react-hook-form';
import { showToast } from '../../../helpers';
import { firebaseFirestore } from '../../../firebase';
// import { useAppContext } from '../../layout/app/AppLayout';

type FormData = {
    value: any;
}

export const InputEditModal = ({
    documentPath,
    readableFieldName,
    fieldName,
    isDisabled,
    value,
    isOpen,
    onClose
}: {
    readableFieldName: string,
    documentPath: string,
    fieldName: string,
    value?: any,
    isOpen: boolean,
    isDisabled: boolean
    onClose: () => void
}) => {
    // const context = useAppContext();
    const toast = useToast();

    const {
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
    } = useForm<FormData>({
        values: { value },
        resetOptions: { keepDirtyValues: true }
    });

    const send = (data: FormData) => {
        return new Promise<void>((resolve) => {
            const success = () => {
                showToast(toast, {
                    title: 'Success',
                    description: 'Field value was updated',
                    status: 'success',
                });
                onClose();
            }
            const error = (e: any) => {
                console.error(e);
                showToast(toast, {
                    title: 'Error',
                    description: 'Cant update field value. Please try again.',
                    status: 'error',
                });
            }
            updateDoc(
                doc(firebaseFirestore, documentPath), {
                [fieldName]: data.value,
                updated: serverTimestamp()
            })
                .then(success)
                .catch(error)
                .finally(resolve);
        });
    }
    return (
        <Modal
            size='2xl'
            isOpen={isOpen}
            onClose={onClose}
            isCentered={true}>
            <ModalOverlay />
            <ModalContent as='form' noValidate onSubmit={handleSubmit(send)}>
                <ModalHeader>Editar {readableFieldName}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl isInvalid={errors?.value !== undefined}>
                        <FormLabel htmlFor='value'>{readableFieldName}</FormLabel>
                        <Controller
                            control={control}
                            name='value'
                            render={({ field: { onChange,value, ref } }) => (
                                <Input
                                    id='value'
                                    value={value}
                                    onFocusCapture={()=>{
                                        onChange('');
                                    }}
                                    onChange={onChange}
                                    onBlur={e => {
                                        if (e.target.value.length > 0) {
                                            e.target.value = e.target.value.padStart(10, '0');
                                            onChange(e);
                                        }
                                    }}
                                    textAlign={'right'}
                                    type='text'
                                    ref={ref}
                                />
                            )} />
                        <FormErrorMessage>
                            {errors.value &&
                                <>{errors.value.message}</>
                            }
                        </FormErrorMessage>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button variant='ghost' mr={3} onClick={onClose} color='black'>
                        Close
                    </Button>
                    <Button
                        type='submit'
                        isDisabled={isDisabled}
                        isLoading={isSubmitting}>
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}