import { Image } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ImageSlider = ({ slides }) => {
  return (
    <Carousel 
      infiniteLoop
      dynamicHeight={false}
      centerSlidePercentage={80}

      emulateTouch
      showThumbs={false}
      autoPlay
      
    >
      {slides.map((slide) => {
        return <Image height={'300px'}  src={slide.image} />;
      })}
    </Carousel>
  );
};

export default ImageSlider;