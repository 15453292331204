import {
    Box,
    Button,
    Center,
    Checkbox,
    Container,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Image,
    Input,
    Link,
    Stack,
    Text,
    useToast,
} from '@chakra-ui/react'
import Logo from '../../assets/img/logo.png';
import { useState } from 'react';
import { firebaseAuth } from '../../firebase';
import { showToast } from '../../helpers';
import { useForm } from 'react-hook-form';
import { signInWithEmailAndPassword } from '@firebase/auth';

type LoginFormData = {
    email: string,
    password: string,
    rememberMe: boolean
}

export const Login = () => {
    const toast = useToast();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [rememberMe, setRememberMe] = useState(true);

    const {
        handleSubmit,
    } = useForm<LoginFormData>({
        values: {
            email,
            password,
            rememberMe
        }
    });

    const send = async (data: LoginFormData) => {
        return await new Promise<void>(async (resolve) => {
            setIsLoading(true)
            let errorMessage = null
            await signInWithEmailAndPassword(firebaseAuth, data.email, data.password)
                .then(async (userCredential) => {
                    resolve()
                })
                .catch((error) => {
                    errorMessage = error.code;
                });

            if ((firebaseAuth.currentUser && firebaseAuth.currentUser.emailVerified === false) || errorMessage) {
                firebaseAuth.signOut()
                if (firebaseAuth.currentUser && firebaseAuth.currentUser.emailVerified === false) {
                    errorMessage = 'Tiene que aprobar el correo de verificación, revise su correo.';
                } else if (errorMessage === 'auth/user-disabled') {
                    errorMessage = 'Su empresa debe aprobar su cuenta';
                } else {
                    errorMessage = 'Usuario o contraseña incorrectos';
                }

                showToast(toast, {
                    status: 'error',
                    title: 'Error',
                    description: errorMessage
                });
            }
            setIsLoading(false)
        });
    }

    return (
        <Container maxW="lg" py={{ base: '12', md: '14' }} px={{ base: '0', sm: '8' }}>
            <Stack spacing="8">
                <Stack spacing="6">
                    <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                        <Center>
                            <Image width={32} src={Logo} />
                        </Center>
                        <Heading size={{ base: 'xs', md: 'sm' }}>Inicio de sesión</Heading>
                        <Text color="fg.muted">
                            No tienes cuenta? <Link href="../auth/register">Regístrate</Link>
                        </Text>
                    </Stack>
                </Stack>
                <Box
                    py={{ base: '0', sm: '8' }}
                    px={{ base: '4', sm: '10' }}
                    bg={{ base: 'transparent', sm: 'bg.surface' }}
                    boxShadow={{ base: 'none', sm: 'md' }}
                    borderRadius={{ base: 'none', sm: 'xl' }}
                >
                    <Stack as='form' onSubmit={handleSubmit(send)} spacing="6">
                        <Stack spacing="5">
                            <FormControl>
                                <FormLabel htmlFor="email">Email</FormLabel>
                                <Input id="email" type="email" onChange={(e) => setEmail(e.currentTarget.value)} />
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="email">Password</FormLabel>
                                <Input id="password" type="password" onChange={(e) => setPassword(e.currentTarget.value)} />
                            </FormControl>
                        </Stack>
                        <HStack justify="space-between">
                            <Checkbox
                                pr={4}
                                colorScheme='blue'
                                isChecked={rememberMe}
                                onChange={(e) => setRememberMe(!rememberMe)}
                            >
                                Recuérdame
                            </Checkbox>
                            <Link href='../auth/resetpassword'>

                                <Button colorScheme='blue' variant="text" size="sm" >
                                    ¿Olvidaste la contraseña?
                                </Button>
                            </Link>
                        </HStack>
                        <Stack spacing="6">
                            <Button
                                colorScheme='blue'
                                type='submit'
                                isLoading={isLoading}>
                                Entrar</Button>
                        </Stack>
                        <Center>
                            <Link href='../auth/verificationmail' colorScheme='blue' variant="text" size="sm" >
                                ¿No recibiste el correo de verificación?
                            </Link>
                        </Center>
                    </Stack>
                </Box>
            </Stack>
        </Container>
    )
}