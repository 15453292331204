import { ChakraProvider } from '@chakra-ui/react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Outlet,
    Route,
    RouterProvider,
    ScrollRestoration
} from 'react-router-dom';
import { theme } from './theme';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { NotFound } from './routes/misc/NotFound';
import { Error } from './routes/misc/Error'
import { CookiesProvider } from 'react-cookie';
import { AppLayout } from './layout/app/AppLayout';
import { ClientsList } from './routes/clients/ClientsList';
import { ClientDetails } from './routes/clients/ClientDetails';
import { BookingsList } from './routes/bookings/BookingsList';
import { BookingDetails } from './routes/bookings/BookingDetails';
import { BonusList } from './routes/bonus/BonusList';
import { BonusForm } from './routes/bonus/BonusForm';
import { FacilitiesList } from './routes/facilities/FacilitiesList';
import { BookingForm } from './routes/bookings/BookingForm';
import { FacilitiesForm } from './routes/facilities/FacilitiesForm';
import { ClientDashboard } from './dashboard/ClientDashboard';
import { FacilityBookingForm } from './routes/facilities/FacilityBookingForm';
import { SettingsForm } from './routes/settings/SettingsForm';
import { BookingCalendar } from './routes/bookings/BookingCalendar';
import { Login } from './routes/auth/Login';
import { AuthLayout } from './layout/auth/AuthLayout';
import { Register } from './routes/auth/Register';
import { ResetPassword } from './routes/auth/ResetPassword';
import { UserLayout } from './layout/app/UserLayout';
import { ClientForm } from './routes/clients/ClientForm';
import { ManagerLayout } from './layout/app/ManagerLayout';
import { PaymentOk } from './routes/checkout/paymentOk/PaymentOk';
import { PaymentKo } from './routes/checkout/paymentKo/PaymentKo';
import { PublicLayout } from './layout/app/PublicLayout';
import { Checkout } from './routes/checkout/Checkout';
import { ResendVerificationMail } from './routes/auth/ResendVerificationMail';


const Root = () => (
    <>
        <ScrollRestoration />
        <Outlet />
    </>
)

export const App = () => {

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path='/' element={<Root />} errorElement={<Error />}>
                <Route path='auth' element={<AuthLayout />}>
                    <Route path='login' element={<Login />} />
                    <Route path='verificationmail' element={<ResendVerificationMail />} />
                    <Route path='register' element={<Register />} />
                    <Route path='resetpassword' element={<ResetPassword />} />
                </Route>
                <Route path='app' element={<AppLayout />}>
                    <Route path='dashboard' element={<ClientDashboard />} />
                    <Route path='clients' element={<ClientsList />} />
                    <Route path='clients/:id' element={<ClientDetails />} />
                    <Route path='user/new' element={<ClientForm />} />
                    <Route path='bookings' element={<BookingsList />} />
                    <Route path='bookings/:id' element={<BookingDetails />} />
                    <Route path='booking/new' element={<BookingForm />} />
                    <Route path='bookings/facilities/:clientId' element={<BookingsList />} />
                    <Route path='bookings/clients/:facilityId' element={<BookingsList />} />
                    <Route path='bookings/calendar' element={<BookingCalendar />} />
                    <Route path='bonus' element={<BonusList />} />
                    <Route path='bonus/:id' element={<BonusForm />} />
                    <Route path='facilities' element={<FacilitiesList />} />
                    <Route path='facilities/:id' element={<FacilitiesForm />} />
                    <Route path='facilities/:id/book' element={<FacilityBookingForm />} />
                    <Route path='settings' element={<SettingsForm />} />
                </Route>
                <Route path='manager' element={<ManagerLayout />}>
                    <Route path='dashboard' element={<ClientDashboard />} />
                    <Route path='clients' element={<ClientsList />} />
                    <Route path='clients/:id' element={<ClientDetails />} />
                    <Route path='bookings' element={<BookingsList />} />
                    <Route path='bookings/:id' element={<BookingDetails />} />
                    <Route path='booking/new' element={<BookingForm />} />
                    <Route path='bookings/calendar' element={<BookingCalendar />} />
                    <Route path='facilities' element={<FacilitiesList />} />
                    <Route path='facilities/:id/book' element={<FacilityBookingForm />} />
                </Route>
                <Route path='user' element={<UserLayout />}>
                    <Route path='dashboard' element={<ClientDashboard />} />
                    <Route path='bookings' element={<BookingsList />} />
                    <Route path='bookings/:id' element={<BookingDetails />} />
                    <Route path='booking/new' element={<BookingForm />} />
                    <Route path='bookings/clients/:clientId' element={<BookingsList />} />
                    <Route path='bookings/calendar' element={<BookingCalendar />} />
                    <Route path='facilities/:id/book' element={<FacilityBookingForm />} />
                </Route>

                <Route path='public' element={<PublicLayout />}>
                    <Route path='checkout/:bookingId' element={<Checkout />} />
                    <Route path='checkout/paymentok/:orderId' element={<PaymentOk />} />
                    <Route path='checkout/paymentko/:orderId' element={<PaymentKo />} />
                </Route>

                <Route path='not-found' element={<NotFound />} />
                <Route index element={<Navigate to='/not-found' replace />} />
                <Route path='*' element={<Navigate to='/not-found' replace />} />
            </Route >
        )
    );
    return (
        <CookiesProvider>
            <HelmetProvider>
                <Helmet>
                    <title>PCTT</title>
                    <link rel='canonical' href='https://pcttbinary.web.app' />
                    <meta name='description'
                        content='PCTT - Sistema de reservas' />
                </Helmet>
                <ChakraProvider theme={theme}>
                    <RouterProvider router={router} />
                </ChakraProvider>
            </HelmetProvider>
        </CookiesProvider>
    );
}