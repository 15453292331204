import {
    Avatar,
    Box,
    Button,
    ButtonGroup,
    Container,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Image
} from '@chakra-ui/react';
import { User, signOut } from 'firebase/auth';
import { FiMoreVertical } from 'react-icons/fi';
import { firebaseAuth } from '../../firebase';
import { MobileDrawer } from './MobileDrawer';
import Logo from '../../assets/img/pctt-logo.png';
import { AppRoutes } from './AppRoutes';
import { Link } from 'react-router-dom';

export const Navbar = ({ user, isAdmin, isManager }: { user: User | null, isAdmin: boolean, isManager: boolean }) => {
    return (
        <Box borderBottomWidth='1px' bg='bg.surface' position='relative'>
            <Container maxW={'container.2xl'} py={4}>
                <HStack justify='space-between' spacing='8'>
                    <HStack spacing='10'>
                        <HStack spacing='3'>
                            <MobileDrawer isAdmin={isAdmin} isManager={isManager} />
                            <Image width={{ base: 16, lg: 24 }} src={Logo} />
                        </HStack>
                        <ButtonGroup
                            size='lg'
                            variant='text'
                            colorScheme='gray'
                            spacing='8'
                            display={{ base: 'none', lg: 'flex' }}
                        >
                            {AppRoutes.map(r => {
                                if (r.type === 'button') {
                                    if (
                                        (r.AdminAllow && isAdmin) ||
                                        (r.ManagerAllow && isManager) ||
                                        (r.UserAllow && !isAdmin && !isManager)
                                        ) {
                                            return (
                                                <Button key={r.name} as={Link} to={r.to}>{r.name}</Button>
                                            );
                                    }
                                    return
                                } else {
                                    return //TODO si son todos button, quitar
                                }
                            })}
                        </ButtonGroup>
                    </HStack>
                    <HStack spacing={{ base: '2', md: '4' }}>
                        <HStack>
                            {/*<Avatar boxSize='10' src={user.photoURL ?? ''} name={user.displayName ?? ''} />*/}
                            <Menu>
                                <MenuButton
                                    as={IconButton}
                                    aria-label='User Menu'
                                    icon={<FiMoreVertical />}
                                    variant='ghost'
                                />
                                <MenuList>
                                    <MenuItem onClick={() => {
                                        signOut(firebaseAuth);
                                    }}>
                                        Logout
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                    </HStack>
                </HStack>
            </Container>
        </Box>
    )
}