export type NavElement = {
    name: string;
    to: string;
}
export const AppRoutes = [
    {
        name: 'Inicio',
        to: 'dashboard',
        type: 'button',
        UserAllow: true,
        ManagerAllow: true,
        AdminAllow: true
    },
    {
        name: 'Clientes',
        to: 'clients',
        type: 'button',
        UserAllow: false,
        ManagerAllow: true,
        AdminAllow: true
    },
    {
        name: 'Reservas',
        to: 'bookings',
        type: 'button',
        UserAllow: true,
        ManagerAllow: true,
        AdminAllow: true
    },
    {
        name: 'Salas',
        to: 'facilities',
        type: 'button',
        UserAllow: false,
        ManagerAllow: true,
        AdminAllow: true
    },
    {
        name: 'Bonos',
        to: 'bonus',
        type: 'button',
        UserAllow: false,
        ManagerAllow: false,
        AdminAllow: true
    },
    {
        name: 'Ajustes',
        to: 'settings',
        type: 'button',
        UserAllow: false,
        ManagerAllow: false,
        AdminAllow: true
    }
]