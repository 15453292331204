import { Box, HStack, IconButton, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { FiEdit2 } from 'react-icons/fi'
import { Link } from 'react-router-dom'

export const DataBox = ({
    title,
    content,
    link,
    children,
    onEdit
}: {
    title: string,
    content?: string,
    link?: string,
    children?: ReactNode | ReactNode[],
    onEdit?: () => void
}) => {      
    return (
        <Box h={16}>
            <Text fontSize='lg' fontWeight='bold'>
                {title}
            </Text>
            {(content !== undefined) &&
                <HStack justify={'space-between'}>
                    <Text {...(link ? {as: Link} : {})} {...(link ? {to: link} : {})} fontSize='sm'>
                        {content}
                    </Text>
                    {onEdit &&
                        <Box pr={4}>
                            <IconButton
                                onClick={onEdit}
                                icon={<FiEdit2 />}
                                variant='tertiary'
                                aria-label={`Edit ${title}`} />
                        </Box>
                    }
                </HStack>
            }
            {children}


        </Box>
    )
}