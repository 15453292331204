import {
    Box,
    Button,
    Stack,
    Text,
    useToast,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from '@chakra-ui/react';
import { Select as ReactSelect } from 'chakra-react-select';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useOutletContext, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { DocumentData, QueryDocumentSnapshot, QueryFieldFilterConstraint, collection, doc, query, where } from 'firebase/firestore';
import { firebaseAuth, firebaseFirestore } from '../../firebase';
import { showToast } from '../../helpers';
import debounce from 'lodash.debounce';
import { Helmet } from 'react-helmet-async';
import { BookingStatus, getStatusOptions } from '../../types/BookingStatus';
import FullCalendar from '../../components/FullCalendar';
import { User } from 'firebase/auth';

export const BookingCalendar = () => {
    const [user, isAdmin, isManager, path]: [User, boolean, boolean, string] = useOutletContext();
    let { clientId, facilityId } = useParams();

    if (!isAdmin && !isManager) {
        clientId = user.uid
    }


    const toast = useToast();
    const [textTable, setTextTable] = useState<string>('');
    const [bookings, setAllBookings] = useState([])

    const calendarMap = new Map<BookingStatus, string>();
    calendarMap.set(BookingStatus.WAITING_FOR_APPROVAL, "1");
    calendarMap.set(BookingStatus.WAITING_FOR_PAYMENT, "2");
    calendarMap.set(BookingStatus.RESERVED, "3");
    calendarMap.set(BookingStatus.DENIED, "4");
    calendarMap.set(BookingStatus.REFUNDED, "5");

    const [selectedClient, setSelectedClient] = useState<string>();
    const [selectedFacility, setSelectedFacility] = useState<string>();
    const [selectedStatus, setSelectedStatus] = useState<{ label: string, value: string }>();
    const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot>();
    const onStatusSelect = (e: any) => {
        setSelectedStatus(e);
    }

    const [docs, setDocs] = useState<QueryDocumentSnapshot[]>([]);

    const [clientsMap, setClients] = useState<Map<string, DocumentData>>(new Map<string, DocumentData>());
    const [facilitiesMap, setFacilities] = useState<Map<string, DocumentData>>(new Map<string, DocumentData>());

    const [searchQuery, setSearchQuery] = useState<string>();
    const changeHandler = (event: any) => setSearchQuery(event.target.value);
    const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);
    const queryConstraints: QueryFieldFilterConstraint[] = [];
    if (selectedStatus) {
        queryConstraints.push(where('status', 'in', [selectedStatus.value]));
    }
    if (selectedFacility) {
        queryConstraints.push(where('facilityId', '==', selectedFacility))
    }
    if (clientId || selectedClient) {
        queryConstraints.push(where('userId', '==', clientId ? clientId : selectedClient));
    } else if (facilityId) {
        queryConstraints.push(where('facilityId', '==', facilityId));
    }

    const [bookingsQuerySnapshot, isLoading, bookingsLoadingError] = useCollection(
        query(
            collection(firebaseFirestore, 'bookings'),
            ...queryConstraints
        )
    );

    const [facilitiesDataSnapshot, isLoadingFacilities, facilitiesLoadingError] = useCollection(
        query(collection(firebaseFirestore, 'facilities'))
    );
    const [clientsDataSnapshot, isLoadingClients, clientsLoadingError] = useCollection(
        query(collection(firebaseFirestore, 'users'))
    );
    const [clientDataSnapshot, isLoadingClient, clientLoadingError] = useDocumentData(
        doc(firebaseFirestore, 'users', (clientId ? clientId : 'nonexistingclient'))
    );

    useEffect(() => {
        if (!isLoadingFacilities) {
            facilitiesDataSnapshot!.docs.map(el =>
                setFacilities(new Map(facilitiesMap.set(el.id, el.data())))
            )
            if (facilityId) {
                setTextTable(` - ${facilitiesMap.get(facilityId)!.name}`)
            }
        }
    }, [isLoadingFacilities]);

    useEffect(() => {
        if (!isLoadingClients) {
            if (!isLoadingClients && !clientId) {
                clientsDataSnapshot!.docs.map(el =>
                    setClients(new Map(clientsMap.set(el.id, el.data())))
                )
            }
            if (!isLoadingClient && clientId && clientDataSnapshot) {
                setClients(new Map(clientsMap.set(clientId, clientDataSnapshot)))
                setTextTable(` - ${clientsMap.get(clientId)!.displayName}`)
                setSelectedClient(firebaseAuth.currentUser!.uid)
            }
        }
    }, [isLoadingClients, isLoadingClient]);

    useEffect(() => {
        if (bookingsLoadingError || facilitiesLoadingError) {
            showToast(toast, {
                title: 'Could not fetch data.',
                status: 'error'
            });
        }
    }, [bookingsLoadingError, facilitiesLoadingError, clientsLoadingError])

    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();
        }
    }, []);

    useEffect(() => {
        setTextTable('');
    }, [clientId, facilityId, isLoadingClient]);

    useEffect(() => {
        setLastVisible(undefined);
        setDocs([]);
    }, [
        selectedStatus,
    ]);

    useEffect(() => {
        if (!isLoading && bookingsQuerySnapshot?.docs && (clientsDataSnapshot || clientDataSnapshot) && facilitiesDataSnapshot) {
            const tempBookings = []
            bookingsQuerySnapshot.docs.forEach((booking) => {
                tempBookings.push(
                    {
                        id: booking.id,
                        title: facilitiesMap.get(booking.data().facilityId)!.name + ': (' + booking.data().info.sector + ') ' + booking.data().info.description,
                        calendarId: calendarMap.get(booking.data().status),
                        category: "time",
                        attendees: [clientsMap.get(booking.data().userId)!.displayName],
                        isVisible: true,
                        start: new Date(booking.data().info.start.toMillis()),
                        end: new Date(booking.data().info.end.toMillis()),
                    })
            });
            setAllBookings(tempBookings);
        }
    }, [isLoading, bookingsQuerySnapshot, clientsDataSnapshot, facilitiesDataSnapshot]);


    return (
        <>
            <Helmet>
                <title>PCTT - Calendario de reservas</title>
            </Helmet>
            <Stack>
                <Box
                    bg='bg.surface'
                    boxShadow={{ base: 'none', md: 'sm' }}
                    borderRadius={{ base: 'none', md: 'lg' }}
                >
                    <Stack spacing='5'>
                        <Box px={{ base: '4', md: '6' }} pt='5'>
                            <Stack direction={{ base: 'column', md: 'row' }} justify='space-between'>
                                <Text fontSize={'3xl'} fontWeight='medium'>
                                    Calendario {textTable}
                                </Text>
                                <Stack direction={{ base: 'column', lg: 'row' }} alignSelf={{ base: 'flex-start', lg: 'flex-end' }} width={{ base: '100%', lg: 'auto' }}>
                                    {((isAdmin || isManager) &&
                                        <ReactSelect
                                            chakraStyles={{
                                                container: (provided) => ({
                                                    ...provided,
                                                    display: 'flex',
                                                    width: '100%',
                                                    maxWidth: { base: 'full', lg: 'xs' }
                                                })
                                            }}
                                            isClearable={true}
                                            isLoading={isLoadingClients}
                                            placeholder='Cliente'
                                            onChange={nv => setSelectedClient(nv?.value!!)}
                                            options={clientsDataSnapshot?.docs.map(o => ({ label: o.data().displayName, value: o.id }))}
                                            noOptionsMessage={(_i) => 'No hay clientes.'}
                                        />
                                    )}
                                    <ReactSelect
                                        chakraStyles={{
                                            container: (provided) => ({
                                                ...provided,
                                                display: 'flex',
                                                width: '100%',
                                                maxWidth: { base: 'full', lg: 'xs' }
                                            })
                                        }}
                                        isClearable={true}
                                        isLoading={isLoadingFacilities}
                                        placeholder='Salas'
                                        onChange={nv => setSelectedFacility(nv?.value!!)}
                                        options={facilitiesDataSnapshot?.docs.map(o => ({ label: o.data().name, value: o.id }))}
                                        noOptionsMessage={(_i) => 'No hay salas.'}
                                    />

                                    <ReactSelect
                                        chakraStyles={{
                                            container: (provided) => ({
                                                ...provided,
                                                display: 'flex',
                                                width: '100%',
                                                maxWidth: { base: 'full', lg: 'xs' }
                                            })
                                        }}

                                        isClearable={true}
                                        value={selectedStatus}
                                        options={getStatusOptions()}
                                        placeholder='Estados'
                                        onChange={nv => onStatusSelect(nv)}
                                    />
                                </Stack>
                                <Menu>
                                    <MenuButton as={Button} colorScheme='blue' rightIcon={<ChevronDownIcon />}>
                                        Acciones
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem
                                            as='a'
                                            href={`../booking/new`}>
                                            Crear Reserva
                                        </MenuItem>
                                        <MenuItem
                                            as='a'
                                            href={`../bookings`}>
                                            Ver lista
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </Stack>
                        </Box>
                        <Box overflowX='auto' overflowY='hidden'>
                            <FullCalendar schedules={bookings}></FullCalendar>
                        </Box>
                    </Stack>
                </Box>
            </Stack>
        </>
    )

}