import { CalendarIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, FormControl, FormLabel, HStack, Heading, Input, Spinner, Stack, useToast } from '@chakra-ui/react'
import { collection, doc, query } from 'firebase/firestore';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { firebaseAuth, firebaseFirestore } from '../../firebase';
import { useEffect, useState } from 'react';
import { showToast } from '../../helpers';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet-async';
import { Select } from 'chakra-react-select';
import { firebaseFunctions } from '../../firebase';
import { httpsCallable } from 'firebase/functions';
import { getLabel } from '../../common/labelsFactory';
import { RateUnitType } from '../../types/RateUnitType';
import { User } from 'firebase/auth';

type BookingFormData = {
    facility?: string;
    client?: string;
    start?: number;
    units?: number;
    unitType?: any;
    description?: string;
    sector?: any
}

export const BookingForm = () => {

    const toast = useToast();
    const navigate = useNavigate();
    const [user, isAdmin, isManager, path]: [User, boolean, boolean, string] = useOutletContext();

    let { clientId } = useParams();

    if (!isAdmin && !isManager) {
        clientId = user.uid
    }

    const createBookingCommand = httpsCallable(
        firebaseFunctions,
        'createBookingCallable'
    );

    const [allFacilities, setAllfacilities] = useState<{ label: string, value: string }[]>([])
    const [allClients, setAllClients] = useState<{ label: string, value: string }[]>([])
    const [allSectors, setAllSectors] = useState<{ label: string; value: string; }[]>([])
    const [unitTypes, setUnitTypes] = useState<{ label: string, value: RateUnitType }[]>([])

    const [facilitySelected, setFacilitySelected] = useState<{ label: string, value: string }>();
    const [clientSelected, setClientSelected] = useState<{ label: string, value: string }>();
    const [unitTypeSelected, setUnitTypeSelected] = useState<{ label: string, value: string }>();
    const [sectorSelected, setSectorSelected] = useState<{ label: string, value: string }>();
    const [startDate, setStartDate] = useState<number>()
    const [units, setUnits] = useState<number>()
    const [description, setDescription] = useState('')

    const [facilityUnitTypesMap, setFacilityUnitTypesMap] = useState<Map<string, { label: string, value: string }[]>>(new Map());

    const [facilitiesDataSnapshot, isLoadingFacilities, errorLoadingFacilities] = useCollection(
        query(collection(firebaseFirestore, 'facilities'))
    );

    const [clientsDataSnapshot, isLoadingClients, errorLoadingClients] = useCollection(
        query(collection(firebaseFirestore, 'users'))
    );

    const [clientDataSnapshot, isLoadingClient, clientLoadingError] = useDocumentData(
        doc(firebaseFirestore, 'users', (clientId ? clientId : 'nonexistingclient'))
    );

    const [settingsSnapshot, isLoadingSettings, errorLoadingSettings] = useDocumentData(
        doc(firebaseFirestore, 'settings', 'public')
    );

    useEffect(() => {
        if (!isLoadingSettings && settingsSnapshot) {
            const sectors: { label: string; value: string; }[] = [];
            settingsSnapshot.sectors.forEach((sector: string) => {
                sectors.push({ label: sector, value: sector })
            });
            setAllSectors(sectors);
        }
    }, [isLoadingSettings])

    useEffect(() => {
        if (!isLoadingFacilities) {
            const facilities: { label: string, value: string }[] = facilitiesDataSnapshot!.docs.map(el =>
                ({ label: el.data().name, value: el.id })
            )
            setAllfacilities(facilities);
            const tempMap = new Map();
            for (const facility of facilitiesDataSnapshot!.docs) {
                const facilitiesDateUnits: { label: string, value: string }[] = facility.data().rates.map(el =>
                    ({ label: getLabel(el.unitType), value: el.unitType })
                )
                tempMap.set(facility.id, facilitiesDateUnits);
            }
            setFacilityUnitTypesMap(tempMap);
        }
    }, [isLoadingFacilities]);

    useEffect(() => {
        if (facilitySelected) {
            setUnitTypes(facilityUnitTypesMap.get(facilitySelected.value)!!)
        }
    }, [facilitySelected]);

    useEffect(() => {
        if (!isLoadingClients && !clientId) {
            const clients: { label: string, value: string }[] = clientsDataSnapshot!.docs.map(el =>
                ({ label: el.data().displayName, value: el.id })
            )
            setAllClients(clients);
        }
        if (!isLoadingClient && clientId && clientDataSnapshot) {
            setClientSelected({ label: firebaseAuth.currentUser!.displayName, value: firebaseAuth.currentUser!.uid })
        }
    }, [isLoadingClients, isLoadingClient, clientDataSnapshot]);

    const send = (data: BookingFormData) => {
        return new Promise<void>(async (resolve) => {
            const success = () => {
                showToast(toast, {
                    title: 'Success',
                    description: 'La reserva se ha creado con éxito',
                    status: 'success',
                });
                navigate('../bookings', { replace: true });
                resolve()
            }
            const error = (e: any) => {
                console.error(e);
                showToast(toast, {
                    title: 'Error',
                    description: e,
                    status: 'error',
                });
                resolve()
            }

            if (!data.description || !data.sector || !data.client || !data.facility || !data.start || !data.units || !data.unitType) {
                error('Debe rellenar todos los campos');
            } else {
                await createBookingCommand(
                    {
                        facilityId: data.facility,
                        clientId: data.client,
                        start: data.start,
                        units: data.units,
                        unitType: data.unitType.value,
                        description: (data.description) ? data.description : null,
                        sector: (data.sector) ? data.sector.value : null
                    }).then((result) => {
                        if (!result.data.success) {
                            error(result.data.error)
                        } else {
                            success()
                        }
                    }).catch((e) => {
                        console.error(e);
                        error('')
                    });
            }
        });
    }

    const {
        handleSubmit,
        register,
        setValue,
        control,
        watch,
        formState: { errors, isSubmitting },
    } = useForm<BookingFormData>({
        values: {
            facility: facilitySelected?.value,
            client: clientSelected?.value,
            start: startDate,
            units: units,
            unitType: unitTypeSelected,
            sector: sectorSelected,
            description: description
        },
        resetOptions: {
            keepDirtyValues: true
        }
    });

    return (
        <>
            <Helmet>
                <title>PCTT - Bonus</title>
            </Helmet>
            <Stack spacing={8}>
                <HStack spacing={4}>
                    <CalendarIcon boxSize={8} />
                    <Heading size='md'>
                        {'Crear Reserva'}
                    </Heading>
                </HStack>
                {isLoadingClients && isLoadingFacilities &&
                    <Flex justifyContent={'center'} py={24}>
                        <Spinner size='xl' />
                    </Flex>
                }
                {!isLoadingClients && !isLoadingFacilities &&
                    <Stack as='form' spacing={8} onSubmit={handleSubmit(send)} noValidate>
                        <Box bg='bg-surface' boxShadow='sm' borderRadius='lg' flexGrow={1} pb={4}>
                            <Stack spacing='5' px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                                <Stack direction={{ base: 'column', lg: 'row' }} alignSelf={{ base: 'flex-start', lg: 'flex-end' }} width={{ base: '100%' }} mb={5} display='flex'>
                                    <FormControl>
                                        <FormLabel htmlFor='description'>Motivo de la reserva:</FormLabel>
                                        <Input
                                            placeholder="Escriba una breve descripción"
                                            size="md"
                                            type="text"
                                            onChange={(e) => setDescription(e.currentTarget.value)} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor='sectors'>Sector:</FormLabel>
                                        <Select
                                            options={allSectors}
                                            value={sectorSelected}
                                            isLoading={isLoadingSettings}
                                            placeholder='Elija un sector'
                                            onChange={nv => setSectorSelected(nv!)}
                                        />
                                    </FormControl>
                                </Stack>
                                <Stack direction={{ base: 'column', lg: 'row' }} alignSelf={{ base: 'flex-start', lg: 'flex-end' }} width={{ base: '100%' }} mb={5} display='flex'>
                                    {(isAdmin &&
                                        <FormControl>
                                            <FormLabel htmlFor='client'>Seleccione el cliente:</FormLabel>
                                            <Select
                                                isRequired
                                                isLoading={isLoadingClients}
                                                placeholder='Cliente'
                                                onChange={nv => setClientSelected(nv!)}
                                                options={allClients}
                                                value={clientSelected}
                                            />
                                        </FormControl>
                                    )}
                                    <FormControl>
                                        <FormLabel htmlFor='facility'>Seleccione la sala:</FormLabel>
                                        <Select
                                            isRequired
                                            options={allFacilities}
                                            value={facilitySelected}
                                            isLoading={isLoadingFacilities}
                                            placeholder='Salas'
                                            onChange={nv => setFacilitySelected(nv!)}
                                        />
                                    </FormControl>
                                </Stack>
                                <Box display={(facilitySelected) ? 'flex' : 'none'} >
                                    <Stack direction={{ base: 'column', lg: 'row' }} alignSelf={{ base: 'flex-start', lg: 'flex-end' }} width={{ base: '100%' }} mb={5} display='flex'>

                                        <FormControl >
                                            <FormLabel htmlFor='start'>Fecha inicio de la reserva:</FormLabel>
                                            <Input
                                                isRequired
                                                placeholder="Select Date and Time"
                                                size="md"
                                                type="datetime-local"
                                                onChange={nv => setStartDate(Date.parse(nv.target.value))}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel htmlFor='units'>Cantidad de tiempo:</FormLabel>
                                            <Input
                                                isRequired
                                                placeholder="0"
                                                size="md"
                                                type="number"
                                                onChange={(e) => setUnits(Number(e.currentTarget.value))} />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel htmlFor='units'>Unidad de tiempo:</FormLabel>
                                            <Select
                                                isRequired
                                                options={unitTypes}
                                                value={unitTypeSelected}
                                                isLoading={isLoadingFacilities}
                                                placeholder='Elija una opción'
                                                onChange={nv => setUnitTypeSelected(nv!)}
                                            />
                                        </FormControl>
                                    </Stack>
                                </Box>

                            </Stack>
                        </Box>
                        <HStack justify={'flex-end'}>
                            <Button as={Link} to={'../bookings'} replace variant='ghost' size='lg' colorScheme='blue'>Cancelar</Button>
                            <Button
                                variant='primary'
                                colorScheme='blue'
                                isLoading={isSubmitting}
                                isDisabled={false}
                                type='submit'
                                size='lg'>
                                Crear
                            </Button>
                        </HStack>
                    </Stack>
                }
            </Stack>
        </>
    )

}