import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast
} from '@chakra-ui/react'
import { DocumentData, doc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { useAppContext } from '../../../layout/app/AppLayout';
import { Controller, useForm } from 'react-hook-form';
import { showToast } from '../../../helpers';
import { firebaseFirestore } from '../../../firebase';
import { Select } from 'chakra-react-select';
import { UserStatus } from '../../../types/UserStatus';
import { getLabel } from '../../../common/labelsFactory';

type FormData = {
    status: string;
}

export const EditClientStatusModal = ({
    clientId, clientDataSnapshot, isOpen, onClose
}: {
    clientId: string,
    clientDataSnapshot: DocumentData,
    isOpen: boolean,
    onClose: () => void
}) => {
    const context = useAppContext();
    const toast = useToast();

    const states = [
        { value: UserStatus.ACTIVE, label: getLabel(UserStatus.ACTIVE) },
        { value: UserStatus.BLOCKED, label: getLabel(UserStatus.BLOCKED) },
        { value: UserStatus.ONLY_LOGIN, label: getLabel(UserStatus.ONLY_LOGIN) },
    ]
    const {
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
    } = useForm<FormData>({
        values: {
            status: clientDataSnapshot?.status
        },
        resetOptions: {
            keepDirtyValues: true
        }
    });

    const getStatus = (value: any) => {
        if (value === UserStatus.ACTIVE_BY_API || value === UserStatus.ACTIVE) {
            return { value: value, label: getLabel(UserStatus.ACTIVE) };
        }
        else if (value === UserStatus.BLOCKED_BY_API || value === UserStatus.BLOCKED) {
            return { value: value, label: getLabel(UserStatus.BLOCKED) };
        }
        else if (value === UserStatus.ONLY_LOGIN || value === UserStatus.ONLY_LOGIN_BY_API) {
            return { value: value, label: getLabel(UserStatus.ONLY_LOGIN) };
        }

        return false;
    }


    const send = (data: FormData) => {
        return new Promise<void>((resolve) => {
            const success = () => {
                showToast(toast, {
                    title: 'Success',
                    description: 'Client was updated',
                    status: 'success',
                });
                onClose();
            }
            const error = (e: any) => {
                console.error(e);
                showToast(toast, {
                    title: 'Error',
                    description: 'Cant update client. Please try again.',
                    status: 'error',
                });
            }
            updateDoc(
                doc(firebaseFirestore, 'users', clientId), {
                status: data.status,
                updated: serverTimestamp()
            })
                .then(success)
                .catch(error)
                .finally(resolve);
        });
    }
    return (
        <Modal
            size='2xl'
            isOpen={isOpen}
            onClose={onClose}
            isCentered={true}>
            <ModalOverlay
                bg='blackAlpha.300'
                backdropFilter='blur(10px) hue-rotate(90deg)'
            />
            <ModalContent as='form' noValidate onSubmit={handleSubmit(send)}>
                <ModalHeader>Asignar bono</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl isInvalid={errors?.status !== undefined}>
                        <FormLabel htmlFor='bonus'>Bonos</FormLabel>
                        <Controller
                            control={control}
                            name='status'
                            render={({ field: { onChange, value, ref } }) => (
                                <Select
                                    placeholder='Elige un estado'
                                    onChange={nv => onChange(nv?.value!!)}
                                    value={getStatus(value)}
                                    options={states}
                                    ref={ref}
                                />
                            )} />
                        <FormErrorMessage>
                            {errors.status &&
                                <>{errors.status.message}</>
                            }
                        </FormErrorMessage>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button variant='ghost' mr={3} onClick={onClose} color='black'>
                        Cerrar
                    </Button>
                    <Button
                        type='submit'
                        isDisabled={false}
                        isLoading={isSubmitting}>
                        Guardar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}